import { useAssets } from './VirtualizedGrid.hooks';

export const useSearchTotalsSelector = () => {
  const { data } = useAssets();
  const lastPage = data?.pages[data.pages.length - 1];
  const totalCount = lastPage?.totalCount;
  const totalCountTruncated = lastPage?.totalCountTruncated;

  return { totalCount, totalCountTruncated };
};
