import { CollectionInfoType } from '../../types';
import { SortField, SortDirection } from '../../components/AssetGrid/CollectionGridSort';
import { SearchStateType } from './SearchContext.types';
import { SORT_FIELD_QUERY_PARAM, SORT_DIRECTION_QUERY_PARAM } from '../../components/AssetGrid/CollectionGrid.consts';
import { getQueryParam } from '../../helpers/url.helpers';

export const getInitialState = (collectionInfo: CollectionInfoType): SearchStateType => {
  const sortField = getQueryParam(SORT_FIELD_QUERY_PARAM) as SortField;
  const sortDirection = getQueryParam(SORT_DIRECTION_QUERY_PARAM) as SortDirection;
  const isFolderDecoupling = !!collectionInfo.flags.folderDecouplingEnabled;
  const assetNameKey = isFolderDecoupling ? SortField.DISPLAY_NAME : SortField.FILE_NAME;

  return {
    filtersLuceneQueryString: undefined,
    sortField: sortField || assetNameKey,
    sortDirection: sortDirection || SortDirection.ASC,
  };
};
