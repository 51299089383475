import { Dispatch, useContext } from 'react';
import { SearchStateType, DispatchAction } from './SearchContext.types';
import { SearchContext } from './SearchProvider';

export const useSearchState = () => {
  const searchContext = useContext(SearchContext)?.state as SearchStateType;

  if (!searchContext) {
    throw new Error('Collection Context: No value provided');
  }

  return searchContext;
};

export const useSearchDispatch = (): Dispatch<DispatchAction> => {
  return useContext(SearchContext)?.dispatch as Dispatch<DispatchAction>;
};
