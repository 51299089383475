import { useCallback, useState, useRef } from 'react';

const useHover = () => {
  const ref = useRef<HTMLElement | null>(null);

  const [isHovered, setHovered] = useState(false);
  const onEnter = () => setHovered(true);
  const onLeave = () => setHovered(false);

  const setRef = useCallback((node: HTMLElement | null) => {
    if (ref.current) {
      ref.current.removeEventListener('mouseenter', onEnter);
      ref.current.removeEventListener('mouseleave', onLeave);
    }

    if (node) {
      node.addEventListener('mouseenter', onEnter);
      node.addEventListener('mouseleave', onLeave);
    }

    ref.current = node;
  }, []);

  return { isHovered, ref: setRef };
};

export default useHover;
