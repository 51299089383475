import { globalTheme } from '@clds/component-theme';
import styled from 'styled-components';
import { StyledItemOverlayContainer } from '../AssetGrid/item/ItemOverlay.styles';

export const StyledAssetCardContainer = styled.div<{ cardHeight: number; cardWidth: number }>`
  position: relative;
  display: flex;
  min-width: 110px;
  border: 1px solid ${globalTheme.palette.secondary};
  background-color: transparent;
  height: ${({ cardHeight }) => `${cardHeight}px`};
  width: ${({ cardWidth }) => `${cardWidth}px`};
  overflow: hidden;
  &:hover {
    ${StyledItemOverlayContainer} {
      opacity: 1;
    }
  }
`;

interface StyledPlaceholderIconWrapperProps {
  withAnimation: boolean;
}

export const StyledPlaceholderIconWrapper = styled.div<StyledPlaceholderIconWrapperProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${({ withAnimation }) => withAnimation && 'process 800ms ease-in-out infinite'};
  @keyframes process {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`;

export const StyledIconOverlay = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
