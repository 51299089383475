/* istanbul ignore file */
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { globalTheme, useMixin } from '@clds/component-theme';
import { FileDownload } from '@clds/icon';
import { HorizontalSeparator } from '@clds/separator';
import { Item, ItemLayout } from '@clds/item';
import { Button } from '@clds/button-old';
import { Asset } from '@cld/console-apps-types';
import { messages } from '../../i18n';
import { SharedPreset } from '../../types';
import {
  StyledCheckCircle,
  StyledPaper,
  StyledPresetHeaderTypography,
  StyledTextSkeleton,
  StyledThumbnailContainer,
  StyledTypography,
} from './DownloadPresetsModalStyled';
import { useTotalsForDownload } from './Download.api';
import { PresetTypeEnum } from './types';
import { Thumbnail } from '../Thumbnail/Thumbnail';
import { DownloadType } from '../Enums/Enum';
import { TruncatedWithTooltip } from '../../cld-filters/components/shared/TruncatedWithTooltip';

type PresetType = 'image' | 'video';

const DownloadStatus = () => <StyledCheckCircle size="sm" color={useMixin(globalTheme.palette.success)} />;

interface PresetsSectionProps {
  activePresets: Array<string>;
  assets: Array<Asset> | Asset;
  presets: Array<SharedPreset>;
  downloadType: DownloadType;
  onPresetDownload: (externalId?: string, presetName?: string, presetTypes?: string[]) => void;
}

export const PresetsSection: FC<React.PropsWithChildren<PresetsSectionProps>> = ({ activePresets, assets, presets, onPresetDownload, downloadType }) => {
  const isBulkDownload = assets && Array.isArray(assets) && assets.length > 1;
  const { formatMessage } = useIntl();
  const { isLoading, data, error } = useTotalsForDownload();

  const ItemLayoutRightSlot = (externalId?: string, presetName?: string, presetTypes?: string[]) => {
    const isActive = presetName && activePresets.includes(presetName);
    return isActive ? (
      <DownloadStatus />
    ) : (
      <Button
        onClick={(e) => {
          e.stopPropagation();
          onPresetDownload(externalId, presetName, presetTypes);
        }}
      >
        <FileDownload />
      </Button>
    );
  };

  const renderPresetItemSecondaryContent = (presetTypes: PresetTypeEnum[]) => {
    if (error || !isBulkDownload) {
      return null;
    }

    if (isLoading || !data) {
      return <StyledTextSkeleton isLoading />;
    }

    const totalsByPresetType = {
      [PresetTypeEnum.Image]: data.totalImages,
      [PresetTypeEnum.Video]: data.totalVideos,
    };
    const displayedSecondaryContent = presetTypes
      .map((presetType) => formatMessage(messages[`${presetType}Count`], { count: totalsByPresetType[presetType] }))
      .join(', ');

    return displayedSecondaryContent;
  };

  const thumbnailAsset: Asset[] | Asset = Array.isArray(assets) ? assets.find((asset) => asset.type === 'image') || assets[0] : assets;
  return (
    <>
      <StyledPresetHeaderTypography size="sm" type="regular" as="span">
        <FormattedMessage {...messages.presetsDownload} values={{ isBulkDownload }} />
      </StyledPresetHeaderTypography>

      <StyledPaper borderRadius="sm" depth="none">
        {presets?.map(({ name, transformation, presetTypes }) => {
          const assetId = Array.isArray(assets) ? undefined : assets?.externalId;

          return (
            <div key={name}>
              <Item borderRadius="sm" size="md" onClick={() => onPresetDownload(assetId, name, presetTypes)}>
                <ItemLayout
                  // eslint-disable-next-line
                  //@ts-ignore
                  rightSlot={ItemLayoutRightSlot(assetId, name, presetTypes)}
                >
                  <StyledThumbnailContainer>
                    <Thumbnail iconColorType="highContrast" asset={thumbnailAsset} transformation={transformation} />
                    <TruncatedWithTooltip tooltipText={name} data-unit-test="truncated">
                      {name}
                      <StyledTypography size="xxs" type="highContrast">
                        {downloadType === DownloadType.All && renderPresetItemSecondaryContent(presetTypes)}
                      </StyledTypography>
                    </TruncatedWithTooltip>
                  </StyledThumbnailContainer>
                </ItemLayout>
              </Item>
              <HorizontalSeparator />
            </div>
          );
        })}
      </StyledPaper>
    </>
  );
};
