import React, {
  FunctionComponent,
  ReactNode,
  useLayoutEffect,
  useState,
} from 'react';
import useResizeObserver from 'use-resize-observer';
import { Transition } from 'react-transition-group';
import { isMobile } from 'react-device-detect';
import useControlledState from '@quid/react-use-controlled-state';
import { TransitionStatus } from 'react-transition-group/Transition';
import { StylableComponentProps } from '@clds/common-definitions';
import { Placement } from '@clds/tooltip';
import useHover from '@cld/use-hover';
import { usePersistency } from '@cld/use-persistency';
import {
  StyledButtonContainer,
  StyledCollapsiblePanel,
  StyledContentWrapper,
  StyledFloatingPanel,
} from './CollapsiblePanel.styled';
import { Direction, NOTCH_SIZE } from './shared/consts';
import { getDefaultValue } from './shared/helpers';
import { ToggleButtonWithTooltip } from './ToggleButtonWithTooltip';
import { useFullyFloatingPanel } from './useFullyFloatingPanel';
import { useSessionStorage } from './useSessionStorage';

export interface CollapsiblePanelProps extends StylableComponentProps {
  /** Set the direction to which the panel is closing  **/
  direction?: Direction;
  /** The Collapsible panel leaves a notch, and doesn't close completely. This lets you set the size (default is 20px) **/
  notchSize?: number;
  /** Setting this to true will make the Collapse Button appear on hover only (on mobile/tablet this prop is ignored) **/
  showCollapseOnHover?: boolean;
  /** Sets a local storage key which saves the last width. Will be used as 'defaultWidth' on next load **/
  persistenceKey?: string;
  /** Sets a session storage key which saves the last width. Will be used as 'defaultWidth' on next load **/
  sessionStorageKey?: string;
  /** Callback function triggered whenever the toggle button is pressed **/
  toggleCallback?: (arg0: boolean) => void;
  /** Tooltip Text for expand state text **/
  tooltipExpand?: string;
  /** Tooltip Text for collapse state text **/
  tooltipCollapse?: string;
  /** Tooltip Position **/
  tooltipPosition?: Placement;
  /** Passing true will remove the toggle button and expand the panel **/
  disabled?: boolean;
  /** Component's child component **/
  children: ReactNode;
  /** Classname **/
  className?: string;
  /** Set an option to make the panel open in float mode when hovering over the closed panel **/
  isFloatOnHover?: boolean;
  /** Set an option to hide the content while panel is closed, this will add a wrapping div to the content**/
  isContentHiddenOnClose?: boolean;
  /** Callback function triggered whenever the panel is on float on hover state and open/close  **/
  onFloatChange?: (arg: boolean) => void;
}

export interface ControlledCollapsiblePanelProps extends CollapsiblePanelProps {
  defaultIsExpanded?: undefined;
  /** Make the component controlled and pass a value to set if it's expanded  **/
  isExpanded?: boolean;
  /** Make the component controlled and pass a setter function for expanded state  **/
  setExpanded?: (value: boolean) => void;
}

export interface UncontrolledCollapsiblePanelProps
  extends CollapsiblePanelProps {
  /** Set a default value for weather the component is expanded or collapsed on first render (relevant only in uncontrolled mode)  **/
  defaultIsExpanded?: boolean;
  isExpanded?: undefined;
  setExpanded?: undefined;
}

/**
 * A wrapper for container elements which lets us toggle them close/open.
 */
const CollapsiblePanel: FunctionComponent<
  React.PropsWithChildren<
    ControlledCollapsiblePanelProps | UncontrolledCollapsiblePanelProps
  >
> = ({
  direction = Direction.LEFT,
  notchSize = NOTCH_SIZE,
  showCollapseOnHover = false,
  persistenceKey,
  sessionStorageKey,
  toggleCallback,
  defaultIsExpanded = true,
  isExpanded: isExpandedCustom,
  setExpanded: setExpandedCustom,
  tooltipExpand,
  tooltipCollapse,
  tooltipPosition = 'top',
  disabled = false,
  children,
  className,
  isFloatOnHover = false,
  isContentHiddenOnClose = false,
  onFloatChange,
}) => {
  const { value: valueFromLocalStorage, setPersistentValue } =
    usePersistency(persistenceKey);
  const { value: valueFromSessionStorage, setSessionStorage } =
    useSessionStorage(sessionStorageKey);
  const valueFromStorage = valueFromLocalStorage || valueFromSessionStorage;
  const defaultValue = getDefaultValue(
    valueFromStorage,
    defaultIsExpanded,
    isExpandedCustom,
  );
  // This lets us make our component either controlled or uncontrolled.
  const [expanded, setExpanded] = useControlledState(
    defaultValue,
    isExpandedCustom,
    setExpandedCustom,
  );

  const { ref: panelRef, isHovered: panelIsHovered } = useHover();
  const { ref: innerPanelRef, width } = useResizeObserver<HTMLDivElement>();

  const [isPanelFloatedOpen, setIsPanelFloatedOpen] = useState(false);
  const [buttonContainerIsHovered, setButtonContainerIsHovered] =
    useState(false);

  useFullyFloatingPanel(isPanelFloatedOpen, onFloatChange);

  const toggle = () => {
    const newValue = !expanded;
    setExpanded(newValue);
    setPersistentValue(newValue.toString());
    setSessionStorage(newValue.toString());
    toggleCallback?.(newValue);
  };

  useLayoutEffect(() => {
    const panelHovered =
      isFloatOnHover && !isMobile && !expanded && panelIsHovered;
    const shouldCloseFloatWhenPanelExpanded =
      buttonContainerIsHovered && expanded && isPanelFloatedOpen;

    if (
      (!buttonContainerIsHovered || shouldCloseFloatWhenPanelExpanded) &&
      panelHovered !== isPanelFloatedOpen
    ) {
      setIsPanelFloatedOpen(panelHovered);
    }
  }, [
    isFloatOnHover,
    expanded,
    panelIsHovered,
    buttonContainerIsHovered,
    isPanelFloatedOpen,
  ]);

  return (
    <Transition in={expanded} timeout={200}>
      {(state: TransitionStatus) => (
        <StyledCollapsiblePanel
          panelWidth={expanded || disabled ? width : notchSize}
          ref={panelRef}
          dataTest="collapsible-panel"
          className={className}
          dataTestSpecifier={state}
        >
          <StyledFloatingPanel
            panelWidth={width}
            isPanelFloatOpen={isPanelFloatedOpen}
            state={state}
            direction={direction}
          >
            <StyledContentWrapper
              ref={innerPanelRef}
              panelWidth={width}
              state={state}
              isPanelFloatOpen={isPanelFloatedOpen}
              direction={direction}
              isContentHiddenOnClose={isContentHiddenOnClose}
            >
              {children}
            </StyledContentWrapper>
            {!disabled && (
              <StyledButtonContainer
                direction={direction}
                onMouseEnter={() => setButtonContainerIsHovered(true)}
                onMouseLeave={() => setButtonContainerIsHovered(false)}
              >
                <ToggleButtonWithTooltip
                  show={
                    isMobile ||
                    panelIsHovered ||
                    !showCollapseOnHover ||
                    !expanded
                  }
                  toggle={toggle}
                  direction={direction}
                  expanded={expanded}
                  tooltipTitle={expanded ? tooltipCollapse : tooltipExpand}
                  tooltipPosition={tooltipPosition}
                />
              </StyledButtonContainer>
            )}
          </StyledFloatingPanel>
        </StyledCollapsiblePanel>
      )}
    </Transition>
  );
};

export default CollapsiblePanel;
