import React from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import { usePhone, useTablet } from '@clds/style-utils';
import { Asset, isImageAsset, isVideoAsset } from '@cld/console-apps-types';
// eslint-disable-next-line
// @ts-ignore
import { withRenderOnHover } from '@cld/with-conditional-render';
import AssetCard from '@cld/asset-card';
import { ItemOverlay } from './item/ItemOverlay';
import { CAPTION_HEIGHT, CARD_HEIGHT, CARD_WIDTH } from './CollectionGrid.consts';
import { SharedPreset } from '../../types';

const AssetCardWrapper = styled.div`
  width: ${CARD_WIDTH}px;
  height: ${CARD_HEIGHT}px;
  > * {
    width: 100%;
    height: 100%;
  }
`;

const ContentOnHover = withRenderOnHover(styled.div`
  height: 100%;
`);

export interface ContentProps {
  presets: SharedPreset[];
  onClick: () => void;
  onDownload: (assets: Asset[] | Asset) => void;
  preventDownload?: boolean;
  asset: Asset;
  trackingAssetPosition: number;
}

export const Content = ({ asset, preventDownload, onClick, onDownload, presets, trackingAssetPosition }: ContentProps) => {
  const isPhone = usePhone();
  const isTablet = useTablet();

  const Item = (
    <ItemOverlay
      presets={presets}
      preventDownload={preventDownload}
      asset={asset}
      onClick={onClick}
      onDownload={onDownload}
      trackingAssetPosition={trackingAssetPosition}
    />
  );

  if (isPhone || isTablet) {
    return Item;
  }
  return <ContentOnHover>{Item}</ContentOnHover>;
};

export const CollectionGridItem = ({
  'data-test-specifier': dataTestSpecifier,
  asset,
  preventDownload,
  onClick,
  onDownload,
  presets,
  trackingAssetPosition,
}: ContentProps & { ['data-test-specifier']: string }) => {
  const isImage = isImageAsset(asset);
  const isVideo = isVideoAsset(asset);

  return (
    <LazyLoad overflow offset={500}>
      <AssetCardWrapper data-test-specifier="asset-card-wrapper">
        <AssetCard
          data-test-specifier={dataTestSpecifier}
          assetSrc={asset.urls.thumbnail}
          assetType={asset.type}
          assetFormat={asset.format}
          assetWidth={(isImage || isVideo) && asset.width ? asset.width : 0}
          assetHeight={(isImage || isVideo) && asset.height ? asset.height : 0}
          assetBytes={asset.bytes}
          assetDuration={isVideo && asset.duration ? asset.duration : 0}
          caption={asset.displayName || asset.filename}
          captionHeight={CAPTION_HEIGHT}
        >
          <Content
            presets={presets}
            onClick={onClick}
            onDownload={onDownload}
            preventDownload={preventDownload}
            asset={asset}
            trackingAssetPosition={trackingAssetPosition}
          />
        </AssetCard>
      </AssetCardWrapper>
    </LazyLoad>
  );
};
