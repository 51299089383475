import styled from 'styled-components';
import React, { ComponentProps } from 'react';
import { OptionTypeBase } from 'react-select';
import { IndicatorComponentType } from 'react-select/src/components';
import { useMixin } from '@clds/component-theme';
import { ArrowDropDown, IconProps } from '@clds/icon';
import { comboboxTheme } from '../theme';

interface DropdownIconProps extends IconProps {
  menuIsOpen: boolean | undefined;
  isDisabled: boolean | undefined;
}

const DropdownIcon = styled(ArrowDropDown)<DropdownIconProps>`
  transition: transform 200ms ease-out;
  transform: rotate(${(props) => (props?.menuIsOpen ? 180 : 0)}deg);
  margin-right: ${comboboxTheme.dropdownIndicator.margin.right};
  ${(props) => props?.isDisabled && 'opacity:0.5;'}
`;

export const DropdownIndicator = <IsMulti extends boolean>({ selectProps }: ComponentProps<IndicatorComponentType<OptionTypeBase, IsMulti>>) => {
  const color = useMixin(comboboxTheme.dropdownIndicator.color);
  return (
    <DropdownIcon
      isDisabled={selectProps.isDisabled}
      menuIsOpen={selectProps.menuIsOpen}
      size="sm"
      color={color}
      data-test-specifier="dropdown-indicator"
    />
  );
};
