import React from 'react';
import styled, { css } from 'styled-components';
import { Option } from 'react-select/src/filters';
import { GroupedOptionsType, OptionTypeBase, GroupProps as GroupBaseProps } from 'react-select';
import { globalTheme } from '@clds/component-theme';
import { CommonComboboxProps, ComboboxProps } from '../types';
import { StyledItem } from '../ComboboxItem';

type RootProps = Pick<CommonComboboxProps, 'groupsSeparator'>;

const Root = styled.div<RootProps>`
  position: relative;

  &:first-child {
    padding-top: ${globalTheme.spacing.xxs};
  }

  &:before {
    content: '';
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    background: ${globalTheme.palette.secondary};
    display: none;
  }

  ${(props) =>
    props.groupsSeparator &&
    css`
      &:not(:first-child) {
        margin-top: 1px;

        &:before {
          display: block;
        }
      }
    `}

  ${StyledItem}:first-of-type {
    border-radius: 0;
  }

  &:not(:last-of-type) {
    ${StyledItem} {
      border-radius: 0;
    }
  }
`;

export type GroupProps<IsMulti extends boolean> = GroupBaseProps<GroupedOptionsType<OptionTypeBase>, IsMulti>;

export const Group = <IsMulti extends boolean>({ Heading, headingProps, selectProps, label, children }: React.PropsWithChildren<GroupProps<IsMulti>>) => {
  const groupsSeparator = (selectProps as ComboboxProps<Option, IsMulti>).groupsSeparator;
  return (
    <Root groupsSeparator={groupsSeparator} data-testid="combobox-options-group">
      <Heading {...headingProps} selectProps={selectProps}>
        {label}
      </Heading>
      <div>{children}</div>
    </Root>
  );
};
