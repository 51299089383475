import { globalTheme } from '@clds/component-theme';
import styled, { css } from 'styled-components';
import EmptyState from '@cld/empty-state';

export const MOSAIC_GAP_PADDING = globalTheme.spacing.xs;

export const StyledMosaicLayoutWrapper = styled.div`
  position: relative;
  padding: ${MOSAIC_GAP_PADDING};
`;

export const StyledMosaicLayoutRow = styled.div`
  display: flex;
  gap: ${MOSAIC_GAP_PADDING};
  margin-bottom: ${MOSAIC_GAP_PADDING};
`;

export interface ParentProps {
  isEmptyList: boolean;
}

export const StyledParent = styled.div<ParentProps>`
  height: 100%;
  width: 100%;
  overflow: auto;
  ${({ isEmptyList }) =>
    isEmptyList &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export const StyledEmptyState = styled(EmptyState)`
  flex: 1;
  height: 100%;
`;
