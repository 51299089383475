/* eslint-disable react/no-deprecated */
// istanbul ignore file
import React from 'react';
import envInfoLabel from '@cld/env-info-label';
import { createRoot } from 'react-dom/client';
import App from './components/App';

const root = createRoot(document.getElementById('standalone')!);

envInfoLabel();

root.render(<App />);
