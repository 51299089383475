import React, { FC, ReactNode } from 'react';
import { globalTheme } from '@clds/component-theme';
import { usePhone } from '@clds/style-utils';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@clds/icon';
import { Columns, Content, StyledButton, StyledNextButton, StyledPrevButton } from './Navigable.styled';

type GlobalThemePalette = keyof ReturnType<typeof globalTheme>['palette'];

export interface NavigableProps {
  color: GlobalThemePalette;
  onNext: () => void;
  onPrevious: () => void;
  children: ReactNode;
  canNavigateToNext?: boolean;
  canNavigateToPrevious?: boolean;
}

export const Navigable: FC<React.PropsWithChildren<NavigableProps>> = ({
  children,
  canNavigateToPrevious,
  canNavigateToNext,
  onNext,
  onPrevious,
  color,
}) => {
  const isPhone = usePhone();
  return (
    <Columns color={color}>
      <StyledPrevButton isVisible={canNavigateToPrevious} data-test="collection-share-navigable-previous-button">
        <StyledButton
          onClick={onPrevious}
          shape="pill"
          variant="overlay"
          tone="solid"
          data-test-specifier="keyboardArrowLeft"
          leftSlot={<KeyboardArrowLeft />}
        />
      </StyledPrevButton>
      <Content isPhone={isPhone} data-test-specifier="navigable-content">
        {children}
      </Content>
      <StyledNextButton isVisible={canNavigateToNext} data-test="collection-share-navigable-next-button">
        <StyledButton
          onClick={onNext}
          shape="pill"
          variant="overlay"
          tone="solid"
          data-test-specifier="keyboardArrowRight"
          leftSlot={<KeyboardArrowRight />}
        />
      </StyledNextButton>
    </Columns>
  );
};
