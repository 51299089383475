import React, { useMemo, useState } from 'react';
import { NumberField } from '@clds/number-field-old';
import { SearchFilterValuesType, SearchFiltersNames, SmdFilterComponentBasePropsType } from '../../types';
import { OperatorFilter, OperatorFilterValue, OperatorOptionType, SmdOperatorType, useCldFilters } from '../../../../cld-filters';
import { useIntl } from 'react-intl';
import { SMD_NUMBER_OPERATORS, SMD_NUMBER_OPERATOR_KEYS } from './SmdNumber.constants';
import { messages } from '../../../../i18n';
import { getUpdatedSmdNumber } from './SmdNumber.helpers';

export const SmdNumber = ({ filterDefinition, onFilterInteract, onClear }: SmdFilterComponentBasePropsType) => {
  const { values, setFilterValue } = useCldFilters<SearchFilterValuesType>();
  const { externalId, label } = filterDefinition;
  const { formatMessage } = useIntl();

  const smdNumberValue: SmdOperatorType | null = values[SearchFiltersNames.SmdNumber];
  const currentSmdFieldValue: OperatorFilterValue | undefined = smdNumberValue?.[externalId as keyof typeof smdNumberValue];
  const isAnyOrEmpty =
    currentSmdFieldValue &&
    (currentSmdFieldValue.operator == SMD_NUMBER_OPERATOR_KEYS.EMPTY || currentSmdFieldValue.operator == SMD_NUMBER_OPERATOR_KEYS.ANY);
  const currentNumber = currentSmdFieldValue?.value || '';
  const hasValue = isAnyOrEmpty || !!currentNumber;
  // The reason to manage 2 states for operator (one in this state, and the second in clfFilters context)
  // is so user can change the operator, without affecting the URL or the query
  const [selectedOperator, setSelectedOperator] = useState<string>(currentSmdFieldValue?.operator || SMD_NUMBER_OPERATOR_KEYS.EQUAL);

  const smdDateOperatorsOptions = useMemo(() => {
    return Object.keys(SMD_NUMBER_OPERATORS).map((key) => {
      const { value, translationId } = SMD_NUMBER_OPERATORS[key as keyof typeof SMD_NUMBER_OPERATORS];
      const message = messages[translationId as keyof typeof messages];
      return {
        value,
        label: message ? formatMessage(message) : translationId,
      };
    });
  }, [formatMessage]);

  const handleOperatorChange = (operator: OperatorOptionType) => {
    setSelectedOperator(operator.value);
    const updatedValue = getUpdatedSmdNumber({ smdNumberValue, externalIdToUpdate: externalId, operator: operator.value, value: currentNumber });
    setFilterValue(SearchFiltersNames.SmdNumber, updatedValue);
    onFilterInteract(SearchFiltersNames.SmdNumber, updatedValue);
  };

  const handleDateInputChange = (value: string) => {
    const updatedValue = getUpdatedSmdNumber({ smdNumberValue, externalIdToUpdate: externalId, operator: selectedOperator, value });
    setFilterValue(SearchFiltersNames.SmdNumber, updatedValue);
    onFilterInteract(SearchFiltersNames.SmdNumber, updatedValue);
  };

  const handleClear = () => {
    const updatedValue = getUpdatedSmdNumber({ smdNumberValue, externalIdToUpdate: externalId, operator: SMD_NUMBER_OPERATOR_KEYS.EQUAL, value: '' });
    setFilterValue(SearchFiltersNames.SmdNumber, updatedValue);
    setSelectedOperator(SMD_NUMBER_OPERATOR_KEYS.EQUAL);
    onClear(SearchFiltersNames.SmdNumber);
  };

  const getValueLabel = () => {
    const selectedOperator: OperatorOptionType | undefined = smdDateOperatorsOptions.find((operator) => operator.value === currentSmdFieldValue?.operator);
    if (isAnyOrEmpty) {
      return selectedOperator?.label;
    } else {
      return currentNumber ? `${selectedOperator?.label?.toLowerCase()} ${currentNumber}` : '';
    }
  };

  return (
    <OperatorFilter
      operators={smdDateOperatorsOptions}
      value={selectedOperator}
      onChange={handleOperatorChange}
      filterLabelPrefix={label}
      valueLabel={getValueLabel()}
      hasValue={hasValue}
      onClear={handleClear}
      {...(isAnyOrEmpty ? {} : { delimiter: '' })}
      renderFieldComponent={
        isAnyOrEmpty ? null : (
          <NumberField
            placeholder={formatMessage(messages.typeToFilter)}
            value={currentNumber}
            onChange={handleDateInputChange}
            isDisabled={isAnyOrEmpty}
          />
        )
      }
    />
  );
};
