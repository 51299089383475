import React, { createContext, useReducer } from 'react';
import { searchReducer } from './SearchContext.reducer';
import { SearchContextType } from './SearchContext.types';
import { getInitialState } from './SearchContext.helpers';
import { useCollectionInfoContext } from '../CollectionInfoContext/CollectionInfo.hooks';

export const SearchContext = createContext<SearchContextType | null>(null);

export const SearchProvider = ({ children }: { children: React.ReactNode }) => {
  const collectionInfo = useCollectionInfoContext();
  const initialState = getInitialState(collectionInfo);
  const [state, dispatch] = useReducer(searchReducer, initialState);

  return <SearchContext.Provider value={{ state, dispatch }}>{children}</SearchContext.Provider>;
};
