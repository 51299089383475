import { Asset } from '@cld/console-apps-types';
import * as yup from '@cld/yup-extra';
import { ResourceStatus, ResourceSubStatus, ResourceSubtype, ResourceType, UploadTypeValues } from '@cld/console-apps-types';
import { SearchStateType } from '../../../context/SearchContext/SearchContext.types';

export interface SearchDataType {
  assets: Array<Asset>;
  nextCursor: string | null | undefined;
  totalCount: number;
  totalCountTruncated: boolean;
}

const assetsSchema = yup
  .object({
    publicId: yup.string().required(),
    displayName: yup.string().nullable(),
    externalId: yup.string().required(),
    format: yup.string().nullable(),
    resourceType: yup.mixed().oneOf(Object.values(ResourceType)).required(),
    resourceSubtype: yup.mixed().oneOf([...Object.values(ResourceSubtype), null]),
    uploadType: yup.mixed().oneOf(UploadTypeValues).required(),
    urls: yup
      .object({
        original: yup.string().required(),
        originalDownload: yup.string().notRequired(),
        preview: yup.string().required(),
        previewDownload: yup.string().optional(),
        thumbnail: yup.string().required(),
        thumbnailDownload: yup.string().optional(),
      })
      .camelCase()
      .required(),
    status: yup.mixed().oneOf(Object.values(ResourceStatus)).required(),
    subStatus: yup.mixed().oneOf([...Object.values(ResourceSubStatus), null]),
  })
  .from('delivery_urls', 'urls')
  .from('type', 'uploadType')
  .from('substatus', 'subStatus')
  .camelCase()
  .required();

export type AssetDTOType = yup.InferType<typeof assetsSchema>;

export const searchDataSchema = yup
  .object({
    assets: yup.array(assetsSchema).defined(),
    nextCursor: yup.string().nullable(),
    totalCount: yup.number().required(),
    totalCountTruncated: yup.boolean().required(),
  })
  .camelCase()
  .noUnknown()
  .required();

/**
 * This DTO pattern is used to decouple from UI's type and response data type
 * If anything changes on the server side we can simply do it here e.g changing 'total_count' name:
 * export type SearchDataDTOType = Omit<SearchDataType, 'total_count'> & { total_search_count: number };
 * See this for more details: https://codewithstyle.info/typescript-dto/
 */
export type SearchDataDTOType = SearchDataType;

export interface QueryKeyType {
  queryKey: string;
  searchParams: SearchStateType;
}

export type PageParam = string | null;
