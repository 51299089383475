import * as ACTION_TYPES from './SearchContext.actionTypes';
import { SearchStateType, DispatchAction } from './SearchContext.types';
import { SortField, SortDirection } from '../../components/AssetGrid/CollectionGridSort';

export const searchReducer = (state: SearchStateType, action: DispatchAction) => {
  const actions = {
    [ACTION_TYPES.SET_FILTERS_LUCENE_QUERY_STRING]: () => {
      const filtersLuceneQueryString = action.filtersLuceneQueryString as string;

      return { ...state, filtersLuceneQueryString };
    },
    [ACTION_TYPES.SET_SORT_FIELD]: () => {
      const sortField = action.sortField as SortField;

      return { ...state, sortField };
    },
    [ACTION_TYPES.SET_SORT_DIRECTION]: () => {
      const sortDirection = action.sortDirection as SortDirection;

      return { ...state, sortDirection };
    },
  };

  return actions[action.type] ? actions[action.type]() : state;
};
