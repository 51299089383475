/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return */
import { useMemo } from 'react';
import { ControlProps, Styles } from 'react-select';
import { math, rgba } from 'polished';
import { mapValues, omit } from 'lodash';
import { FieldValidationState } from '@clds/common-definitions';
import { globalTheme, MixinFunction, useMixin } from '@clds/component-theme';
import { getTypographyRuleMixins, TypographySize } from '@clds/typography';
import { comboboxTheme } from '../theme';
import { ComboboxSize } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSelectStyles = <IsMulti extends boolean>(size: ComboboxSize, validationState: FieldValidationState = 'none'): Styles<any, IsMulti> => {
  const themeValue = useMixin(comboboxTheme);
  const globalThemeValue = useMixin(globalTheme);

  return useMemo(() => {
    const typographyRules = getTypographyRuleMixins(themeValue.typographySize[size] as TypographySize, 'regular');
    const typographyStyle = mapValues(typographyRules, (mixin: MixinFunction) => mixin({ theme: globalThemeValue }));
    const valueContainerHorizontalMargin = math(`${themeValue.input.padding.all[size]} - ${themeValue.multiValue.margin[size]}`);
    const valueContainerLeftMargin = math(`${themeValue.input.padding.left[size]} - ${themeValue.multiValue.margin[size]}`);
    const valueLeftMargin = themeValue.singleValue.marginLeft[size];

    return {
      valueContainer: (base, state: ControlProps<Record<string, unknown>, IsMulti>) => ({
        ...base,
        ...typographyStyle,
        padding: 0,
        margin: `calc(-1px + ${valueContainerHorizontalMargin}) 0 -1px calc(-1px + ${valueContainerLeftMargin})`,
        alignItems: 'flex-start',
        overflow: 'auto',
        overflowY: 'hidden',
        opacity: state.isDisabled ? 0.5 : 1,
      }),
      singleValue: (base) => ({ ...base, ...typographyStyle, marginLeft: valueLeftMargin }),
      control: (base, state) => {
        const getBorderColor = () => {
          if (state.isDisabled) {
            return themeValue.border.color.disabled;
          }

          if (state.isFocused) {
            return themeValue.border.color.focus;
          }

          return validationState === 'none' ? themeValue.border.color.base : themeValue.border.color[validationState];
        };

        return {
          ...base,
          borderColor: getBorderColor(),
          '&:hover': {
            borderColor: themeValue.border.color.hover,
          },
          borderWidth: themeValue.border.width,
          borderRadius: themeValue.border.radius,
          transition: 'border-color 250ms, box-shadow 250ms',
          backgroundColor: themeValue.background.color.base,
          outline: 'none',
          boxShadow: state.isFocused ? `0 0 0 3px ${rgba(themeValue.outline.color.base, 0.5)}` : 'none',
          minHeight: themeValue.minHeight[size],
          minWidth: 'inherit',
        };
      },
      input: ({ ...rest }) => ({
        ...rest,
        marginLeft: valueLeftMargin,
        '[data-test-specifier="root-chip"] + &': { marginLeft: 0 }, // do not add margin if text field appears after chips
        padding: 0,
        alignSelf: 'center',
        ...typographyStyle,
      }),
      placeholder: (base) => ({
        ...base,
        color: themeValue.input.color.placeholder,
        marginLeft: valueLeftMargin,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '90%',
      }),
      indicatorsContainer: (base) => ({ ...base, margin: 0 }),
      menu: (base) => ({
        ...base,
        boxShadow: `0 0 0 1px ${globalThemeValue.palette.secondaryAlt}, ${globalThemeValue.shadow.sm}`,
        borderRadius: globalThemeValue.radius.lg,
        marginTop: globalThemeValue.spacing.xxs,
        marginBottom: globalThemeValue.spacing.xxs,
        background: themeValue.menu.background.color,
      }),
      menuList: (props) => omit(props, 'paddingTop', 'paddingBottom'),
    };
  }, [size, globalThemeValue, themeValue, validationState]);
};
