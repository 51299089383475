import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';

export const StyledChipLabel = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  max-width: 215px;
  svg {
    margin-right: ${globalTheme.spacing.xxs};
  }
`;

export const StyledFilterLabelPrefix = styled.span<{ hasValue: boolean }>`
  margin-right: ${({ hasValue }) => hasValue && globalTheme.spacing.xxs};
`;

export const StyledTruncated = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
`;
