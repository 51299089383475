import React, { useCallback, useState } from 'react';
import { AsyncMultiSelectFilter, MultiSelectOptionType, MultiSelectGroupOptionType, useCldFilters } from '../../../../cld-filters';
import {
  FilterComponentBasePropsType,
  MetadataFieldOption,
  SearchFilterValuesType,
  SearchFiltersNames,
  SmdFilterComponentBasePropsType,
} from '../../types';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { fetchMetadataOptions } from '../../../../api/autocomplete/helpers';

const createGroup = (options: MultiSelectOptionType[] | undefined) => [
  {
    label: 'Unselected',
    options,
  },
];
const parentField = SearchFiltersNames.SmdMultiple;

export const SmdMultiple = ({ onFilterInteract, onClear, filterDefinition }: SmdFilterComponentBasePropsType) => {
  const { values, setSmdFilterValue } = useCldFilters<SearchFilterValuesType>();
  const [options, setOptions] = useState<MultiSelectGroupOptionType[]>([]);
  const { externalId, label } = filterDefinition ?? {};

  const parentValue = values[parentField as keyof typeof values];
  const fieldValue = parentValue?.[externalId as keyof typeof parentValue];
  const fieldValues = fieldValue?.['values'] || [];

  const optionCreator = (value: MultiSelectOptionType) => ({
    id: value.id,
    value: value.value,
    label: value.label,
  });
  const apiOptionCreator = (item: MetadataFieldOption) => ({
    id: item.externalId,
    value: item.externalId,
    label: item.value,
  });
  const handleChange = useCallback(
    (selectedValues: MultiSelectOptionType[]) => {
      const valueToSet = {
        operator: 'OR',
        values: selectedValues.map((t) => {
          return {
            id: t.id,
            value: t.value,
            label: t.label,
          };
        }),
      };
      if (externalId) {
        setSmdFilterValue(parentField, externalId, valueToSet);
        onFilterInteract(parentField, valueToSet);
      }
    },
    [setSmdFilterValue, onFilterInteract]
  );

  const loadOptions = async (newInput: string) => {
    const values = await fetchMetadataOptions(newInput, externalId as string);
    const options = values?.map((value) => apiOptionCreator(value));
    const optionsGroup = createGroup(options) as MultiSelectGroupOptionType[];
    setOptions(optionsGroup);
  };

  const handleClear = () => {
    onClear(SearchFiltersNames.SmdMultiple);
  };
  if (!filterDefinition) {
    return <></>;
  }
  return (
    <AsyncMultiSelectFilter
      values={fieldValues}
      onClear={handleClear}
      optionCreator={optionCreator}
      onChange={handleChange}
      options={options}
      filterLabelPrefix={label as string}
      dataTest="smd-multiple"
      withFilterInput
      loadOptions={loadOptions}
    />
  );
};
