/* istanbul ignore file */
/**
 * VirtualizedGrid was copy-pasted from console repo
 */
import styled, { css } from 'styled-components';
import EmptyState from '@cld/empty-state';

export interface ParentProps {
  isEmptyList: boolean;
}

export const StyledParent = styled.div<ParentProps>`
  // This is a must since virtual list needs to know the height of its parent
  height: 100%;
  width: 100%;
  overflow: auto;
  ${({ isEmptyList }) =>
    isEmptyList &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export interface ListContainerProps {
  'data-test': string | undefined;
  height: number;
  width: number;
  center: boolean;
}

export interface VirtualRowProps {
  start: number;
  gap: number;
}

export interface ItemContainerProps {
  width: number;
}

export const StyledListContainer = styled.div<ListContainerProps>`
  position: relative;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  display: flex;
  justify-content: center;
  margin: ${({ center }) => (center ? '0 auto' : 'unset')};
`;

export const StyledVirtualRow = styled.div<VirtualRowProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: ${({ start }) => `translateY(${start}px)`};
  display: flex;
  flex-wrap: wrap;
  gap: ${({ gap }) => `${gap}px`};
  overflow: auto;
  padding: ${({ gap }) => `0 ${gap}px ${gap}px`};
  overflow: hidden;
`;

export const StyledItemContainer = styled.div<ItemContainerProps>`
  width: ${({ width }) => `${width}px`};
  display: flex;
  justify-content: center;
`;

export const ProgressBarWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledEmptyState = styled(EmptyState)`
  flex: 1;
`;
