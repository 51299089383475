import React, { FunctionComponent } from 'react';
import { Placement, Tooltip } from '@clds/tooltip';
import { Indicator } from './Indicator';
import { StyledToggleButton } from './CollapsiblePanel.styled';
import { Direction } from './shared/consts';

export interface ToggleButtonWithTooltipProps {
  direction: Direction;
  show: boolean;
  expanded?: boolean;
  toggle?: () => void;
  tooltipTitle?: string;
  tooltipPosition?: Placement;
}

export const ToggleButtonWithTooltip: FunctionComponent<
  React.PropsWithChildren<ToggleButtonWithTooltipProps>
> = ({ show, toggle, direction, expanded, tooltipTitle, tooltipPosition }) => {
  return (
    <Tooltip title={tooltipTitle} placement={tooltipPosition}>
      <StyledToggleButton
        size="sm"
        show={show}
        shape="pill"
        variant="secondary"
        onClick={toggle}
        direction={direction}
        className="collapsible-panel-toggle-button"
        data-test-specifier="collapsible-panel-toggle-button"
        leftSlot={<Indicator direction={direction} isExpanded={expanded} />}
      ></StyledToggleButton>
    </Tooltip>
  );
};
