import React, { FC, ReactNode } from 'react';
import { ImplicitMixedControlProps } from '@clds/common-definitions';
import Popover, { PopoverProps } from '@clds/popover';
import { Menu, MenuStateProps, useMenuState } from '@clds/menu-old';
import { useExplicitSelectionState } from './DropdownMenu.hooks';
import { useDropdownMenu } from './headless/useDropdownMenu';
import { useDropdownMenuState } from './headless/useDropdownMenuState';

type SupportedPopoverProps = Partial<Pick<PopoverProps, 'placement' | 'afterVisibleChange' | 'align' | 'getTooltipContainer' | 'minWidth' | 'maxWidth'>>;

interface DropdownMenuBaseProps extends SupportedPopoverProps {
  items: ReactNode;
  focusOnClose?: boolean;
  'data-test-specifier'?: string;
  maxHeight?: 'md' | 'lg' | 'none';
  triggerAction?: 'click' | 'hover';
  children?: ReactNode;
}

export type DropdownMenuProps = DropdownMenuBaseProps & MenuStateProps & ImplicitMixedControlProps<'opened', boolean>;

export const DropdownMenu: FC<DropdownMenuProps> = ({
  defaultOpened,
  isOpened,
  onOpenedChange,
  children,
  items,
  onSelect,
  minWidth,
  maxWidth,
  maxHeight,
  defaultFocusedId,
  defaultSelectedId,
  focusedId,
  selectedId,
  onFocusedIdChange,
  focusOnClose = true,
  triggerAction = 'click',
  'data-test-specifier': dataTestSpecifier = '',
  ...rest
}) => {
  const explicitSelectionState = useExplicitSelectionState({ defaultSelectedId, selectedId, onSelect });
  const menuState = useMenuState({
    defaultFocusedId,
    focusedId,
    onFocusedIdChange,
    ...explicitSelectionState,
  });
  const dropdownMenuState = useDropdownMenuState({ defaultOpened, isOpened, onOpenedChange }, menuState);
  const dropdownMenu = useDropdownMenu({ items, trigger: children, focusOnClose }, dropdownMenuState);

  const menu = (
    <Menu
      variant={{ selectionVariant: 'subtle', borderRadius: 'md' }}
      {...dropdownMenu.menuProps}
      maxHeight={maxHeight}
      ref={dropdownMenu.navigationRef}
      data-test="menu"
    >
      {dropdownMenu.connectedItems}
    </Menu>
  );

  return (
    <Popover
      placement="bottomRight"
      animation="zoom"
      trigger={triggerAction}
      minWidth={minWidth}
      maxWidth={maxWidth}
      paperProps={{ isPadded: false, borderRadius: 'md' }}
      overlay={menu}
      data-test-specifier={`dropdown-menu${dataTestSpecifier ? '-' + dataTestSpecifier : ''}`}
      {...dropdownMenu.popoverProps}
      {...rest}
    >
      {dropdownMenu.connectedTrigger}
    </Popover>
  );
};
