import { ASSETS_GROUPS } from '../../SearchFilters.constants';

export enum GROUPS {
  BASIC,
  MORE,
}

export const ALL_ASSETS_FORMATS = Object.freeze({
  jpg: { value: 'jpg', type: ASSETS_GROUPS.IMAGE, group: GROUPS.BASIC },
  png: { value: 'png', type: ASSETS_GROUPS.IMAGE, group: GROUPS.BASIC },
  gif: { value: 'gif', type: ASSETS_GROUPS.IMAGE, group: GROUPS.BASIC },
  pdf: { value: 'pdf', type: ASSETS_GROUPS.IMAGE, group: GROUPS.BASIC },
  mp4: { value: 'mp4', type: ASSETS_GROUPS.VIDEO, group: GROUPS.BASIC },
  mov: { value: 'mov', type: ASSETS_GROUPS.VIDEO, group: GROUPS.BASIC },
  mp3: { value: 'mp3', type: ASSETS_GROUPS.AUDIO, group: GROUPS.BASIC },
  doc: { value: 'doc', type: ASSETS_GROUPS.TXT, group: GROUPS.BASIC },
  bmp: { value: 'bmp', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  ico: { value: 'ico', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  tiff: { value: 'tiff', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  eps: { value: 'eps', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  jpc: { value: 'jpc', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  jp2: { value: 'jp2', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  psd: { value: 'psd', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  psb: { value: 'psb', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  webp: { value: 'webp', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  svg: { value: 'svg', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  wdp: { value: 'wdp', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  hpx: { value: 'hpx', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  djvu: { value: 'djvu', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  ai: { value: 'ai', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  flif: { value: 'flif', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  bpg: { value: 'bpg', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  miff: { value: 'miff', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  tga: { value: 'tga', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  heic: { value: 'heic', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },
  indd: { value: 'indd', type: ASSETS_GROUPS.IMAGE, group: GROUPS.MORE },

  css: { value: 'css', type: ASSETS_GROUPS.TXT, group: GROUPS.MORE },
  js: { value: 'js', type: ASSETS_GROUPS.TXT, group: GROUPS.MORE },
  json: { value: 'json', type: ASSETS_GROUPS.TXT, group: GROUPS.MORE },
  zip: { value: 'zip', type: ASSETS_GROUPS.TXT, group: GROUPS.MORE },
  docx: { value: 'docx', type: ASSETS_GROUPS.TXT, group: GROUPS.MORE },
  otf: { value: 'otf', type: ASSETS_GROUPS.TXT, group: GROUPS.MORE },
  ttf: { value: 'ttf', type: ASSETS_GROUPS.TXT, group: GROUPS.MORE },
  fnt: { value: 'fnt', type: ASSETS_GROUPS.TXT, group: GROUPS.MORE },
  txt: { value: 'txt', type: ASSETS_GROUPS.TXT, group: GROUPS.MORE },
  m3u8: { value: 'm3u8', type: ASSETS_GROUPS.TXT, group: GROUPS.MORE },
  mpd: { value: 'mpd', type: ASSETS_GROUPS.TXT, group: GROUPS.MORE },
  max: { value: 'max', type: ASSETS_GROUPS.TXT, group: GROUPS.MORE },

  aac: { value: 'aac', type: ASSETS_GROUPS.AUDIO, group: GROUPS.MORE },
  ogg: { value: 'ogg', type: ASSETS_GROUPS.AUDIO, group: GROUPS.MORE },
  m4a: { value: 'm4a', type: ASSETS_GROUPS.AUDIO, group: GROUPS.MORE },
  wav: { value: 'wav', type: ASSETS_GROUPS.AUDIO, group: GROUPS.MORE },
  aiff: { value: 'aiff', type: ASSETS_GROUPS.AUDIO, group: GROUPS.MORE },
  flac: { value: 'flac', type: ASSETS_GROUPS.AUDIO, group: GROUPS.MORE },

  avi: { value: 'avi', type: ASSETS_GROUPS.VIDEO, group: GROUPS.MORE },
  '3gp': { value: '3gp', type: ASSETS_GROUPS.VIDEO, group: GROUPS.MORE },
  '3g2': { value: '3g2', type: ASSETS_GROUPS.VIDEO, group: GROUPS.MORE },
  wmv: { value: 'wmv', type: ASSETS_GROUPS.VIDEO, group: GROUPS.MORE },
  mpeg: { value: 'mpeg', type: ASSETS_GROUPS.VIDEO, group: GROUPS.MORE },
  mkv: { value: 'mkv', type: ASSETS_GROUPS.VIDEO, group: GROUPS.MORE },
  webm: { value: 'webm', type: ASSETS_GROUPS.VIDEO, group: GROUPS.MORE },
  ogv: { value: 'ogv', type: ASSETS_GROUPS.VIDEO, group: GROUPS.MORE },
  flv: { value: 'flv', type: ASSETS_GROUPS.VIDEO, group: GROUPS.MORE },
  ts: { value: 'ts', type: ASSETS_GROUPS.VIDEO, group: GROUPS.MORE },

  bw: { value: 'bw', type: ASSETS_GROUPS.THREE_D, group: GROUPS.MORE },
  fbx: { value: 'fbx', type: ASSETS_GROUPS.THREE_D, group: GROUPS.MORE },
  glb: { value: 'glb', type: ASSETS_GROUPS.THREE_D, group: GROUPS.MORE },
  gltf: { value: 'gltf', type: ASSETS_GROUPS.THREE_D, group: GROUPS.MORE },
  obj: { value: 'obj', type: ASSETS_GROUPS.THREE_D, group: GROUPS.MORE },
  ply: { value: 'ply', type: ASSETS_GROUPS.THREE_D, group: GROUPS.MORE },
  u3ma: { value: 'u3ma', type: ASSETS_GROUPS.THREE_D, group: GROUPS.MORE },
  usdz: { value: 'usdz', type: ASSETS_GROUPS.THREE_D, group: GROUPS.MORE },
  gltz: { value: 'gltz', type: ASSETS_GROUPS.THREE_D, group: GROUPS.MORE },
  fbxz: { value: 'fbxz', type: ASSETS_GROUPS.THREE_D, group: GROUPS.MORE },
});

export const FORMATS_LABELS = Object.freeze({
  gif: 'GIF',
  png: 'PNG',
  jpg: 'JPG',
  bmp: 'BMP',
  ico: 'ICO',
  pdf: 'PDF',
  tiff: 'TIFF',
  indd: 'INDD',
  eps: 'EPS',
  jpc: 'JPC',
  jp2: 'JP2',
  psd: 'PSD',
  psb: 'PSB',
  zip: 'ZIP',
  svg: 'SVG',
  mp4: 'MP4',
  webm: 'WebM',
  webp: 'WebP',
  wdp: 'WDP',
  hpx: 'HPX',
  djvu: 'DJVU',
  ai: 'AI',
  flif: 'FLIF',
  bpg: 'BPG',
  miff: 'MIFF',
  tga: 'TGA',
  heic: 'HEIC',
  css: 'CSS',
  js: 'JS',
  json: 'JSON',
  mp3: 'MP3',
  aac: 'AAC',
  ogg: 'OGG',
  m4a: 'M4A',
  wav: 'WAV',
  aiff: 'AIFF',
  flac: 'FLAC',
  avi: 'AVI',
  mov: 'MOV',
  '3gp': '3GP',
  '3g2': '3G2',
  wmv: 'WMV',
  mpeg: 'MPEG',
  mkv: 'MKV',
  ogv: 'OGV',
  flv: 'FLV',
  m3u8: 'M3U8',
  ts: 'TS',
  mpd: 'MPD',
  doc: 'DOC',
  docx: 'DOCX',
  otf: 'OTF',
  ttf: 'TTF',
  fnt: 'FNT',
  txt: 'TXT',
  max: 'MAX',
  bw: 'BW',
  fbx: 'FBX',
  glb: 'GLB',
  gltf: 'GLTF',
  obj: 'OBJ',
  ply: 'PLY',
  u3ma: 'U3MA',
  usdz: 'USDZ',
  gltz: 'GLTZ',
  fbxz: 'FBXZ',
});
