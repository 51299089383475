export enum QueryTypesType {
  Keyword = 'keyword',
}

export enum DimensionValues {
  QueryValue = 'queryValue',
  ResourceTYpe = 'resourceType',
}

export interface DimensionsQueryData {
  [DimensionValues.QueryValue]: string;
  [DimensionValues.ResourceTYpe]: string;
}

export enum OPERATOR_FILTER_OPERATORS_KEYS {
  CONTAINS = ':',
  EQUALS = '=',
  ANY = 'any',
  EMPTY = 'empty',
  LESS = '<',
  LESS_EQUAL = '<=',
  MORE_EQUAL = '>=',
  MORE = '>',
}
