import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../i18n';

const useWeekdayName = () => {
  const { formatMessage } = useIntl();
  const weekDays = useMemo(
    () => [
      formatMessage(messages.sunday),
      formatMessage(messages.monday),
      formatMessage(messages.tuesday),
      formatMessage(messages.wednesday),
      formatMessage(messages.thursday),
      formatMessage(messages.friday),
      formatMessage(messages.saturday),
    ],
    [formatMessage]
  );
  return weekDays;
};
export default useWeekdayName;
