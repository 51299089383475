/* istanbul ignore file */
/**
 * VirtualizedGrid was copy-pasted from console repo
 */
import { QueryFunctionContext } from '@tanstack/react-query';
import { getProperties } from '@cld/console-apps-services';
import { GRID_MAX_COLUMNS } from '../CollectionGrid.consts';
import { AssetDTOType, QueryKeyType, PageParam, SearchDataDTOType, SearchDataType, searchDataSchema } from './VirtualizedGrid.types';
import { Asset, normalizeAssetFormat, normalizeAssetType, validateAsset } from '@cld/console-apps-types';
import { publicBaseUrl } from '../../../api/api.constants';
import { SearchStateType } from '../../../context/SearchContext/SearchContext.types';

export const PAGE_SIZE = GRID_MAX_COLUMNS * GRID_MAX_COLUMNS;

export const getNumColumns = (numItems: number, maxColumns: number, itemWidth: number, itemPadding: number, containerWidth: number): number => {
  const optionalNumColumns = numItems < maxColumns ? numItems : maxColumns;
  for (let numColumns = optionalNumColumns; numColumns > 0; numColumns--) {
    const requiredWidthForColumns = itemWidth * numColumns + (numColumns + 1) * itemPadding;
    if (containerWidth >= requiredWidthForColumns) {
      return numColumns;
    }
  }
  return 1;
};

const buildRequestUrl = (customerId: string, collectionId: string, searchParams: SearchStateType, nextCursor?: string | null | undefined): string => {
  const params: Record<string, string> = {
    max_results: `${PAGE_SIZE}`,
    ...(nextCursor ? { next_cursor: nextCursor } : {}),
    expression: searchParams.filtersLuceneQueryString || '',
    sort_by: `${searchParams.sortField} ${searchParams.sortDirection}`,
  };
  const queryParams = new URLSearchParams(params).toString();

  return `${publicBaseUrl}/${customerId}/${collectionId}/search?${queryParams}`;
};

export const parseSearchData = (rawData: unknown): SearchDataType => {
  // Yup schema also converts to camel case
  // @ts-expect-error Silencing existing error, please fix it
  const searchDataDTO: SearchDataDTOType = searchDataSchema.validateSync(rawData);

  // Convert DTO to UI's Type
  // @ts-expect-error Silencing existing error, please fix it
  const assets: Array<Asset> = searchDataDTO.assets.map((assetDto: AssetDTOType) => {
    const type = normalizeAssetType(assetDto.resourceType, assetDto.resourceSubtype);
    const format = normalizeAssetFormat(assetDto.resourceType, assetDto.publicId, assetDto.format);
    return validateAsset(assetDto.uploadType, type, { ...assetDto, format, type });
  });

  const searchData: SearchDataType = {
    assets,
    nextCursor: searchDataDTO.nextCursor,
    totalCount: searchDataDTO.totalCount,
    totalCountTruncated: searchDataDTO.totalCountTruncated,
  };

  return searchData;
};

export const fetchAssets = async ({ pageParam: nextCursor, queryKey }: QueryFunctionContext<QueryKeyType[], PageParam>) => {
  const [{ searchParams }] = queryKey;
  const { customerId, resourceId: collectionId } = getProperties();

  if (!(collectionId && customerId)) {
    throw new Error('corrupted properties');
  }

  const requestUrl = buildRequestUrl(customerId, collectionId, searchParams, nextCursor);
  const response: Response = await fetch(requestUrl);
  const data = await response.json();
  const parsedResponse = parseSearchData(data);

  return parsedResponse;
};
