import React, { FC, ReactNode } from 'react';
import { ComboboxItem } from '@cld/combobox';
import { Search } from '@clds/icon';
import { ItemLayout } from '@clds/item';
import { Button } from '@clds/button-old';
import Tooltip from '@clds/tooltip';
import { useIntl } from 'react-intl';
import {
  StyledControl,
  StyledHorizontalSeparator,
  StyledOption,
  StyledOptionLabel,
  StyledSearchInput,
  StyledSearchInputContainer,
} from './MultiSelectFilter.styles';
import { messages } from '../../../i18n';

// @ts-expect-error TODO: Add types
const Option = (props) => {
  const { data, innerProps } = props;
  return (
    <StyledOption>
      <ComboboxItem {...props} innerProps={{ ...innerProps, showCheckIconWhenSelected: true, 'data-test-specifier': data.value }}>
        <ItemLayout leftSlot={data.icon} secondaryContent={data.description}>
          <Tooltip trigger="hover" title={data.label}>
            <StyledOptionLabel>{data.label}</StyledOptionLabel>
          </Tooltip>
        </ItemLayout>
      </ComboboxItem>
    </StyledOption>
  );
};

// @ts-expect-error TODO: Add types
const Input = (props) => {
  const { formatMessage } = useIntl();
  const { ref, value, onChange, onBlur, onFocus, selectProps } = props;
  const isMultipleSelections = !!selectProps.value?.length;
  const isShowClearSelectionEnabled = selectProps?.showClearSelection ?? true;
  const shouldShowClearSelection = isMultipleSelections && isShowClearSelectionEnabled;

  return (
    <>
      <StyledSearchInputContainer>
        <StyledSearchInput
          leadingDecoration={<Search size="sm" />}
          inputRef={ref}
          value={value}
          placeholder={selectProps.placeholder}
          inputProps={{
            onChange,
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          type="text"
        />
        {shouldShowClearSelection && (
          <Button onClick={selectProps.onClickClearSelection} tone="plain" size="sm">
            {formatMessage(messages.clear)}
          </Button>
        )}
      </StyledSearchInputContainer>
      <StyledHorizontalSeparator verticalMargin="xs" />
    </>
  );
};

const Control: FC<{ children: ReactNode }> = ({ children }) => {
  return <StyledControl>{children}</StyledControl>;
};

const MultiValue = () => <></>;

export const getComponents = (withFilterInput: boolean) =>
  withFilterInput
    ? {
        Option,
        Input,
        Control,
        ValueControl: Control,
        DropdownIndicator: null,
        LoadingIndicator: null,
        MultiValue,
      }
    : { Option };
