/** STANDARD QUERY BUILDERS */

export const groupQueriesByAND = (queries: string[]) => {
  return queries
    .filter(Boolean)
    .map((query) => `(${query})`)
    .join(' AND ');
};

export const keywordQuery = (field: string, match: string, operator = '') => {
  return `${field}${operator}"${match}"`;
};

export const customQuery = (field: string, query: string) => {
  return `${field}${query}`;
};

export const combineByOr = (queries: string[]) => {
  if (queries.length === 1) return queries[0];
  return queries.map((query) => `(${query})`).join(' OR ');
};

export const multiSelectQuery = (field: string, options: string[], operator = '') => {
  const queries = options.map((option) => keywordQuery(field, option, operator));
  return combineByOr(queries);
};

export const rangeQuery = (field: string, min: string, max: string) => {
  return `${field}:[${min} TO ${max}]`;
};
