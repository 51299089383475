import { isEmpty } from 'lodash';
import { SMD_NUMBER_OPERATOR_KEYS } from './SmdNumber.constants';
import { SmdOperatorType } from '../../../../cld-filters';

export const getUpdatedSmdFieldValue = (operator: string, value: string | undefined) => {
  const isAnyOrEmpty = operator == SMD_NUMBER_OPERATOR_KEYS.EMPTY || operator == SMD_NUMBER_OPERATOR_KEYS.ANY;
  if (isAnyOrEmpty) {
    return { operator };
  } else if (value) {
    return { operator, value };
  } else {
    return null;
  }
};

export const getUpdatedSmdNumber = ({
  smdNumberValue,
  externalIdToUpdate,
  operator,
  value,
}: {
  smdNumberValue: SmdOperatorType | null;
  externalIdToUpdate: string;
  operator: string;
  value: string | undefined;
}) => {
  const smdFieldValue = getUpdatedSmdFieldValue(operator, value);

  if (smdFieldValue) {
    return { ...(smdNumberValue || {}), [externalIdToUpdate]: smdFieldValue };
  } else {
    if (!smdNumberValue) return null;
    const { [externalIdToUpdate]: removedSmdField, ...updatedSmdTextValue } = smdNumberValue;
    return isEmpty(updatedSmdTextValue) ? null : updatedSmdTextValue;
  }
};
