import { MultiSelectFilter, MultiSelectOptionType, useCldFilters } from '../../../../cld-filters';
import React, { useCallback } from 'react';
import { FilterComponentBasePropsType, SearchFilterValuesType, SearchFiltersNames } from '../../types';
import { useIntl } from 'react-intl';
import { messages } from '../../../../i18n';
import { dimensionsOptions } from './Dimensions.helpers';

export const Dimensions = ({ onFilterInteract, onClear }: FilterComponentBasePropsType) => {
  const { values, setFilterValue } = useCldFilters<SearchFilterValuesType>();
  const { formatMessage } = useIntl();
  const dimensions = values[SearchFiltersNames.Dimensions];

  const handleChange = useCallback(
    (dimensions: MultiSelectOptionType[]) => {
      setFilterValue(SearchFiltersNames.Dimensions, dimensions);
      onFilterInteract(SearchFiltersNames.Dimensions, dimensions);
    },
    [setFilterValue, onFilterInteract]
  );

  const handleClear = () => {
    onClear(SearchFiltersNames.Dimensions);
  };

  return (
    <MultiSelectFilter
      values={dimensions}
      onClear={handleClear}
      // @ts-expect-error TODO: fix this
      onChange={handleChange}
      options={dimensionsOptions}
      filterLabelPrefix={formatMessage(messages.dimensions)}
      dataTest="search-dimensions"
      withFilterInput
    />
  );
};
