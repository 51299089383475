import { useCallback } from 'react';

export const useSessionStorage = (sessionStorageKey: string | undefined) => {
  const value =
    (sessionStorageKey && sessionStorage.getItem(sessionStorageKey)) || null;

  const setSessionStorage = useCallback(
    (value: string | number) => {
      if (sessionStorageKey) {
        sessionStorage.setItem(sessionStorageKey, value.toString());
      }
    },
    [sessionStorageKey],
  );

  return { value, setSessionStorage };
};
