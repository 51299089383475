/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FormattedMessage } from 'react-intl';
import React, { FC } from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { usePhone } from '@clds/style-utils';
import { Asset, VideoAsset } from '@cld/console-apps-types';
import { AssetMetadataVertical } from '@cld/asset-metadata';
import { Button } from '@clds/button-old';
import { Cross, FileDownload } from '@clds/icon';
import { messages } from '../../i18n';
import { useCloudinaryTracker } from '../CloudinaryTrackerProvider/hooks';
import { TextSkeleton } from '@cld/skeleton';
import { getAssetTrackingParams } from '../CloudinaryTrackerProvider/utils';

const StyledTextSkeleton = styled(TextSkeleton)`
  width: 30%;
  height: 45px;
`;

const Root = styled.header`
  display: flex;
  align-items: center;
  padding: ${globalTheme.spacing.md};
  gap: ${globalTheme.spacing.md};
  background-color: ${globalTheme.palette.surface};
`;

const Actions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: ${globalTheme.spacing.xs};
`;

interface HeaderProps {
  asset: Asset;
  onClose: () => void;
  onModalDownload: (asset: Asset) => void;
  trackingAssetPosition: number;
  isDownloadEnabled?: boolean;
}

export const Header: FC<React.PropsWithChildren<HeaderProps>> = ({ asset, onClose, onModalDownload, trackingAssetPosition, isDownloadEnabled }) => {
  const isPhone = usePhone();
  const cloudinaryTracker = useCloudinaryTracker();

  const onClickHandler = () => {
    onModalDownload(asset);

    void cloudinaryTracker({
      eventName: 'collection_asset_preview_download_intent_click',
      ...getAssetTrackingParams({ asset: { ...asset, trackingAssetPosition } }),
    });
  };

  const onCloseHanlder = () => {
    onClose();
    void cloudinaryTracker({
      eventName: 'collection_asset_preview_close_click',
      ...getAssetTrackingParams({ asset: { ...asset, trackingAssetPosition } }),
    });
  };

  return (
    <Root>
      {asset ? (
        <AssetMetadataVertical
          type={asset.type}
          filename={asset.displayName || asset.filename}
          bytes={asset.bytes}
          height={(asset as VideoAsset).height}
          width={(asset as VideoAsset).width}
          duration={(asset as VideoAsset).duration}
          color="contrastLow"
        />
      ) : (
        <StyledTextSkeleton isLoading />
      )}
      <Actions>
        {isDownloadEnabled && (
          <Button variant="primary" onClick={onClickHandler} data-test="download-button" isDisabled={!asset} leftSlot={<FileDownload />}>
            {!isPhone && <FormattedMessage {...messages.assetDownload} />}
          </Button>
        )}
        <Button onClick={onCloseHanlder} tone="surface" variant="secondary" data-test="close-button" leftSlot={<Cross />} />
      </Actions>
    </Root>
  );
};

export const HeaderHeight = {
  desktop: 76,
  phone: 70,
};
