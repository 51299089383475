import React, { FC, ReactNode, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePhone } from '@clds/style-utils';
import { Tooltip } from '@clds/tooltip';
import { Button } from '@clds/button-old';
import { FileDownload } from '@clds/icon';
import { messages } from '../../i18n';
import { useCloudinaryTracker } from '../CloudinaryTrackerProvider/hooks';
import { DesktopLanguageSelector } from './LanguageSelector/DesktopLanguageSelector';
import { MobileLanguageSelector } from './LanguageSelector/MobileLanguageSelector';
import { COLLECTION_KINDS, SharedPreset } from '../../types';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';
import {
  Count,
  Root,
  StyledCollectionLogo,
  StyledHeaderContainer,
  StyledHeaderLeftSlot,
  StyledHeaderRightSlot,
  StyleTypography,
  Title,
} from './CollectionHeaderItem.styles';
import { getThemeIcon, ThemeContext } from '../Theme/CollectionTheme.helpers';
import { Typography } from '@clds/typography';
import { DropdownMenu } from '@cld/dropdown-menu';
import { DownloadCollectionMenuItem } from '../DownloadCollectionMenuItem/DownloadCollectionMenuItem';
import { useSearchState } from '../../context/SearchContext/SearchContext.hooks';
import { useSearchTotalsSelector } from './VirtualizedGrid/VirtualizedGrid.selectors';
import { DownloadType } from '../Enums/Enum';

export interface CollectionHeaderItemProps {
  collectionTitle: ReactNode;
  shouldDisplayDownloadBtn: boolean;
  encryptedAccountId: string;
  presets: Array<SharedPreset>;
  onDownload: (downloadType: DownloadType) => void;
  isDownloadPrevented?: boolean;
}

export const CollectionHeaderItem: FC<React.PropsWithChildren<CollectionHeaderItemProps>> = ({
  collectionTitle,
  onDownload,
  shouldDisplayDownloadBtn,
  encryptedAccountId,
  isDownloadPrevented,
  ...rest
}) => {
  const isPhone = usePhone();
  const collectionInfo = useCollectionInfoContext();
  const { formatMessage } = useIntl();
  const collectionDownloadMessage = formatMessage(messages.collectionDownload);
  const cloudinaryTracker = useCloudinaryTracker();
  const { themeName, toggleTheme } = useContext(ThemeContext);
  const ThemeIcon = getThemeIcon(themeName);
  const isDownloadButtonDisabled = collectionInfo.totalCount > collectionInfo.downloadAssetsLimit;
  const isTempCollection = collectionInfo.collectionKind === COLLECTION_KINDS.TEMP;
  const title = isTempCollection ? formatMessage(messages.tempCollectionTitle) : collectionTitle;
  const { totalCount } = useSearchTotalsSelector();
  const isSearchResultsExist = totalCount && totalCount > 0;
  const isDownloadSearchResult = totalCount && totalCount > collectionInfo.downloadAssetsLimit;
  const searchParams = useSearchState();
  const isUserSearch = searchParams?.filtersLuceneQueryString && searchParams?.filtersLuceneQueryString.length > 0;
  const ShouldDisplayMultipleDownloadOptions = isUserSearch && isSearchResultsExist && !isDownloadSearchResult;

  const onClickDownload = async (downloadType: DownloadType) => {
    onDownload(downloadType);
    await cloudinaryTracker({ eventName: `collection_download_intent_click_${downloadType}` });
  };

  const handleTooltipVisibleChange = (visible: boolean) => {
    if (!visible) return;
    cloudinaryTracker({ eventName: 'collection_download_disabled_tooltip' });
  };

  return (
    <StyledHeaderContainer>
      <Root {...rest} data-test="collection-header">
        <StyledHeaderLeftSlot>
          <StyledCollectionLogo encryptedAccountId={encryptedAccountId} />
          <Title>
            <Tooltip maxWidth="lg" placement="bottomRight" title={title}>
              <Tooltip.OverflowTrigger>{title}</Tooltip.OverflowTrigger>
            </Tooltip>
          </Title>
        </StyledHeaderLeftSlot>

        <StyledHeaderRightSlot>
          <Tooltip title={formatMessage(messages[`${themeName}Theme`])}>
            <Button onClick={toggleTheme} variant="secondary" size="sm" type="button" tone="plain" leftSlot={<ThemeIcon />} />
          </Tooltip>
          {isPhone ? <MobileLanguageSelector /> : <DesktopLanguageSelector />}

          {shouldDisplayDownloadBtn &&
            (ShouldDisplayMultipleDownloadOptions ? (
              <DropdownMenu
                minWidth="sm"
                placement="bottom"
                triggerAction="hover"
                getTooltipContainer={(containerElement) => containerElement}
                items={
                  <>
                    <DownloadCollectionMenuItem
                      onClickDownload={onClickDownload}
                      disabled={isDownloadButtonDisabled}
                      downloadType={DownloadType.All}
                      secondaryContent={
                        isDownloadPrevented && <FormattedMessage {...messages.countAssets} values={{ count: collectionInfo.totalCount }} />
                      }
                      handleTooltipVisibleChange={handleTooltipVisibleChange}
                      isDownloadButtonDisabled={isDownloadButtonDisabled}
                    >
                      <Typography size="xs" type="regular">
                        <FormattedMessage {...messages.collectionDownload} />
                      </Typography>
                    </DownloadCollectionMenuItem>
                    <DownloadCollectionMenuItem
                      onClickDownload={onClickDownload}
                      downloadType={DownloadType.SEARCHED_ASSETS}
                      isStriped={true}
                      secondaryContent={
                        isDownloadPrevented &&
                        (totalCount && totalCount > 1 ? (
                          <FormattedMessage {...messages.countAssets} values={{ count: totalCount }} />
                        ) : (
                          <FormattedMessage {...messages.countAsset} values={{ count: totalCount }} />
                        ))
                      }
                    >
                      <StyleTypography size="xs" type="regular">
                        <FormattedMessage {...messages.downloadSearchAssets} />
                      </StyleTypography>
                    </DownloadCollectionMenuItem>
                  </>
                }
              >
                <div>
                  <Button data-test-specifier="download-collection-button" aria-label={collectionDownloadMessage} leftSlot={<FileDownload />} />
                </div>
              </DropdownMenu>
            ) : (
              <Tooltip
                maxWidth="md"
                placement="bottomLeft"
                onVisibleChange={handleTooltipVisibleChange}
                title={
                  isDownloadButtonDisabled && (
                    <FormattedMessage {...messages.disabledDownloadTooltip} values={{ limit: collectionInfo.downloadAssetsLimit }} />
                  )
                }
              >
                <div>
                  <Button
                    data-test-specifier="download-collection-button"
                    onClick={isDownloadButtonDisabled ? undefined : () => onClickDownload(DownloadType.All)}
                    aria-label={collectionDownloadMessage}
                    isDisabled={isDownloadButtonDisabled}
                    leftSlot={<FileDownload />}
                  />
                </div>
              </Tooltip>
            ))}
        </StyledHeaderRightSlot>
      </Root>
    </StyledHeaderContainer>
  );
};
