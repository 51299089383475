import { CollectionInfoType } from '../../types';
import { CollectionInfoContext } from './CollectionInfoProvider';
import { useContext } from 'react';

export const useCollectionInfoContext = () => {
  const collectionInfoContext = useContext(CollectionInfoContext) as CollectionInfoType;

  if (!collectionInfoContext) {
    throw new Error('Collection Context: No value provided');
  }

  return collectionInfoContext;
};
