import { SyntheticEvent, useCallback } from 'react';
import { ValueType } from 'react-select';
import { Subtract } from 'utility-types';
import { CommonFieldProps } from '@clds/common-definitions';
import { createFakeEvent } from '@clds/create-fake-event';
import { ComboboxProps } from '../types';

type AdaptedProps<T, IsMulti extends boolean> = Omit<CommonFieldProps<ValueType<T, IsMulti>, SyntheticEvent>, 'children'>;

/**
 * Adapts CommonFieldProps to react-select props
 * @param props all props that should be passed to react-select component
 * @return props that are compatible with react-select
 */
export const useAdaptedProps = <T, IsMulti extends boolean, P extends AdaptedProps<T, IsMulti>>(
  props: P
): Subtract<P, AdaptedProps<T, IsMulti>> & ComboboxProps<T, IsMulti> => {
  const { name, value, defaultValue, isDisabled, isReadonly, validationState, onFocus, onBlur, onChange, onClick, ...rest } = props;
  const readonlyProps = isReadonly ? { isSearchable: false, menuIsOpen: false, isClearable: false } : {};

  const onChangeAdapted = useCallback(
    (eventValue: ValueType<T, IsMulti>) => {
      onChange?.(eventValue, createFakeEvent({ name, value: eventValue }));
    },
    // eslint-ignore-next-line
    [name, onChange]
  );

  return {
    ...rest,
    name,
    value,
    defaultValue,
    isDisabled,
    ...readonlyProps,
    validationState,
    onFocus,
    onBlur,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: onChangeAdapted as any,
    onClick,
  };
};
