import React, { memo, useCallback, useEffect } from 'react';
import { filter, isNull, some, isUndefined } from 'lodash';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FormattedMessage } from 'react-intl';
import { Asset } from '@cld/console-apps-types';
import { useInView } from 'react-intersection-observer';
import { useCloudinaryTracker } from '../CloudinaryTrackerProvider/hooks';
import { CollectionHeaderItem } from './CollectionHeaderItem';
import { CollectionGridItem } from './CollectionGridItem';
import { EmptyCollection } from './EmptyCollection';
import { CARD_HEIGHT, CARD_PADDING, CARD_WIDTH, GRID_MAX_COLUMNS } from './CollectionGrid.consts';
import { VirtualizedGrid } from './VirtualizedGrid/VirtualizedGrid';
import { StyledAssetsGridContainer, StyledGridAndHeaderContainer, StyledTypography, StyledProgressWrapper } from './CollectionGrid.styles';
import { CollectionGridTopSection } from './CollectionGridTopSection';
import { CollectionInfoType, SharedPreset } from '../../types';
import { useAssets } from './VirtualizedGrid/VirtualizedGrid.hooks';
import { getAssetTrackingParams } from '../CloudinaryTrackerProvider/utils';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';
import { PresetTypeEnum } from '../DowloadPresetsModal/types';
import { CollectionDescription } from './CollectionDescription';
import { useSearchTotalsSelector } from './VirtualizedGrid/VirtualizedGrid.selectors';
import { messages } from '../../i18n';
import { DownloadType } from '../Enums/Enum';
import { MosaicLayout } from '../MosaicLayout/MosaicLayout';
import { ItemOverlay } from './item/ItemOverlay';
import { BaseSkeleton } from '@cld/skeleton';

const VIEW = 'MOSAIC';

const CollectionGridPure = ({
  onModalDownload,
  collectionInfo,
  presets,
  preventDownload,
  customLogoPath,
  setPreviewedAssetIndex,
}: CollectionGridProps) => {
  const {
    collection: { name: collectionName, description: collectionDescription },
  } = collectionInfo;

  const { allFetchedAssets, isFetchingNextPage, fetchNextPage, hasNextPage, isPending } = useAssets();
  const { totalCount, totalCountTruncated } = useSearchTotalsSelector();
  const shouldShowTotalAssets = !isUndefined(totalCount) && !isUndefined(totalCountTruncated);

  const collectionIncludesRawAssets = some(allFetchedAssets, { type: 'raw' });
  const hasPresets = presets?.length > 0;
  const canDownload = collectionIncludesRawAssets || hasPresets || !preventDownload;
  const cloudinaryTracker = useCloudinaryTracker();

  const onCollectionDownload = (downloadType: DownloadType) => {
    if (canDownload) {
      onModalDownload(allFetchedAssets, downloadType);
    }
  };

  const renderAssetCard = useCallback(
    (asset: Asset, index: number) => {
      const handleClick = () => {
        void cloudinaryTracker({
          eventName: 'collection_asset_click',
          ...getAssetTrackingParams({ asset: { ...asset, trackingAssetPosition: index + 1 } }),
        });
        setPreviewedAssetIndex(index);
      };
      const presetsByType = isNull(presets)
        ? presets
        : filter(presets, (preset) => preset?.presetTypes?.includes(asset?.type as unknown as PresetTypeEnum));

      return (
        <CollectionGridItem
          data-test-specifier={`${index}-${asset.displayName || asset.filename}`}
          asset={asset}
          key={`${asset.type}/${asset.publicId}`}
          presets={presetsByType}
          onClick={handleClick}
          onDownload={onModalDownload}
          preventDownload={preventDownload}
          trackingAssetPosition={index + 1}
        />
      );
    },
    [cloudinaryTracker, onModalDownload, presets, preventDownload, setPreviewedAssetIndex]
  );

  const renderAssetCardOverlay = useCallback(
    (asset: Asset, index: number) => {
      const handleClick = () => {
        void cloudinaryTracker({
          eventName: 'collection_asset_click',
          ...getAssetTrackingParams({ asset: { ...asset, trackingAssetPosition: index + 1 } }),
        });
        setPreviewedAssetIndex(index);
      };
      const presetsByType = isNull(presets)
        ? presets
        : filter(presets, (preset) => preset?.presetTypes?.includes(asset?.type as unknown as PresetTypeEnum));

      return (
        <ItemOverlay
          presets={presetsByType}
          preventDownload={preventDownload}
          asset={asset}
          onClick={handleClick}
          onDownload={onModalDownload}
          trackingAssetPosition={index + 1}
        />
      );
    },
    [cloudinaryTracker, onModalDownload, presets, preventDownload, setPreviewedAssetIndex]
  );

  const { ref: progressRef, inView } = useInView();
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const renderInfiniteScrollProgress = () =>
    hasNextPage && <StyledProgressWrapper ref={progressRef}>{isFetchingNextPage ? <BaseSkeleton /> : null}</StyledProgressWrapper>;

  return (
    <StyledGridAndHeaderContainer>
      <CollectionHeaderItem
        collectionTitle={collectionName}
        presets={presets}
        onDownload={onCollectionDownload}
        shouldDisplayDownloadBtn={canDownload}
        encryptedAccountId={customLogoPath}
        isDownloadPrevented={!preventDownload}
      />
      <CollectionGridTopSection description={collectionDescription} />
      <StyledAssetsGridContainer>
        <AutoSizer>
          {({ width, height }: { width: number; height: number }) => (
            <div style={{ width, height }}>
              {VIEW === 'MOSAIC' ? (
                <MosaicLayout
                  items={allFetchedAssets}
                  containerWidth={width}
                  dataTest="mosaic-layout"
                  isLoading={isPending}
                  renderAssetCardOverlay={renderAssetCardOverlay}
                  TopScrollableSection={
                    <>
                      <CollectionDescription description={collectionDescription} />
                      <StyledTypography size="xs" type="highContrast">
                        {shouldShowTotalAssets && (
                          <FormattedMessage
                            {...(totalCountTruncated ? messages.isOverLimit : messages.collectionAssetsCount)}
                            values={{ count: totalCount }}
                          />
                        )}
                      </StyledTypography>
                    </>
                  }
                  BottomLoadingMoreProgress={renderInfiniteScrollProgress()}
                />
              ) : (
                <VirtualizedGrid
                  itemHeight={CARD_HEIGHT}
                  itemWidth={CARD_WIDTH}
                  maxColumns={GRID_MAX_COLUMNS}
                  itemPadding={CARD_PADDING}
                  renderItem={renderAssetCard}
                  gridDataTest="collection-grid"
                  containerCurrentWidth={width}
                  TopScrollableSection={
                    <>
                      <CollectionDescription description={collectionDescription} />
                      {shouldShowTotalAssets && (
                        <StyledTypography size="xs" type="highContrast">
                          <FormattedMessage
                            {...(totalCountTruncated ? messages.isOverLimit : messages.collectionAssetsCount)}
                            values={{ count: totalCount }}
                          />
                        </StyledTypography>
                      )}
                    </>
                  }
                />
              )}
            </div>
          )}
        </AutoSizer>
      </StyledAssetsGridContainer>
    </StyledGridAndHeaderContainer>
  );
};

export interface CollectionGridProps {
  collectionInfo: CollectionInfoType;
  onModalDownload: (assets: Asset[] | Asset | null, downloadType?: DownloadType) => void;
  presets: SharedPreset[];
  customLogoPath: string;
  preventDownload: boolean;
  setPreviewedAssetIndex: (index: number) => void;
}

export const CollectionGrid = memo((props: Omit<CollectionGridProps, 'collectionInfo'>) => {
  const collectionInfo: CollectionInfoType = useCollectionInfoContext();
  if (collectionInfo.totalCount > 0) {
    return <CollectionGridPure {...props} collectionInfo={collectionInfo} />;
  }

  return <EmptyCollection collectionName={collectionInfo.collection.name} collectionDescription={collectionInfo.collection.description} />;
});
