import React from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '@cld/global-style';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';
import { useTheme } from './CollectionTheme.hooks';
import { ThemeContext } from './CollectionTheme.helpers';

export const CollectionDynamicTheme = ({ children }: { children: React.ReactNode }) => {
  const collectionInfo = useCollectionInfoContext();
  return (
    <CollectionTheme primaryColor={collectionInfo.preferences?.theme?.primaryColor} primaryColorDark={collectionInfo.preferences?.theme?.primaryColorDark}>
      {' '}
      {children}{' '}
    </CollectionTheme>
  );
};

export const CollectionTheme = ({
  children,
  primaryColor,
  primaryColorDark,
}: {
  children: React.ReactNode;
  primaryColor?: string | null | undefined;
  primaryColorDark?: string | null | undefined;
}) => {
  const { themeName, theme, toggleTheme } = useTheme(primaryColor, primaryColorDark);
  return (
    <ThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ themeName, toggleTheme }}>
        <GlobalStyles />
        {children}
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};
