import React, { FC } from 'react';
import { OptionProps, OptionTypeBase } from 'react-select';
import { useMixin } from '@clds/component-theme';
import Tooltip, { Placement, TooltipThemeProps } from '@clds/tooltip';
import { ItemLayout } from '@clds/item';
import { ComboboxItem } from '../ComboboxItem';
import { comboboxTheme } from '../theme';

export const Option = <IsMulti extends boolean>(props: React.PropsWithChildren<OptionProps<OptionTypeBase, IsMulti>>) => {
  const label = props.selectProps.getOptionLabel?.(props.data) || props.label;
  return (
    <ComboboxItem {...props}>
      <ItemLayout>
        <Tooltip
          title={props.label}
          placement={useMixin(comboboxTheme.tooltip.placement) as Placement}
          maxWidth={useMixin(comboboxTheme.tooltip.maxWidth) as TooltipThemeProps['maxWidth']}
        >
          <Tooltip.OverflowTrigger>{label}</Tooltip.OverflowTrigger>
        </Tooltip>
      </ItemLayout>
    </ComboboxItem>
  );
};
