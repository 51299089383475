import { FreeSearchFilter, useCldFilters } from '../../../cld-filters';
import React from 'react';
import { messages } from '../../../i18n';
import { useIntl } from 'react-intl';
import { FilterComponentBasePropsType, SearchFilterValuesType, SearchFiltersNames } from '../types';

const FreeSearch = ({ onFilterInteract, onClear }: FilterComponentBasePropsType) => {
  const { values, setFilterValue } = useCldFilters<SearchFilterValuesType>();
  const value = values[SearchFiltersNames.FreeSearch];
  const { formatMessage } = useIntl();

  const handleChange = (value: string) => {
    setFilterValue(SearchFiltersNames.FreeSearch, value);
  };

  const handleClear = () => {
    setFilterValue(SearchFiltersNames.FreeSearch, '');
    onClear(SearchFiltersNames.FreeSearch);
  };

  const handleFocus = () => {
    onFilterInteract(SearchFiltersNames.FreeSearch, value);
  };

  return (
    <FreeSearchFilter
      value={value}
      placeholder={formatMessage(messages.searchFiltersFreeSearchPlaceholder)}
      onChange={handleChange}
      onClear={handleClear}
      onFocus={handleFocus}
    />
  );
};

export { FreeSearch };
