import React from 'react';
import { Audio, File, Photo, Video } from '@clds/icon';
import { ThreeD } from '../../../icons';
import { ALL_ASSETS_FORMATS, FORMATS_LABELS, GROUPS } from './Formats.constants';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../../../i18n/messages';

const icons = {
  image: <Photo size="sm" />,
  video: <Video size="sm" />,
  doc: <File size="sm" />,
  audio: <Audio size="sm" />,
  three_d: <ThreeD size="sm" />,
};

const popularFormatsOptions = Object.values(ALL_ASSETS_FORMATS)
  .filter((format) => format.group === GROUPS.BASIC)
  .map((item) => ({
    value: item.value,
    label: FORMATS_LABELS[item.value as keyof typeof FORMATS_LABELS],
    // @ts-expect-error Index type interference error
    icon: icons[item.type],
  }));

const moreFormatsOptions = Object.values(ALL_ASSETS_FORMATS)
  .filter((format) => format.group === GROUPS.MORE)
  .map((item) => ({
    value: item.value,
    label: FORMATS_LABELS[item.value as keyof typeof FORMATS_LABELS],
    // @ts-expect-error TODO: fix this (Index type interference error)
    icon: icons[item.type],
  }));

export const formatsOptions = [
  {
    label: <FormattedMessage {...messages.popular} />,
    options: popularFormatsOptions,
  },
  {
    label: <FormattedMessage {...messages.moreFormats} />,
    options: moreFormatsOptions,
  },
];
