import React, { createContext } from 'react';
import { useCollectionInfo } from '../../api/collectionInfo/hooks';
import { ProgressWrapper } from '../../components/App.styles';
import Progress from '@clds/progress';
import { NotFound } from '@cld/empty-state';
import { useIntl } from 'react-intl';
import { messages } from '../../i18n';
import { CollectionInfoType } from '../../types';
import { CollectionTheme } from '../../components/Theme/CollectionTheme';

export const CollectionInfoContext = createContext<CollectionInfoType | null>(null);

export const CollectionInfoProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, isLoading, isError } = useCollectionInfo();
  const { formatMessage } = useIntl();
  const notFoundMessage = formatMessage(messages.collectionNotFound);

  if (isLoading) {
    return (
      <CollectionTheme>
        <ProgressWrapper data-test-specifier="app-loader">
          <Progress.Circular variant="primary" size="xl" />
        </ProgressWrapper>
      </CollectionTheme>
    );
  }

  if (isError || !data) {
    return (
      <CollectionTheme>
        <NotFound title={notFoundMessage} />
      </CollectionTheme>
    );
  }

  return <CollectionInfoContext.Provider value={data}>{children}</CollectionInfoContext.Provider>;
};
