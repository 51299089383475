import { globalTheme } from '@clds/component-theme';
import { typography } from '@clds/typography';
import styled from 'styled-components';
import Drawer from '@cld/drawer';

export const StyledSearchFiltersContainer = styled.div`
  padding: ${globalTheme.spacing.md};
  width: 100%;
  background-color: ${globalTheme.palette.surface};
  display: flex;
  align-items: center;
  gap: ${globalTheme.spacing.sm};
  flex-wrap: wrap;
`;

export const StyledMobileDrawerContainer = styled.div`
  width: calc(100vw - ${globalTheme.spacing.md});
  max-width: 25rem;
  background-color: ${globalTheme.palette.surface};
  border-top-left-radius: ${globalTheme.radius.xl};
  border-top-right-radius: ${globalTheme.radius.xl};
  padding: ${globalTheme.spacing.md};
  padding-top: 0;
`;

export const StyledDrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${globalTheme.spacing.sm} 0;
`;

export const StyledDrawerFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${globalTheme.spacing.sm};
`;

export const StyledLeftSlot = styled.div`
  display: flex;
  align-items: center;
  gap: ${globalTheme.spacing.xs};
  span {
    ${typography.xs.contrastInvert}
  }
`;

export const StyledDrawer = styled(Drawer)`
  width: revert;
  height: revert;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledFreeSearchWrapper = styled.div`
  padding: 0 ${globalTheme.spacing.sm};
  margin-bottom: ${globalTheme.spacing.xxs};
  width: 100%;
`;
