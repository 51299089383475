import { useCallback, useContext, useEffect, useState } from 'react';
import { THEME, ThemeContext, ThemeType, appThemeGen, getNextTheme } from './CollectionTheme.helpers';

export const useSystemThemeDetector = () => {
  const getCurrentTheme = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());
  const mqListener = (e: MediaQueryListEvent) => {
    setIsDarkTheme(e.matches);
  };

  useEffect(() => {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    darkThemeMq.addEventListener('change', mqListener);
    return () => darkThemeMq.removeEventListener('change', mqListener);
  }, []);
  return isDarkTheme ? THEME.DARK : THEME.LIGHT;
};

export const useTheme = (primaryColor?: string | null | undefined, primaryColorDark?: string | null | undefined) => {
  const [themeName, setThemeName] = useState<ThemeType>((localStorage.getItem('collectionAppTheme') as ThemeType) || THEME.SYSTEM);
  const systemTheme = useSystemThemeDetector();
  let newThemeValue = themeName;
  if (newThemeValue === THEME.SYSTEM) {
    newThemeValue = systemTheme;
  }
  const theme = appThemeGen(newThemeValue, primaryColor, primaryColorDark);

  const toggleTheme = useCallback(() => {
    const nextThemeValue = getNextTheme(themeName);
    setThemeName(nextThemeValue);
    localStorage.setItem('collectionAppTheme', nextThemeValue);
  }, [themeName]);

  return { theme, themeName, toggleTheme };
};

export const useIsDarkTheme = () => {
  const { themeName } = useContext(ThemeContext);
  const systemTheme = useSystemThemeDetector();

  if (themeName === THEME.SYSTEM) {
    return systemTheme === THEME.DARK;
  }
  return themeName === THEME.DARK;
};
