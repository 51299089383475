import { globalTheme } from '@clds/component-theme';
import styled from 'styled-components';

export const StyledParent = styled.div`
  // This is a must since virtual list needs to know the height of its parent
  height: 100%;
  overflow: auto;
  padding-top: ${globalTheme.spacing.md};
`;

export const StyledListContainer = styled.div<{ height: number }>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  box-sizing: border-box;
`;

export const StyledVirtualRow = styled.div<{ height: number; start: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: ${({ height }) => `${height}px`};
  transform: ${({ start }) => `translateY(${start}px)`};
`;

export const StyledSkeletonItem = styled.div<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  width: 100%;
`;

export const StyledItemContainer = styled.div`
  height: 100%;
`;
