/* istanbul ignore file */
import React, { useState } from 'react';
import Popover from '@clds/popover';
import { Button } from '@clds/button-old';
import { ArrowDown, ArrowUp } from '@clds/icon';
import { Tooltip } from '@clds/tooltip';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from '../../i18n';
import { StyledSortContainer } from './CollectionGrid.styles';
import { CollectionGridSortOverlay } from './CollectionGridSortOverlay';
import { useSearchTotalsSelector } from './VirtualizedGrid/VirtualizedGrid.selectors';
import { useSearchDispatch, useSearchState } from '../../context/SearchContext/SearchContext.hooks';
import { setSortField, setSortDirection } from '../../context/SearchContext/SearchContext.actions';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';
import { useCloudinaryTracker } from '../CloudinaryTrackerProvider/hooks';
import { SORT_FIELD_QUERY_PARAM, SORT_DIRECTION_QUERY_PARAM } from './CollectionGrid.consts';
import { upsertQueryParam } from '../../helpers/url.helpers';

export enum SortField {
  DISPLAY_NAME = 'display_name',
  FILE_NAME = 'filename',
  CREATED_AT = 'created_at',
  BYTES = 'bytes',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const CollectionGridSort = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const collectionInfo = useCollectionInfoContext();
  const searchParams = useSearchState();
  const dispatch = useSearchDispatch();
  const { totalCount, totalCountTruncated } = useSearchTotalsSelector();
  const { formatMessage } = useIntl();
  const cloudinaryTracker = useCloudinaryTracker();
  const { sortField, sortDirection } = searchParams;
  const nameField = collectionInfo.flags.folderDecouplingEnabled ? SortField.DISPLAY_NAME : SortField.FILE_NAME;
  const sortItems = [nameField, SortField.CREATED_AT, SortField.BYTES];

  const handleSortFieldChange = (sort: SortField) => {
    dispatch(setSortField(sort));
    upsertQueryParam(SORT_FIELD_QUERY_PARAM, sort);
    setIsPopoverOpen(false);
    const eventParams = { sortField: sort };
    void cloudinaryTracker({
      eventName: 'change_sort_field',
      ...eventParams,
    });
  };

  const handleSortDirectionChange = () => {
    const newDirection = sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
    dispatch(setSortDirection(newDirection));
    upsertQueryParam(SORT_DIRECTION_QUERY_PARAM, newDirection);
    const eventParams = { sortDirection: newDirection };
    void cloudinaryTracker({
      eventName: 'change_sort_direction',
      ...eventParams,
    });
  };

  return (
    <StyledSortContainer>
      <Popover
        destroyTooltipOnHide
        overlay={<CollectionGridSortOverlay sortItems={sortItems} onItemClick={handleSortFieldChange} />}
        paperProps={{ isPadded: false }}
        placement="bottomRight"
        trigger="click"
        popupVisible={isPopoverOpen}
        onVisibleChange={setIsPopoverOpen}
      >
        <Tooltip trigger="hover" placement="left" title={formatMessage(messages.sort)}>
          <Button size="sm" variant="secondary" tone="plain" data-test="grid-sort-by">
            <FormattedMessage {...messages[sortField as keyof typeof messages]} />
          </Button>
        </Tooltip>
      </Popover>
      <Button
        leftSlot={sortDirection === SortDirection.ASC ? <ArrowUp /> : <ArrowDown />}
        onClick={handleSortDirectionChange}
        size="sm"
        variant="secondary"
        tone="plain"
        data-test="grid-sort-order"
      />
    </StyledSortContainer>
  );
};
