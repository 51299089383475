import styled, { css } from 'styled-components';
import { Image, ImageProps } from '../Image/Image';
import { globalTheme } from '@clds/component-theme';
import { AssetWithDimensions } from '../../types';
import { BaseSkeleton } from '@cld/skeleton';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const checkerboard = (color1: any, color2: any, size = 15) => css`
  background-color: ${color2 as string};
  // eslint-disable-next-line
  // @ts-ignore
  background-image: linear-gradient(45deg, ${color1} 25%, transparent 25%), linear-gradient(-45deg, ${color1} 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, ${color1} 75%), linear-gradient(-45deg, transparent 75%, ${color1} 75%);
  background-size: ${size}px ${size}px;
  background-position:
    0 0,
    0 ${size / 2}px,
    ${size / 2}px -${size / 2}px,
    -${size / 2}px 0px;
`;

export const StyledImage = styled(Image)<ImageProps & { shouldHide: boolean }>`
  ${({ shouldHide }) =>
    shouldHide &&
    css`
      opacity: 0;
    `}
`;

interface StyledThumbnailContainerProps {
  asset: AssetWithDimensions;
  itemHeight: number;
  shouldShowCheckers: boolean;
}

export const StyledThumbnailContainer = styled.div<StyledThumbnailContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${({ itemHeight }) => `${itemHeight}px`};
  aspect-ratio: ${({ asset }) => asset?.width / asset?.height};
  ${({ shouldShowCheckers }) => shouldShowCheckers && checkerboard(globalTheme.palette.background, globalTheme.palette.secondary, 12)};
  overflow: hidden;
`;

export const StyledThumbnailSkeleton = styled(BaseSkeleton)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  span {
    border-radius: 0;
  }
`;
