import React from 'react';
// TODO - move StyledParent component to a higher place and use in virtual too
import { StyledMosaicLayoutWrapper, StyledMosaicLayoutRow, StyledParent, StyledEmptyState } from './MosaicLayout.styles';
import { getMockedMosaicRows, getMosaicRows } from './MosaicLayout.helpers';
import { MosaicCardConfiguration, MosaicRowConfiguration } from './MosaicLayout.types';
import { Asset } from '@cld/console-apps-types';
import { AssetCard } from '../AssetCard/AssetCard';
import { AssetWithDimensions } from '../../types';
import { StyledThumbnailContainer, StyledThumbnailSkeleton } from '../Thumbnail/Thumbnail.styles';
import { VirtualizedList } from '../VirtualizedList/VirtualizedList';
import { messages } from '../../i18n';
import * as urls from '../../static';
import { useIntl } from 'react-intl';
import { useIsDarkTheme } from '../Theme/CollectionTheme.hooks';

const renderSkeleton = (mockMosaicRows: MosaicRowConfiguration[]) =>
  mockMosaicRows.map((row, index) => (
    <StyledMosaicLayoutRow key={`mosaic-row-skeleton-${index}`}>
      {row.map((card, index) => (
        // eslint-disable-next-line
        // @ts-ignore
        <StyledThumbnailContainer key={`mosaic-card-skeleton-${index}`} asset={card} itemHeight={card.height} shouldShowCheckers={false}>
          <StyledThumbnailSkeleton />
        </StyledThumbnailContainer>
      ))}
    </StyledMosaicLayoutRow>
  ));
interface MosaicLayoutProps {
  items: Asset[];
  containerWidth: number;
  TopScrollableSection: React.ReactNode;
  BottomLoadingMoreProgress: React.ReactNode;
  renderAssetCardOverlay: (asset: Asset, index: number) => React.ReactNode;
  isLoading: boolean;
  isVirtual?: boolean;
  dataTest: string;
}

export const MosaicLayout = ({
  items,
  containerWidth,
  renderAssetCardOverlay,
  TopScrollableSection,
  BottomLoadingMoreProgress,
  isLoading,
  isVirtual = true,
  dataTest,
}: MosaicLayoutProps) => {
  const mosaicRows = getMosaicRows(containerWidth, items);
  const mockMosaicRows = getMockedMosaicRows(containerWidth);
  const { formatMessage } = useIntl();
  const isDarkTheme = useIsDarkTheme();

  const renderRow = (row: MosaicCardConfiguration[], index: number) => (
    <StyledMosaicLayoutRow key={`mosaic-row-${index}`}>
      {row.map((card: MosaicCardConfiguration) => {
        const typedAsset = card?.originalData as AssetWithDimensions;
        const itemIndex = items.findIndex((item) => item.externalId === typedAsset.externalId);
        return (
          <AssetCard
            key={typedAsset?.externalId}
            index={itemIndex}
            asset={typedAsset}
            cardHeight={card.height}
            cardWidth={card.width}
            renderAssetCardOverlay={renderAssetCardOverlay}
          />
        );
      })}
    </StyledMosaicLayoutRow>
  );

  const renderList = () =>
    mosaicRows &&
    mosaicRows.map((row: MosaicRowConfiguration, index: number) => {
      return (
        <StyledMosaicLayoutRow key={`mosaic-row-${index}`}>
          {row.map((card: MosaicCardConfiguration) => {
            const typedAsset = card?.originalData as AssetWithDimensions;
            const itemIndex = items.findIndex((item) => item.externalId === typedAsset.externalId);
            return (
              <AssetCard
                key={typedAsset?.externalId}
                index={itemIndex}
                asset={typedAsset}
                cardHeight={card.height}
                cardWidth={card.width}
                renderAssetCardOverlay={renderAssetCardOverlay}
              />
            );
          })}
        </StyledMosaicLayoutRow>
      );
    });

  const renderVirtualList = () => {
    const shouldShowEmptyMessage = !isLoading && items.length < 1;
    if (shouldShowEmptyMessage) {
      return (
        <StyledEmptyState
          data-test="empty-state-container"
          imageSrc={isDarkTheme ? urls.searchResultsEmptyStateDark : urls.searchResultsEmptyState}
          subtitle={formatMessage(messages.noResultsSubtitle)}
          title={formatMessage(messages.noResultsTitle)}
        />
      );
    }
    // eslint-disable-next-line
    // @ts-ignore
    return (
      <VirtualizedList<MosaicCardConfiguration[]>
        items={mosaicRows}
        getItemHeight={(index: number) => mosaicRows[index]?.[0]?.height}
        itemGap={8}
        gridPadding={8}
        overscan={5}
        renderItem={renderRow}
        renderSkeleton={() => renderSkeleton(mockMosaicRows)}
        isLoading={isLoading}
        listTop={TopScrollableSection}
        listBottom={BottomLoadingMoreProgress}
      />
    );
  };

  return (
    <StyledParent data-test={dataTest} isEmptyList={false}>
      {isVirtual ? (
        renderVirtualList()
      ) : (
        <>
          {TopScrollableSection}
          <StyledMosaicLayoutWrapper>{isLoading ? renderSkeleton(mockMosaicRows) : renderList()}</StyledMosaicLayoutWrapper>
        </>
      )}
    </StyledParent>
  );
};
