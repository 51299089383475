import React, { ChangeEvent, ComponentType } from 'react';
import { OptionTypeBase } from 'react-select';
import { Subtract } from 'utility-types';
import { CommonFieldProps } from '@clds/common-definitions';
import { ComboboxProps } from './types';

type InjectedProps = Pick<CommonFieldProps<OptionTypeBase, ChangeEvent<HTMLInputElement>>, 'value' | 'onChange'>;

type EnhancedProps = Pick<CommonFieldProps<string | string[], ChangeEvent<HTMLInputElement>>, 'value' | 'onChange'>;

type PassProps<IsMulti extends boolean> = Required<Pick<ComboboxProps<OptionTypeBase>, 'options'>> & Pick<ComboboxProps<OptionTypeBase>, 'getOptionValue'>;

export const withSimpleValue =
  <P extends InjectedProps, IsMulti extends boolean = false>(
    Component: ComponentType<React.PropsWithChildren<P & PassProps<IsMulti>>>
  ): ComponentType<React.PropsWithChildren<Subtract<P, InjectedProps> & EnhancedProps & PassProps<IsMulti>>> =>
  ({ value: simpleValue, options, onChange, getOptionValue, ...restProps }) => {
    const getValue = (value?: OptionTypeBase | null) => {
      if (value === undefined || value === null) {
        return undefined;
      }
      if (getOptionValue) {
        return getOptionValue(value);
      }
      return value.value as string;
    };

    const handleChange: P['onChange'] = (newValue, e) => {
      const optionValue = Array.isArray(newValue) ? newValue.map(getValue) : getValue(newValue);

      // TODO: currently with the fake event from combobox there is value passed in e.target.value
      // TODO: and connectForm take this to use it as field value in formik state
      // TODO: so it has to be replaced
      /* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access */
      // @ts-ignore
      e.target.value = optionValue;
      /* eslint-enable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-member-access */
      onChange?.(optionValue as never, e);
    };

    const findOption = (groupOption: typeof options, selectedValue: typeof simpleValue): OptionTypeBase | undefined => {
      for (const option of groupOption) {
        const value = getValue(option);
        if (value === selectedValue) {
          return option;
        }

        if (Array.isArray(option.options)) {
          return findOption(option.options, selectedValue);
        }

        if (Array.isArray(value)) {
          return findOption(value, selectedValue);
        }
      }
    };

    const valueToOption = (value: typeof simpleValue) => {
      if (value === undefined) {
        return undefined;
      }

      const option = findOption(options, value);
      if (option) {
        return option;
      }
      return {
        value: value,
        label: value,
      };
    };

    // TODO: needs better typing to support grouped options
    const value = Array.isArray(simpleValue) ? simpleValue.map(valueToOption) : valueToOption(simpleValue);
    return <Component value={value} getOptionValue={getOptionValue} onChange={handleChange} {...(restProps as P)} options={options} />;
  };
