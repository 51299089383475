import React, { MouseEventHandler } from 'react';
import { Button } from '@clds/button-old';
import { Cross } from '@clds/icon';
import { useCldFilters } from '../context/CldFilters.hooks';
import { CldFiltersActions, CldFiltersContextState, CldFiltersValues } from '../types';
import { useCloudinaryTracker } from '../../components/CloudinaryTrackerProvider/hooks';

export interface ClearAllButtonProps {
  onClick: (context: CldFiltersContextState<CldFiltersValues> & CldFiltersActions, event: React.MouseEvent<Element, MouseEvent>) => void;
  children: React.ReactNode | string;
}

export const ClearAllButton = ({ onClick, children, ...buttonProps }: ClearAllButtonProps) => {
  const context = useCldFilters();
  const cloudinaryTracker = useCloudinaryTracker();

  const handleClick: MouseEventHandler = (e) => {
    onClick(context, e);
    void cloudinaryTracker({
      eventName: 'click_clear_all_filters',
    });
  };

  return (
    <Button onClick={handleClick} tone="plain" size="sm" leftSlot={<Cross size="sm" />} isDisabled={!context.isPopulated} {...buttonProps}>
      {children}
    </Button>
  );
};
