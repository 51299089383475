import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../../../../i18n';
import { RangeFilter, useCldFilters } from '../../../../cld-filters';
import { FilterComponentBasePropsType, SearchFilterValuesType, SearchFiltersNames } from '../../types';
import { RangeType, UnitType } from '../../../../cld-filters/components/RangeFilter/RangeFilter.types';
import { SizeFilterUnits } from './SizeFilter.types';
import { SEARCH_FILE_SIZE } from './SizeFilter.constants';
import { INITIAL_VALUES } from '../../SearchFilters.constants';

const formatSize = <SizeFilterUnits,>(size: string | undefined, unit: SizeFilterUnits) =>
  // @ts-expect-error TODO: fix this (types suggest it will always be false?)
  `${size}${SEARCH_FILE_SIZE.UNITS.find((u) => u.value === unit)?.label}`;

export const SizeFilter = ({ onFilterInteract, onClear }: FilterComponentBasePropsType) => {
  const { formatMessage } = useIntl();
  const [unitValue, setUnitValue] = useState(SizeFilterUnits.mb);
  const { values, setFilterValue } = useCldFilters<SearchFilterValuesType>();
  const rangeValue = values[SearchFiltersNames.Size];

  const handleRangeChange = useCallback(
    <SizeFilterUnits,>(rangeValue: RangeType<SizeFilterUnits>) => {
      const {
        range: { min, max },
      } = rangeValue;

      setFilterValue(SearchFiltersNames.Size, { ...rangeValue, unit: unitValue });

      if (!min && !max) {
        setFilterValue(SearchFiltersNames.Size, INITIAL_VALUES[SearchFiltersNames.Size]);
      }
      onFilterInteract(SearchFiltersNames.Size, rangeValue);
    },
    [setFilterValue, onFilterInteract, unitValue]
  );

  const handleUnitValueChange = (unitValue: UnitType<SizeFilterUnits>) => {
    setUnitValue(unitValue.value);

    const {
      range: { min, max },
    } = rangeValue;
    if (min || max) {
      setFilterValue(SearchFiltersNames.Size, { ...rangeValue, unit: unitValue.value });
    }
    onFilterInteract(SearchFiltersNames.Size, rangeValue);
  };

  const handleClear = () => {
    onClear(SearchFiltersNames.Size);
  };

  return (
    <RangeFilter<SizeFilterUnits>
      onClear={handleClear}
      rangeValue={rangeValue}
      unitValue={unitValue}
      initialValues={INITIAL_VALUES[SearchFiltersNames.Size]}
      setRange={handleRangeChange}
      onUnitChange={handleUnitValueChange}
      units={SEARCH_FILE_SIZE.UNITS}
      numberFieldMin={SEARCH_FILE_SIZE.MIN}
      numberFieldStep={SEARCH_FILE_SIZE.STEP}
      filterLabelPrefix={formatMessage(messages.sizeFilterLabel)}
      valueLabelFormatter={formatSize}
      dataTest="size-filter"
    />
  );
};
