/* istanbul ignore file */

export const NOTCH_SIZE = 20;

export enum Direction {
  LEFT = 'left',
  RIGHT = 'right',
}

export const TRANSITION_TIME = 300;
