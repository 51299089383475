/** CLD QUERY BUILDERS */

import { groupBy, isEmpty } from 'lodash';
import { CldFieldsNames, METADATA_PREFIX, METADATA_PROPERTY_NAME } from '../../constants';
import { combineByOr, groupQueriesByAND, rangeQuery, keywordQuery, multiSelectQuery, customQuery } from './standard';
import { DimensionValues, DimensionsQueryData, OPERATOR_FILTER_OPERATORS_KEYS } from './types';
import { RangeType } from '../../components/RangeFilter/RangeFilter.types';
import { MultiSelectOption, SmdMultipleType } from '../../../components/SearchFilters/Filters/SmdMultiple/SmdMultiple.types';
import { OperatorFilterValue } from '../../components/OperatorFilter/OperatorFilter.types';

export const assetName = (assetName: string, isFolderDecoupling: boolean) => {
  if (!assetName) {
    return '';
  }

  const field = isFolderDecoupling ? CldFieldsNames.DisplayName : CldFieldsNames.FileName;
  return keywordQuery(field, `${assetName}*`, ':');
};

export const formats = (formats: string[]) => {
  return multiSelectQuery(CldFieldsNames.Format, formats, ':');
};

export const tags = (tags: string[]) => {
  return multiSelectQuery(CldFieldsNames.Tags, tags, ':');
};

export const smdMultiple = (valuesObject: SmdMultipleType) => {
  const fieldIds = Object.keys(valuesObject);
  const queries = fieldIds?.map((fieldId) => {
    const values = valuesObject[fieldId]?.values;
    if (!values || values.length < 1) {
      return '';
    }
    return multiSelectQuery(
      `${METADATA_PREFIX}${fieldId}`,
      values.map((v: MultiSelectOption) => v.value),
      '='
    );
  });
  const queriesToSearch = queries?.filter((filter) => !isEmpty(filter));
  return queriesToSearch ? groupQueriesByAND(queriesToSearch) : '';
};

export const operator = (allSmdTextValues: Record<string, OperatorFilterValue>) => {
  const smdExternalIds = Object.keys(allSmdTextValues);

  const getQueryByOperator = (externalId: string, operatorValue: OperatorFilterValue): string => {
    switch (operatorValue.operator) {
      case OPERATOR_FILTER_OPERATORS_KEYS.CONTAINS:
        return operatorValue.value ? keywordQuery(`${METADATA_PREFIX}${externalId}`, `${operatorValue.value}*`, ':') : '';

      case OPERATOR_FILTER_OPERATORS_KEYS.EQUALS:
      case OPERATOR_FILTER_OPERATORS_KEYS.LESS:
      case OPERATOR_FILTER_OPERATORS_KEYS.LESS_EQUAL:
      case OPERATOR_FILTER_OPERATORS_KEYS.MORE:
      case OPERATOR_FILTER_OPERATORS_KEYS.MORE_EQUAL:
        return operatorValue.value ? keywordQuery(`${METADATA_PREFIX}${externalId}`, `${operatorValue.value}`, operatorValue.operator) : '';

      case OPERATOR_FILTER_OPERATORS_KEYS.ANY:
        return keywordQuery(METADATA_PROPERTY_NAME, externalId, '=');

      case OPERATOR_FILTER_OPERATORS_KEYS.EMPTY:
        return keywordQuery(`NOT ${METADATA_PROPERTY_NAME}`, externalId, '=');
      default:
        return '';
    }
  };

  return groupQueriesByAND(smdExternalIds.map((externalId) => getQueryByOperator(externalId, allSmdTextValues[externalId])));
};

export const dimensions = (dimensions: DimensionsQueryData[]) => {
  if (!dimensions.length) return '';

  const dimensionsGroupsByResourceType = groupBy(dimensions, DimensionValues.ResourceTYpe);
  const groupedQueries = Object.entries(dimensionsGroupsByResourceType).map(([resourceType, dimensions]) => {
    const resourceQuery = keywordQuery(CldFieldsNames.ResourceType, resourceType, ':');

    const dimensionsQueries = dimensions.map((dimension) => customQuery(CldFieldsNames.Pixels, dimension.queryValue));
    const pixelsQuery = combineByOr(dimensionsQueries);
    return groupQueriesByAND([resourceQuery, pixelsQuery]);
  });
  return combineByOr(groupedQueries);
};

export const assetTypes = (types: string[]) => {
  if (types.length === 0) return '';
  return multiSelectQuery(CldFieldsNames.ResourceType, types, ':');
};

export const dateCreated = (time: string) => {
  return keywordQuery(CldFieldsNames.CreatedAt, time, '>');
};

export const size = <T>(size: RangeType<T>) => {
  const {
    range: { min, max },
    unit,
  } = size;

  if (max && min) {
    return rangeQuery(CldFieldsNames.Bytes, `${min}${unit}`, `${max}${unit}`);
  }
  if (max) {
    return keywordQuery(CldFieldsNames.Bytes, `${max}${unit}`, '<=');
  }
  if (min) {
    return keywordQuery(CldFieldsNames.Bytes, `${min}${unit}`, '>=');
  }
  return '';
};
