export const TIME_OPTIONS = {
  ANY: 'any',
  TODAY: 'today',
  LAST7: 'last7',
  LAST30: 'last30',
  LAST90: 'last90',
  LAST180: 'last180',
};

export const TIME_OPTIONS_API_MAP = Object.freeze({
  [TIME_OPTIONS.TODAY]: '1d',
  [TIME_OPTIONS.LAST7]: '7d',
  [TIME_OPTIONS.LAST30]: '30d',
  [TIME_OPTIONS.LAST90]: '90d',
  [TIME_OPTIONS.LAST180]: '180d',
});
