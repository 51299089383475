import React, { ChangeEvent, ComponentProps, FC, FocusEvent, KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import formatDate from 'date-fns/format';
import { trim } from 'lodash';
import { usePrevious } from 'react-use';
import styled from 'styled-components';
import { Props as DayzedProps, useDayzed } from 'dayzed';
import getStartOfDay from 'date-fns/startOfDay';
import { CalendarEmpty, Calendar as CalendarIcon } from '@clds/icon';
import TextField from '@clds/text-field-old';
import Popover from '@clds/popover';
import createFakeEvent from '@clds/create-fake-event';
import { LanguageProvider } from '@cld/intl';
import Calendar from './Calendar';
import { loadMessages } from './i18n';
import { guessDate, isDateInRange } from './utils';
import { GetDateProps, GetForwardProps } from './types';

// TODO: need to support mobile device - npm - react-device-detect ?

export enum ExitEventSources {
  Calendar = 'calendar',
  Input = 'input',
}

export interface DatePickerProps extends Omit<ComponentProps<typeof TextField>, 'type'>, Partial<Omit<DayzedProps, 'children'>> {
  /** date format pattern for the value displayed - for more info please refer to https://date-fns.org/v2.14.0/docs/format */
  format?: string;
  /** date format pattern that will be used for the value exposed by the component (in case a different one than 'format' is needed)*/
  rawFormat?: string;
  /** show calender popover */
  open?: boolean;
  /** Use as an alternative for blur but works for both the input and the popover */
  onExit?(selectedDate: string, exitSource: ExitEventSources): void;
}

export const CalendarButton = styled(CalendarIcon).attrs(() => ({ tabIndex: 0 }))`
  cursor: pointer;
  ${({ isDisabled }: { isDisabled?: boolean }) => isDisabled && 'opacity: 0.5; cursor: default; pointer-events: none;'}
`;

export const CalendarEmptyButton = styled(CalendarEmpty).attrs(() => ({ tabIndex: 0 }))`
  cursor: pointer;
  ${({ isDisabled }: { isDisabled?: boolean }) => isDisabled && 'opacity: 0.5; cursor: default; pointer-events: none;'}
`;

const HIDE_CALENDAR_KEYS = ['Escape', 'Tab'];

const PopoverProps = {
  paperProps: { isPadded: true },
  align: { offset: [0, 5] },
};

const initDateFromValue = (value?: string, formats?: string[]): Date | undefined => (value ? guessDate(trim(value), formats) : undefined);

const DatePicker: FC<React.PropsWithChildren<DatePickerProps>> = ({
  placeholder = 'MM/DD/YYYY',
  value,
  format = 'MMM dd yyyy',
  rawFormat = '',
  open,
  onChange,
  onBlur,
  onExit,
  minDate,
  maxDate,
  showOutsideDays = true,
  inputProps,
  inputRef,
  defaultValue,
  name,
  id,
  isDisabled,
  ...textfieldProps
}: DatePickerProps) => {
  const [initialDate] = useState(() => initDateFromValue(value || defaultValue, [format, rawFormat]));
  const [popoverVisible, setPopoverVisible] = useState(open);
  const prevPopoverVisible = usePrevious(popoverVisible);
  const [selected, setSelected] = useState<Date | undefined>(initialDate);
  const [textfieldValue, setTextfieldValue] = useState<string>(() => (initialDate ? formatDate(initialDate, format) : ''));
  const [calendarOffset, setCalendarOffset] = useState<number>(0);
  const calendarRef = useRef<HTMLDivElement>(null);
  const rawInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (prevPopoverVisible && !popoverVisible) {
      onExit?.(textfieldValue, ExitEventSources.Calendar);
    }
  }, [onExit, popoverVisible, prevPopoverVisible, textfieldValue]);

  const raiseChangeEvent = useCallback(
    (value: string) => {
      if (rawInputRef.current && rawInputRef.current.value !== value) {
        //must set the value on the input so formik will be able to access it
        rawInputRef.current.value = value;
      }

      const event = createFakeEvent(rawInputRef.current) as ChangeEvent<HTMLInputElement>;
      onChange?.(value, event);
    },
    [onChange]
  );

  const handleNewValue = useCallback(
    (date: Date) => {
      setSelected(getStartOfDay(date));
      setPopoverVisible(false);
      const newValue = formatDate(date, format);
      setTextfieldValue(newValue);

      const rawValue = rawFormat ? formatDate(date, rawFormat) : newValue;
      raiseChangeEvent(rawValue);
    },
    [format, rawFormat, raiseChangeEvent]
  );

  const dayzedProps = useDayzed({
    date: selected,
    offset: calendarOffset,
    selected,
    minDate,
    maxDate,
    showOutsideDays,
    onDateSelected: ({ date }) => {
      handleNewValue(date);
      setCalendarOffset(0);
    },
    onOffsetChanged: (newOffset) => {
      setCalendarOffset(newOffset);
    },
  });

  const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const element = e.currentTarget;

    if (e.key === 'Enter') {
      calculateEnteredValue(e.currentTarget);
    } else if (e.key === 'Escape') {
      e.stopPropagation();
    }

    //show calendar only when user empties the input
    const show = !HIDE_CALENDAR_KEYS.includes(e.key) && !element.value;

    if (show) {
      //empty field, ensure calendar has no selected value
      setSelected(undefined);
    }

    setPopoverVisible(show);
  };

  const handleIconOnClick = useCallback(() => {
    setPopoverVisible(!popoverVisible);
  }, [setPopoverVisible, popoverVisible]);

  const handleIconOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setPopoverVisible(!popoverVisible);
      }
    },
    [popoverVisible]
  );

  const handleAfterPopupVisibleChange = useCallback(() => {
    if (popoverVisible) {
      calendarRef?.current?.focus();
    }
  }, [popoverVisible]);

  const calculateEnteredValue = useCallback(
    (input: HTMLInputElement) => {
      setCalendarOffset(0);

      let hasNewValue = false;
      const val = input.value;

      if (val) {
        const date = guessDate(val, [format, rawFormat]);

        if (date && isDateInRange(date, minDate, maxDate)) {
          handleNewValue(date);
          hasNewValue = true;
        }
      }

      if (!hasNewValue) {
        setSelected(undefined);
      }
    },
    [format, rawFormat, minDate, maxDate, handleNewValue]
  );

  const handleOnChange = useCallback(
    (val: string) => {
      setTextfieldValue(val);
      raiseChangeEvent(val);
    },
    [raiseChangeEvent]
  );

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      calculateEnteredValue(e.currentTarget);
      onBlur?.(createFakeEvent(rawInputRef.current) as FocusEvent<HTMLInputElement>);
      onExit?.(textfieldValue, ExitEventSources.Input);
    },
    [onBlur, calculateEnteredValue, onExit, textfieldValue]
  );

  const { getForwardProps, getBackProps, getDateProps, ...restDayzedProps } = dayzedProps;

  const calendarButtonProps = {
    isDisabled,
    onClick: handleIconOnClick,
    onKeyDown: handleIconOnKeyDown,
    size: 'md',
    color: 'contrastLow',
  } as const;

  const picker = (
    <Popover
      paperProps={PopoverProps.paperProps}
      overlay={
        <Calendar
          ref={calendarRef}
          getForwardProps={getForwardProps as GetForwardProps}
          getBackProps={getBackProps as GetForwardProps}
          getDateProps={getDateProps as GetDateProps}
          {...restDayzedProps}
          selected={selected}
          minDate={minDate}
          maxDate={maxDate}
          setPopoverVisible={setPopoverVisible}
        />
      }
      visible={popoverVisible}
      onVisibleChange={setPopoverVisible}
      afterVisibleChange={handleAfterPopupVisibleChange}
      placement="bottom"
      align={PopoverProps.align}
      trigger="click"
    >
      {/* @clds/popover has problems with passing reference to the functional components, thus the <span> wrapper */}
      <span>{!selected ? <CalendarEmptyButton {...calendarButtonProps} /> : <CalendarButton {...calendarButtonProps} />}</span>
    </Popover>
  );

  return (
    <LanguageProvider loadMessages={loadMessages}>
      <input type="text" ref={rawInputRef} style={{ display: 'none' }} name={name} id={id} />
      <TextField
        type="text"
        trailingDecoration={picker}
        placeholder={placeholder}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        inputRef={inputRef}
        inputProps={{
          ...inputProps,
          onKeyDown: handleOnKeyDown,
          value: textfieldValue,
          // Avoid rendering default date input element
          type: '',
        }}
        isDisabled={isDisabled}
        {...textfieldProps}
      />
    </LanguageProvider>
  );
};

export default DatePicker;
