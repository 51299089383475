import React, { FC, MouseEvent, ReactNode, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { File, FileDownload, Photo, Video, ZipFolder } from '@clds/icon';
import { Item, ItemLayout } from '@clds/item';
import { Button } from '@clds/button-old';
import { Asset } from '@cld/console-apps-types';
import { messages } from '../../i18n';
import { StyledItem, StyledSingleDownloadContainer, StyledThumbnailContainer, StyledTypography } from './DownloadPresetsModalStyled';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';
import { HorizontalSeparator } from '@clds/separator';
import { Thumbnail } from '../Thumbnail/Thumbnail';
import { TruncatedWithTooltip } from '../../cld-filters/components/shared/TruncatedWithTooltip';
import { DownloadType } from '../Enums/Enum';
import { useSearchTotalsSelector } from '../AssetGrid/VirtualizedGrid/VirtualizedGrid.selectors';

const ItemRightSlot = (itemType?: string) => {
  switch (itemType) {
    case 'raw':
      return <File />;
    case 'zip':
      return <ZipFolder />;
    case 'video':
      return <Video />;
    default:
      return <Photo />;
  }
};

interface OriginalDownloadSectionProps {
  assets: Asset | Array<Asset>;
  onOriginalDownload: (externalId?: string) => void;
  downloadType: DownloadType;
  preventDownload: boolean;
}

export const OriginalDownloadSection: FC<React.PropsWithChildren<OriginalDownloadSectionProps>> = ({
  assets,
  onOriginalDownload,
  downloadType,
  preventDownload,
}) => {
  const isCollection = Array.isArray(assets);
  const collectionInfo = useCollectionInfoContext();
  const { totalCount } = useSearchTotalsSelector();
  let asset;

  if (!isCollection) {
    asset = assets as Asset;
  }

  const OriginalDownloadButton = (externalId?: string) => (
    <Button
      onClick={useCallback(
        (e: MouseEvent) => {
          e.stopPropagation();
          onOriginalDownload(externalId);
        },
        [externalId]
      )}
    >
      <FileDownload />
    </Button>
  );

  const thumbnailAsset: Asset[] | Asset = Array.isArray(assets)
    ? assets.find((asset) => asset.type === 'image' || asset.type === 'video') || assets[0]
    : assets;
  const formatText = thumbnailAsset?.type === 'video' || thumbnailAsset?.type === 'image' ? { ...messages.originalAssets } : { ...messages.original };
  const assetDisplayName = thumbnailAsset?.displayName || thumbnailAsset?.filename;
  return (
    <StyledItem onClick={() => (isCollection ? onOriginalDownload() : onOriginalDownload(assets?.externalId))}>
      <Item borderRadius="sm" size="md">
        <ItemLayout rightSlot={isCollection ? OriginalDownloadButton() : OriginalDownloadButton(assets?.externalId)}>
          <StyledThumbnailContainer>
            <Thumbnail iconColorType="highContrast" asset={thumbnailAsset} />
            {!isCollection ? (
              <StyledSingleDownloadContainer>
                <TruncatedWithTooltip tooltipText={assetDisplayName} data-unit-test="truncated">
                  {assetDisplayName}
                </TruncatedWithTooltip>
                <StyledTypography size="xxs" type="highContrast">
                  {thumbnailAsset.format}
                </StyledTypography>
              </StyledSingleDownloadContainer>
            ) : (
              <StyledSingleDownloadContainer>
                <FormattedMessage {...formatText} />
                <StyledTypography size="xxs" type="highContrast">
                  {!preventDownload &&
                    (downloadType === DownloadType.All ? (
                      <FormattedMessage {...messages.collectionAssetsCount} values={{ count: collectionInfo.totalCount }} />
                    ) : (
                      <FormattedMessage {...messages.collectionAssetsCount} values={{ count: totalCount }} />
                    ))}
                </StyledTypography>
              </StyledSingleDownloadContainer>
            )}
          </StyledThumbnailContainer>
        </ItemLayout>
      </Item>
      <HorizontalSeparator />
    </StyledItem>
  );
};
