import styled, { css } from 'styled-components';
import { MouseEventHandler } from 'react';
import {
  ENTERING,
  EXITED,
  EXITING,
  TransitionStatus,
} from 'react-transition-group/Transition';
import { globalTheme } from '@clds/component-theme';
import { Button } from '@clds/button-old';
import { Direction, TRANSITION_TIME } from './shared/consts';
import { ToggleButtonWithTooltipProps } from './ToggleButtonWithTooltip';

export interface PanelProps {
  panelWidth?: number;
  dataTest?: string;
  isPanelFloatOpen?: boolean;
  state?: TransitionStatus;
  direction?: Direction;
  isContentHiddenOnClose?: boolean;
  dataTestSpecifier?: string;
}

export interface ButtonControlProps {
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  direction?: Direction;
}

const CommonStyles = css`
  position: absolute;
  top: 0;
  bottom: 0;
`;

const WIDTH_TRANSITION = `width ${TRANSITION_TIME}ms cubic-bezier(0.2, 0, 0, 1)`;
const DELAY_REACTION_ON_HOVER_IN_MS = '300ms';

/*wrapper panel*/
export const StyledCollapsiblePanel = styled.div.attrs((props: PanelProps) => ({
  'data-test': props.dataTest,
  'data-test-specifier': props.dataTestSpecifier,
}))<PanelProps>`
  position: relative;
  flex-shrink: 0;
  width: ${(props) => props.panelWidth && `${props.panelWidth}px`};
  transition: ${WIDTH_TRANSITION};
`;

const calcToCloseState = (props: PanelProps) =>
  props.state === EXITING ||
  (props.state === EXITED && !props.isPanelFloatOpen);

const getFloatingPanelWidth = (props: PanelProps) =>
  props.isPanelFloatOpen ? props.panelWidth && `${props.panelWidth}px` : '100%';
const getFloatingPanelTransitionDelay = (props: PanelProps) => {
  const toCloseState = calcToCloseState(props);

  return props.state === ENTERING
    ? `${TRANSITION_TIME}ms`
    : toCloseState
      ? '0'
      : DELAY_REACTION_ON_HOVER_IN_MS;
};

/*inner panel that contains both button and content, and perform the float on hover using width change*/
export const StyledFloatingPanel = styled.div<PanelProps>`
  ${CommonStyles};
  ${(props) => `${props.direction === Direction.LEFT ? 'left' : 'right'}: 0`};
  width: ${getFloatingPanelWidth};
  //remove width animation if we clicked to open the panel while in float open state
  transition: ${(props) => (props.state !== ENTERING ? WIDTH_TRANSITION : '')};
  transition-delay: ${getFloatingPanelTransitionDelay};
`;

/*inner div that responsible for holding the content and hiding on close*/
export const StyledContentWrapper = styled.div.attrs<PanelProps>((props) => {
  const toCloseState = calcToCloseState(props); // don't hide if floated open

  return {
    style: {
      opacity: toCloseState && props.isContentHiddenOnClose ? 0 : 1,
      transition: 'opacity 50ms linear 200ms',
      pointerEvents: toCloseState ? 'none' : 'auto',
      width: props.panelWidth && `${props.panelWidth}px`,
      [props.direction === Direction.LEFT ? 'right' : 'left']: 0,
      transitionDelay: toCloseState
        ? `${TRANSITION_TIME}ms`
        : DELAY_REACTION_ON_HOVER_IN_MS,
    },
  };
})<PanelProps>`
  ${CommonStyles}
`;

export const StyledButtonContainer = styled.div<ButtonControlProps>`
  ${CommonStyles};
  width: 14px;
  ${(props) =>
    `${props.direction === Direction.LEFT ? 'left' : 'right'}: 100%`};
`;

export const StyledToggleButton = styled(Button)<ToggleButtonWithTooltipProps>`
  position: absolute;
  top: 10px;
  transition: opacity 200ms ease-in-out;
  box-shadow: 0 0 0 1px ${globalTheme.palette.secondaryAlt};
  opacity: ${(props: ToggleButtonWithTooltipProps) => (props.show ? 1 : 0)};
  ${(props: ToggleButtonWithTooltipProps) =>
    `${props.direction === Direction.LEFT ? 'right' : 'left'}: 0`};

  min-height: auto; //fix for safari bug
  border: 0;
  &:focus,
  ${StyledContentWrapper}:focus-within & {
    opacity: 1;
  }
`;
