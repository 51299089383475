import React from 'react';
import { StyledChipLabel, StyledFilterLabelPrefix, StyledTruncated } from './FilterChipLabel.styles';
import { isObject } from 'lodash';
import { MultiSelectOptionType } from '../MultiSelectFilter/MultiSelectFilter.types';
import { TruncatedWithTooltip } from '../shared/TruncatedWithTooltip';
import { StyledBadge } from '../shared/Badge';

export interface MultiValueChipLabelProps {
  values: string[];
  options: MultiSelectOptionType[];
  filterLabelPrefix: string;
  hideLabelTooltip: boolean;
  hasValue: boolean;
}

export const MultiValueChipLabel = ({ values, options, filterLabelPrefix, hideLabelTooltip = false, hasValue }: MultiValueChipLabelProps) => {
  if (values.length === 1) {
    const selectedOption = isObject(values[0]) ? values[0] : options.find((option) => option.value === values[0]);
    const { label = '', icon } = selectedOption || {};
    return (
      <StyledChipLabel>
        <StyledFilterLabelPrefix hasValue={hasValue}>{filterLabelPrefix}:</StyledFilterLabelPrefix>
        {icon}
        <TruncatedWithTooltip
          // TODO: Verify if casting is correct
          tooltipText={label as string}
        >
          {label}
        </TruncatedWithTooltip>
      </StyledChipLabel>
    );
  }
  if (values.length > 1) {
    return (
      <StyledChipLabel data-testid="chip">
        <TruncatedWithTooltip tooltipText={filterLabelPrefix}>{`${filterLabelPrefix}: `}</TruncatedWithTooltip>
        <StyledBadge>{values.length}</StyledBadge>
      </StyledChipLabel>
    );
  }

  return hideLabelTooltip ? (
    <StyledTruncated data-testid="truncated-no-tooltip">{filterLabelPrefix}</StyledTruncated>
  ) : (
    <TruncatedWithTooltip tooltipText={filterLabelPrefix} data-unit-test="truncated">
      {filterLabelPrefix}
    </TruncatedWithTooltip>
  );
};
interface ValueChipLabelProps {
  value: string;
  filterLabelPrefix: string;
  delimiter?: string;
  icon?: string;
  hasValue: boolean;
}

export const ValueChipLabel: React.FC<ValueChipLabelProps> = (props: ValueChipLabelProps) => {
  const delimiter = props.delimiter ?? ':';
  const filterLabelPrefix = props.value ? `${props.filterLabelPrefix}${delimiter}` : props.filterLabelPrefix;
  return (
    <StyledChipLabel>
      <StyledFilterLabelPrefix hasValue={props.hasValue}>{filterLabelPrefix}</StyledFilterLabelPrefix>
      {props.icon}
      <TruncatedWithTooltip tooltipText={props.value} data-unit-test="truncated">
        {props.value}
      </TruncatedWithTooltip>
    </StyledChipLabel>
  );
};
