import { Asset, ImageAsset, VideoAsset } from '@cld/console-apps-types';

export const getDefaultCloudinaryAnalyticsParams = () => {
  const [, cloud] = window.location.pathname.split('/');
  return { cloud_name: cloud };
};

export const getAssetTrackingParams = ({ asset }: { asset: Asset & { trackingAssetPosition: number } }) => ({
  asset_name: asset.displayName || asset.filename,
  asset_position: asset.trackingAssetPosition,
  asset_resource: asset.type,
  asset_type: asset.format.toUpperCase(),
  asset_size: asset.bytes,
  asset_width: (asset as ImageAsset)?.width ?? 0,
  asset_height: (asset as ImageAsset)?.height ?? 0,
  asset_duration: (asset as VideoAsset)?.duration ?? 0,
  asset_id: asset.externalId,
});
