import React, { ComponentType, SyntheticEvent } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { Assign } from 'utility-types';
import { CommonFieldProps } from '@clds/common-definitions';
import { supportFieldLineLayout } from '@clds/toolbar-layout';
import { useAdaptedProps } from './hooks/useAdaptedProps';
import { useSelectProps } from './hooks/useSelectProps';
import { ComboboxProps } from './types';
import { withSimpleValue } from './withSimpleValue';

// This casting is due to old React types from react-select
const StyledSelect = styled(Select as ComponentType)`
  min-width: 200px;

  ${supportFieldLineLayout('.cb__control')};
`;

/**
 * A component to pick one value from the list with no option to create new value.
 * To allow user create new values, use ComboboxCreatable
 */
export const Combobox = <T, IsMulti extends boolean>(props: ComboboxProps<T, IsMulti>) => {
  const finalProps = useSelectProps(props);

  return <StyledSelect {...finalProps} />;
};

export type ComboboxFieldProps<T, IsMulti extends boolean> = Assign<ComboboxProps<T, IsMulti>, CommonFieldProps<T, SyntheticEvent>>;

/**
 * Combobox with props adjusted to CommonFieldProps
 */
export const ComboboxField = <T, IsMulti extends boolean>(props: ComboboxFieldProps<T, IsMulti>) => {
  const comboboxProps = useAdaptedProps(props);
  return <Combobox {...comboboxProps} />;
};

export const SimpleValueComboboxField = withSimpleValue(ComboboxField);
