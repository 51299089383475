import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Button } from '@clds/button-old';
import { Reload } from '@clds/icon';
import { ModalCloseIcon, ModalNext } from '@clds/modal-old';
import { messages } from '../../i18n';
import stopwatch from './stopwatch.svg';
import { Graphic, StyledModalCard, StyledModalContent, StyledModalFooter, StyledModalHeader } from './RefreshModal.styles';

export interface RefreshModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const RefreshModal = ({ isOpen, onClose }: RefreshModalProps) => {
  return (
    <ModalNext isOpen={isOpen} onClose={onClose} closeOnEsc>
      <StyledModalCard>
        <StyledModalHeader>
          <FormattedMessage {...messages.refreshModalHeader} />
          <ModalCloseIcon />
        </StyledModalHeader>
        <StyledModalContent>
          <Graphic src={stopwatch} alt="stopwatch" />
          <FormattedMessage {...messages.refreshModalMessage} />
        </StyledModalContent>
        <StyledModalFooter>
          <Button onClick={() => window.location.reload()} leftSlot={<Reload />} data-test-specifier="reload-button">
            <FormattedMessage {...messages.refreshModalButton} />
          </Button>
        </StyledModalFooter>
      </StyledModalCard>
    </ModalNext>
  );
};

export default RefreshModal;
