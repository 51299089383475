import { MultiSelectFilter, MultiSelectOptionType, useCldFilters } from '../../../../cld-filters';
import React, { useCallback } from 'react';
import { FilterComponentBasePropsType, SearchFilterValuesType, SearchFiltersNames } from '../../types';
import { useIntl } from 'react-intl';
import { messages } from '../../../../i18n';
import { formatsOptions } from './Formats.helpers';

export const Formats = ({ onFilterInteract, onClear }: FilterComponentBasePropsType) => {
  const { values, setFilterValue } = useCldFilters<SearchFilterValuesType>();
  const { formatMessage } = useIntl();
  const formats = values[SearchFiltersNames.Formats];

  const handleChange = useCallback(
    (formats: MultiSelectOptionType[]) => {
      setFilterValue(SearchFiltersNames.Formats, formats);
      onFilterInteract(SearchFiltersNames.Formats, formats);
    },
    [setFilterValue, onFilterInteract]
  );

  const handleClear = () => {
    onClear(SearchFiltersNames.Formats);
  };

  return (
    <MultiSelectFilter
      values={formats}
      onClear={handleClear}
      // @ts-expect-error TODO: fix this
      onChange={handleChange}
      options={formatsOptions}
      filterLabelPrefix={formatMessage(messages.formats)}
      dataTest="search-formats"
      withFilterInput
    />
  );
};
