import { useEffect, useState } from 'react';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';
import { fetchAssets, getNumColumns } from './VirtualizedGrid.helpers';
import { SearchDataType } from './VirtualizedGrid.types';
import { isUndefined } from 'lodash';
import { useSearchState } from '../../../context/SearchContext/SearchContext.hooks';

const queryKey = 'assets';

export const useAssets = (select?: (data: InfiniteData<SearchDataType>) => InfiniteData<SearchDataType>) => {
  const searchParams = useSearchState();

  const result = useInfiniteQuery({
    queryKey: [{ queryKey, searchParams }],
    queryFn: fetchAssets,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    refetchOnWindowFocus: false,
    select,
    enabled: !isUndefined(searchParams.filtersLuceneQueryString),
    initialPageParam: null,
  });
  const allFetchedAssets = result.data ? result.data.pages.flatMap((d) => d.assets) : [];

  return { allFetchedAssets, ...result };
};

export const useGridDimensions = (
  maxColumns: number,
  containerCurrentWidth: number,
  itemsCount: number,
  itemWidth: number,
  itemPadding: number
): { numColumns: number; numRows: number } => {
  const [numColumns, setNumColumns] = useState(maxColumns);

  useEffect(() => {
    const numColumns = getNumColumns(itemsCount, maxColumns, itemWidth, itemPadding, containerCurrentWidth);
    setNumColumns(numColumns);
  }, [containerCurrentWidth, maxColumns, itemWidth, itemPadding, itemsCount]);

  const numRows = Math.ceil(itemsCount / numColumns);

  return { numColumns, numRows };
};
