import { useMemo } from 'react';
import { FocusEventHandler, KeyboardEventHandler, mergeStyles } from 'react-select';
import { ComboboxProps } from '../types';
import { useSelectStyles } from './useSelectStyles';
import { useSelectComponents } from './useSelectComponents';

/**
 * React-select manages its own focus state. We need just to override box-shadow when inner input is focused-visible.
 * React-select relies on internal isFocused state, it is hard to override there.
 * Instead we add inline style based on information from events
 * @param input the input element that receives information about focus-visible
 */
const updateFocusVisibleBoxShadow = (input: HTMLElement) => {
  const focusAdded = input.hasAttribute('data-focus-visible-added');
  const style = input.parentElement?.parentElement?.style;

  if (style) {
    if (focusAdded) {
      style.boxShadow = '';
    } else {
      style.boxShadow = 'none';
    }
  }
};

/**
 * A hook that returns props to be passed to react-select in order to adjust it to Design System definition
 */
export const useSelectProps = <T, IsMulti extends boolean>({
  components: userProvidedComponents,
  size = 'sm',
  validationState,
  onFocus: providedOnFocus,
  onKeyDown: providedOnKeyDown,
  isClearable = true,
  styles: providedStyles,
  id,
  className = '',
  ...rest
}: ComboboxProps<T, IsMulti>) => {
  const baseStyles = useSelectStyles(size, validationState);
  const styles = useMemo(() => (providedStyles ? mergeStyles(baseStyles, providedStyles) : baseStyles), [baseStyles, providedStyles]);

  const components = useSelectComponents(userProvidedComponents);

  const onFocus: FocusEventHandler = (event) => {
    updateFocusVisibleBoxShadow(event.target);
    providedOnFocus?.(event);
  };

  const onKeyDown: KeyboardEventHandler = (event) => {
    updateFocusVisibleBoxShadow(event.target as HTMLElement);
    providedOnKeyDown?.(event);
  };

  return {
    onFocus,
    onKeyDown,
    size,
    classNamePrefix: 'cb',
    isClearable,
    menuPlacement: 'auto',
    ...rest,
    styles,
    inputId: id,
    components,
    className: `cld-combobox ${className}`, // we are adding additional class so we can search for it using this class
  };
};
