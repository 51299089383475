import React, { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Direction } from '@cld/collapsible-panel';
import { HorizontalSeparator } from '@clds/separator';
import { Asset } from '@cld/console-apps-types';
import { Typography } from '@clds/typography';
import { messages } from '../../i18n';
import {
  StyledTypographyHeader,
  StyledDetailsWrapper,
  StyledDetailsRowWrapper,
  StyledSideBarContainer,
  StyledCollapsiblePanel,
} from './previewPane.styles';
import { detailsLogic, propertyLogic } from './PreviewPane.helpers';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';
import { PropertyEnum } from '../../types';

export interface PreviewPaneProps {
  asset: Asset;
}

interface DetailsRowProps {
  titleKey: string;
  titleMassage?: string;
  assetPropertyValue: string | ReactNode;
}

const AssetPropertyRow = ({ titleKey, assetPropertyValue, titleMassage }: DetailsRowProps) => {
  const { formatMessage } = useIntl();
  const message = messages[titleKey as keyof typeof messages];
  const assetPropertyTitle = message ? formatMessage(message) : '';
  return (
    <StyledDetailsRowWrapper>
      <Typography size="xs" type="highContrast">
        {titleMassage ?? assetPropertyTitle}
      </Typography>
      <Typography size="sm" type="highContrast">
        {assetPropertyValue}
      </Typography>
    </StyledDetailsRowWrapper>
  );
};

export const PreviewPane: FC<React.PropsWithChildren<PreviewPaneProps>> = ({ asset }) => {
  const { allowedSearchFields } = useCollectionInfoContext();
  return (
    <StyledCollapsiblePanel direction={Direction.RIGHT} notchSize={0}>
      <StyledSideBarContainer>
        <HorizontalSeparator />
        <StyledTypographyHeader size="lg" type="highContrast" as="h4">
          <FormattedMessage {...messages.summary} />
        </StyledTypographyHeader>
        <HorizontalSeparator />
        <StyledDetailsWrapper>
          {detailsLogic
            .filter(({ value }) => !!value(asset))
            .map(({ titleKey, value }, i) => {
              return <AssetPropertyRow key={i} titleKey={titleKey} assetPropertyValue={value(asset)} />;
            })}
          {allowedSearchFields
            ? allowedSearchFields.map(({ property, externalId, label, type }) => {
                const { titleKey, value } = propertyLogic[property as PropertyEnum];
                return (
                  <AssetPropertyRow
                    key={externalId ?? property}
                    titleKey={titleKey}
                    titleMassage={label}
                    assetPropertyValue={value(asset, type as string, externalId as string)}
                  />
                );
              })
            : null}
        </StyledDetailsWrapper>
      </StyledSideBarContainer>
    </StyledCollapsiblePanel>
  );
};
