import { MultiSelectGroupOptionType, MultiSelectOptionType } from './MultiSelectFilter.types';

const isMultiSelectGroupOptionType = (option: MultiSelectOptionType | MultiSelectGroupOptionType): option is MultiSelectGroupOptionType => {
  return (option as MultiSelectGroupOptionType).options !== undefined;
};

// This turn options array to map, whether it's from type MultiSelectOptionType[] or MultiSelectGroupOptionType[]
export const getOptionsMap = (options: MultiSelectOptionType[] | MultiSelectGroupOptionType[]) => {
  const map = new Map();

  const mapOptions = (options: MultiSelectOptionType[] | MultiSelectGroupOptionType[]) => {
    options.forEach((option) => {
      if (isMultiSelectGroupOptionType(option)) {
        mapOptions(option.options);
      } else {
        map.set(option.value, option);
      }
    });
  };

  mapOptions(options);
  return map;
};
