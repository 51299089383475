import { CldFiltersActions, CldFiltersContextState, CldFiltersContextType, CldFiltersValues } from '../types';
import { getCldFiltersActions } from './CldFilters.actions';
import { CldFiltersContext } from './CldFiltersProvider';
import { useContext } from 'react';

export const useCldFiltersContext = <Values extends CldFiltersValues = CldFiltersValues>() => {
  const cldFilters = useContext<CldFiltersContextType<Values>>(CldFiltersContext)?.state as CldFiltersContextState<Values>;

  if (!cldFilters) {
    console.warn(`CldFilters context is undefined, please verify you are calling useCldFilters() as child of a <CldFiltersProvider> component.`);
  }

  return cldFilters;
};

export const useCldFiltersDispatch = () => {
  return useContext(CldFiltersContext)?.dispatch;
};

export const useCldFilters = <Values extends CldFiltersValues = CldFiltersValues>(): CldFiltersContextState<Values> & CldFiltersActions => {
  const dispatch = useCldFiltersDispatch();
  const context = useCldFiltersContext<Values>();
  const actions = getCldFiltersActions(dispatch);

  return { ...context, ...actions };
};
