import { ResourceTypesType } from './AssetTypes.types';

export const RESOURCE_TYPES: ResourceTypesType = Object.freeze({
  image: {
    label: 'image',
    value: 'image',
  },
  video: {
    label: 'video',
    value: 'video',
  },
  raw: {
    label: 'others',
    value: 'raw',
  },
});
