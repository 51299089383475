import React, { useState } from 'react';
import { ArrowDropDown, Close } from '@clds/icon';
import { messages } from '../../../i18n/messages';
import Popover from '@clds/popover';
import { Tooltip } from '@clds/tooltip';
import { StyledCloseDiv, StyledFilterChip } from './Filter.styles';
import { FormattedMessage } from 'react-intl';

interface FilterProps {
  overlay: React.ReactNode;
  label: React.ReactNode;
  onClear: () => void;
  onOpen?: () => void;
  hasValue: boolean;
  dataTest: string;
}

export const Filter: React.FC<FilterProps> = (props: FilterProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setIsPopoverOpen(visible);
    props.onOpen?.();
  };

  const getFilterChipRightSlot = (): React.ReactNode => {
    return props.hasValue ? (
      <Tooltip trigger="hover" title={<FormattedMessage {...messages['clearFilter']} />}>
        <StyledCloseDiv
          onClick={(e) => {
            if (!isPopoverOpen) {
              // Stop the event from opening the menu
              e.stopPropagation();
            }
            props.onClear();
          }}
        >
          <Close color="primary" size="sm" data-unit-test="filter-close-icon" />
        </StyledCloseDiv>
      </Tooltip>
    ) : (
      <ArrowDropDown size="xs" data-unit-test="filter-dropdown-icon" />
    );
  };

  return (
    <Popover
      popupVisible={isPopoverOpen}
      trigger="click"
      placement="bottomLeft"
      paperProps={{ isPadded: false }}
      // We need this specific zIndex to allow the name tooltip to appear for the Truncation component
      zIndex={99999}
      overlay={props.overlay}
      data-test-specifier={props.dataTest}
      onVisibleChange={handleVisibleChange}
    >
      <div>
        <StyledFilterChip
          label={props.label}
          isHoverable
          rightSlot={getFilterChipRightSlot()}
          tone="subtle"
          variant={props.hasValue ? 'primary' : 'secondary'}
          data-unit-test="filter-chip"
          data-test-specifier={`filter-${props.dataTest}`}
        />
      </div>
    </Popover>
  );
};
