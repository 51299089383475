import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
// eslint-disable-next-line
// @ts-ignore
import { Menu } from '@clds/menu-old';
import { CollectionDescription } from './CollectionDescription';
import Typography from '@clds/typography';

export const StyledSpacer = styled.div`
  flex-grow: 1;
`;

export const StyledCollectionDescription = styled(CollectionDescription)`
  padding: ${globalTheme.spacing.md} 20% ${globalTheme.spacing.md} 20%;
  display: flex;
  justify-content: center;
`;

export const StyledGridAndHeaderContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const StyledProgressWrapper = styled.div`
  height: 80px;
  padding: ${globalTheme.spacing.xs};
  padding-bottom: ${globalTheme.spacing.lg};
`;

export const StyledAssetsGridContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledSortContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledMenu = styled(Menu)`
  min-width: 180px;
`;

export const SearchSortContainer = styled.div`
  position: sticky;
  /* The reason it's not 0 it's because browser stills shows a small window between header and this container */
  top: -1px;
  z-index: 1;
  background-color: ${globalTheme.palette.surface};
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${globalTheme.palette.secondary};
`;

export const StyledTypography = styled(Typography)`
  padding: 0 ${globalTheme.spacing.md};
  height: ${globalTheme.spacing.lg};
`;
