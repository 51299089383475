import { isEmpty } from 'lodash';
import { SMD_DATE_OPERATOR_KEYS } from './SmdDate.constants';
import formatDate from 'date-fns/format';
import { SmdOperatorType } from '../../../../cld-filters';

export const getUpdatedSmdFieldValue = (operator: string, value: string | undefined) => {
  const isAnyOrEmpty = operator == SMD_DATE_OPERATOR_KEYS.EMPTY || operator == SMD_DATE_OPERATOR_KEYS.ANY;
  if (isAnyOrEmpty) {
    return { operator };
  } else if (value) {
    return { operator, value };
  } else {
    return null;
  }
};

export const getUpdatedSmdDate = ({
  smdDateValue,
  externalIdToUpdate,
  operator,
  value,
}: {
  smdDateValue: SmdOperatorType | null;
  externalIdToUpdate: string;
  operator: string;
  value: string | undefined;
}) => {
  const smdFieldValue = getUpdatedSmdFieldValue(operator, value);

  if (smdFieldValue) {
    return { ...(smdDateValue || {}), [externalIdToUpdate]: smdFieldValue };
  } else {
    if (!smdDateValue) return null;
    const { [externalIdToUpdate]: removedSmdField, ...updatedSmdTextValue } = smdDateValue;
    return isEmpty(updatedSmdTextValue) ? null : updatedSmdTextValue;
  }
};

export const formatStringDateToLabel = (date: string) => date && formatDate(new Date(date), 'MMM dd, yyyy');
