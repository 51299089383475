import React from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../../../i18n';
import { Filter } from '../Filter/Filter';
import { ValueChipLabel } from '../FilterChipLabel/FilterChipLabel';
import { StyledNumberField, StyledPaper, StyledSingleValueCombobox, StyledTextDivider } from './RangeFilter.styles';
import { MinMaxType, RangeType, UnitType } from './RangeFilter.types';

const onKeyDown = (e: React.KeyboardEvent) => {
  // Prevent user from typing non integer chars
  if (['.', '-', '+', 'e'].includes(e.key)) {
    e.preventDefault();
  }
};

export interface RangeFilterProps<T> {
  onClear?: () => void;
  rangeValue: RangeType<T>;
  initialValues: RangeType<T>;
  unitValue: T;
  onUnitChange: (unit: UnitType<T>) => void;
  setRange: <T>(minMax: RangeType<T>) => void;
  units: UnitType<T>[];
  filterLabelPrefix: string;
  valueLabelFormatter: <T>(size: string | undefined, unit: T, inRange?: boolean) => string;
  dataTest: string;
  numberFieldMin?: number;
  numberFieldStep?: number;
}

export const RangeFilter = <T,>({
  onClear,
  rangeValue,
  initialValues,
  unitValue,
  onUnitChange,
  setRange,
  units,
  filterLabelPrefix,
  valueLabelFormatter,
  dataTest,
  numberFieldMin = 0,
  numberFieldStep = 1,
}: RangeFilterProps<T>) => {
  const { unit } = rangeValue;
  const { min, max }: MinMaxType = rangeValue.range;
  const { formatMessage } = useIntl();
  const hasValue = !!min || !!max;

  const onClearHandler = () => {
    onClear?.();
    setRange(initialValues ? initialValues : { range: { min: '', max: '' } });
  };

  const onMinChange = (value: string) => {
    setRange({ ...rangeValue, range: { min: value, max } });
  };

  const onMaxChange = (value: string) => {
    setRange({ ...rangeValue, range: { min, max: value } });
  };

  const Overlay = (
    <StyledPaper isPadded depth="none">
      <StyledNumberField
        value={min}
        onChange={onMinChange}
        min={numberFieldMin}
        step={numberFieldStep}
        inputProps={{
          onKeyDown,
        }}
        placeholder={formatMessage(messages.rangeFilterMinPlaceholder)}
        data-test-specifier="min-input"
      />
      <StyledTextDivider size="xs" type="regular">
        -
      </StyledTextDivider>
      <StyledNumberField
        value={max}
        onChange={onMaxChange}
        min={numberFieldMin}
        step={numberFieldStep}
        inputProps={{
          onKeyDown,
        }}
        placeholder={formatMessage(messages.rangeFilterMaxPlaceholder)}
        data-test-specifier="max-input"
      />
      <StyledSingleValueCombobox
        // @ts-expect-error TODO: Fix this and the casting below
        options={units}
        value={unitValue as unknown as string}
        onChange={onUnitChange}
        isClearable={false}
        isCreatable={false}
      />
    </StyledPaper>
  );

  const getValueLabel = () => {
    if (min && !max) {
      return `${formatMessage(messages.rangeFilterMinimumPrefix)} ${valueLabelFormatter(min, unit)}`;
    }
    if (!min && max) {
      return `${formatMessage(messages.rangeFilterMaximumPrefix)} ${valueLabelFormatter(max, unit)}`;
    }
    if (min && max) {
      return `${valueLabelFormatter(min, unit, true)} - ${valueLabelFormatter(max, unit, true)}`;
    }
    return '';
  };

  const valueLabel = getValueLabel();
  const labelComp = <ValueChipLabel value={valueLabel} filterLabelPrefix={filterLabelPrefix} hasValue={hasValue} />;
  return <Filter label={labelComp} hasValue={hasValue} onClear={onClearHandler} overlay={Overlay} dataTest={dataTest} />;
};
