import { media } from '@clds/style-utils';
import TextField from '@clds/text-field-old';
import styled from 'styled-components';

export const StyledSearchInputWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  min-width: 120px;
  max-width: 567px;
  @media ${media.phone({ maxOnly: true })} {
    flex: 1;
  }
`;

export const StyledIconWrapper = styled.div`
  cursor: pointer;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;
