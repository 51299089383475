import React, { FC, ReactNode } from 'react';
import { StylableComponentProps } from '@clds/common-definitions';
import { BlenderProps } from '@clds/blender';
import { FoundationContextProvider, SurfaceContextProvider, Tone, Variant } from '@clds/design-system-foundations';
import { Check, Cross, Error, Info, Warning } from '@clds/icon';
import { asStyledComponent } from '@clds/component-enhancer';
import { BannerContent, BannerRoot, CloseButton, Spacer } from './Banner.styles';

interface BannerBlenderProps {
  variant: Variant;
  tone: Tone;
  'data-test-specifier'?: string;
}

export interface BannerProps extends StylableComponentProps, Partial<BannerBlenderProps> {
  /** Custom Icon (optional) **/
  icon?: ReactNode;
  /** Primary actions component **/
  action?: ReactNode;
  /** Secondary actions component **/
  secondaryAction?: ReactNode;
  children: ReactNode;
  onClose?: () => void;
  isClosable?: boolean;
  'data-test-specifier'?: string;
}

const iconByVariant: Record<Variant, ReactNode> = {
  primary: <Info />,
  secondary: <Info />,
  error: <Error />,
  warning: <Warning />,
  success: <Check />,
  overlay: <Info />,
};

const toBlenderProps = ({ variant, tone }: BannerBlenderProps): BlenderProps => ({
  variant,
  mode: tone === 'solid' ? 'solidContrast' : 'surfaceSolid',
  intensity: tone === 'solid' ? 'md' : 'sm',
});

export const Banner = asStyledComponent<FC<BannerProps>>(
  ({
    icon,
    variant = 'primary',
    tone = 'solid',
    action,
    secondaryAction,
    onClose,
    isClosable = true,
    className,
    'data-test-specifier': dataTestSpecifier,
    children,
  }) => {
    return (
      <SurfaceContextProvider variant={variant} tone={tone}>
        <FoundationContextProvider size="sm">
          <BannerRoot className={className} {...toBlenderProps({ variant, tone })} data-test="banner" data-test-specifier={dataTestSpecifier}>
            <FoundationContextProvider size="lg">{icon !== undefined ? icon : iconByVariant[variant]}</FoundationContextProvider>
            <BannerContent>{children}</BannerContent>
            {action}
            <Spacer />
            {secondaryAction}
            {isClosable && <CloseButton onClick={onClose} leftSlot={<Cross />} data-test="banner--close-button" />}
          </BannerRoot>
        </FoundationContextProvider>
      </SurfaceContextProvider>
    );
  }
);
