// Global queries only!

import { useQuery } from '@tanstack/react-query';
import { fetchCollectionInfo } from './helpers';

// This hook is used in the Provider only and should not be used in our components - https://tkdodo.eu/blog/react-query-and-react-context
export const useCollectionInfo = () => {
  const collectionInfo = useQuery({ queryKey: ['collection-info'], queryFn: fetchCollectionInfo, staleTime: Infinity });

  return collectionInfo;
};
