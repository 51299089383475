import { useIntl } from 'react-intl';
import React, { useMemo, useState } from 'react';
import DatePicker from '@cld/date-picker';
import { messages } from '../../../../i18n';
import { formatStringDateToLabel, getUpdatedSmdDate } from './SmdDate.helpers';
import { DATE, RAW, SMD_DATE_OPERATORS, SMD_DATE_OPERATOR_KEYS } from './SmdDate.constants';
import { SearchFilterValuesType, SearchFiltersNames, SmdFilterComponentBasePropsType } from '../../types';
import { OperatorFilter, OperatorFilterValue, OperatorOptionType, SmdOperatorType, useCldFilters } from '../../../../cld-filters';

export const SmdDate = ({ filterDefinition, onFilterInteract, onClear }: SmdFilterComponentBasePropsType) => {
  const { values, setFilterValue } = useCldFilters<SearchFilterValuesType>();
  const { externalId, label } = filterDefinition;
  const { formatMessage } = useIntl();
  const smdDateValue: SmdOperatorType | null = values[SearchFiltersNames.SmdDate];
  const currentSmdFieldValue: OperatorFilterValue | undefined = smdDateValue?.[externalId as keyof typeof smdDateValue];
  const isAnyOrEmpty =
    currentSmdFieldValue && (currentSmdFieldValue.operator == SMD_DATE_OPERATOR_KEYS.EMPTY || currentSmdFieldValue.operator == SMD_DATE_OPERATOR_KEYS.ANY);
  const currentDate = currentSmdFieldValue?.value || '';
  const hasValue = isAnyOrEmpty || !!currentDate;
  // The reason to manage 2 states for operator (one in this state, and the second in clfFilters context)
  // is so user can change the operator, without affecting the URL or the query
  const [selectedOperator, setSelectedOperator] = useState<string>(currentSmdFieldValue?.operator || SMD_DATE_OPERATOR_KEYS.IS);

  const smdDateOperatorsOptions = useMemo(() => {
    return Object.keys(SMD_DATE_OPERATORS).map((key) => {
      const { value, translationId } = SMD_DATE_OPERATORS[key as keyof typeof SMD_DATE_OPERATORS];
      return {
        value,
        label: formatMessage(messages[translationId as keyof typeof messages]),
      };
    });
  }, [formatMessage]);

  const handleOperatorChange = (operator: OperatorOptionType) => {
    setSelectedOperator(operator.value);
    const updatedValue = getUpdatedSmdDate({ smdDateValue, externalIdToUpdate: externalId, operator: operator.value, value: currentDate });
    setFilterValue(SearchFiltersNames.SmdDate, updatedValue);
    onFilterInteract(SearchFiltersNames.SmdDate, updatedValue);
  };

  const handleDateInputChange = (value: string) => {
    const updatedValue = getUpdatedSmdDate({ smdDateValue, externalIdToUpdate: externalId, operator: selectedOperator, value });
    setFilterValue(SearchFiltersNames.SmdDate, updatedValue);
    onFilterInteract(SearchFiltersNames.SmdDate, updatedValue);
  };

  const handleClear = () => {
    const updatedValue = getUpdatedSmdDate({ smdDateValue, externalIdToUpdate: externalId, operator: SMD_DATE_OPERATOR_KEYS.IS, value: '' });
    setFilterValue(SearchFiltersNames.SmdDate, updatedValue);
    onClear(SearchFiltersNames.SmdDate);
  };

  const getValueLabel = () => {
    const selectedOperator: OperatorOptionType | undefined = smdDateOperatorsOptions.find((operator) => operator.value === currentSmdFieldValue?.operator);
    if (isAnyOrEmpty) {
      return selectedOperator?.label;
    } else {
      return currentDate ? `${selectedOperator?.label?.toLowerCase()} ${formatStringDateToLabel(currentDate)}` : '';
    }
  };

  return (
    <OperatorFilter
      operators={smdDateOperatorsOptions}
      value={selectedOperator}
      onChange={handleOperatorChange}
      filterLabelPrefix={label}
      valueLabel={getValueLabel()}
      hasValue={hasValue}
      onClear={handleClear}
      renderFieldComponent={
        isAnyOrEmpty ? null : (
          <DatePicker
            rawFormat={RAW}
            format={DATE}
            placeholder={formatMessage(messages.pickADate)}
            value={currentDate}
            onChange={handleDateInputChange}
            isDisabled={isAnyOrEmpty}
          />
        )
      }
    />
  );
};
