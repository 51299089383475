import React, { FC } from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { media } from '@clds/style-utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media ${media.desktop({ minOnly: true })} {
    flex-direction: row;
  }
`;

const Entry = styled.div`
  margin-bottom: ${globalTheme.spacing.xs};

  @media ${media.desktop({ minOnly: true })} {
    border-right: 1px solid ${globalTheme.palette.secondaryAlt};
    margin-bottom: initial;

    &:last-child {
      border: none;
    }

    margin-right: ${globalTheme.spacing.sm};
    padding-right: ${globalTheme.spacing.sm};
  }
`;

const Property = styled.span`
  font-weight: 600;
  padding-right: ${globalTheme.spacing.sm};
`;

export interface MetadataProps {
  metadata: Array<{
    key: string;
    value: string;
  }>;
}

export const Metadata: FC<React.PropsWithChildren<MetadataProps>> = ({ metadata }) => (
  <Wrapper>
    {metadata.map(({ key, value }) => (
      <Entry key={`${key}-${value}`}>
        <Property>{key}</Property>
        <span>{value}</span>
      </Entry>
    ))}
  </Wrapper>
);
