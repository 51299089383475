export const SMD_NUMBER_OPERATOR_KEYS = Object.freeze({
  EQUAL: '=',
  LESS: '<',
  LESS_EQUAL: '<=',
  MORE_EQUAL: '>=',
  MORE: '>',
  ANY: 'any',
  EMPTY: 'empty',
});

export const SMD_NUMBER_OPERATORS = Object.freeze({
  [SMD_NUMBER_OPERATOR_KEYS.EQUAL]: { value: SMD_NUMBER_OPERATOR_KEYS.EQUAL, translationId: '=' },
  [SMD_NUMBER_OPERATOR_KEYS.LESS]: { value: SMD_NUMBER_OPERATOR_KEYS.LESS, translationId: '<' },
  [SMD_NUMBER_OPERATOR_KEYS.LESS_EQUAL]: { value: SMD_NUMBER_OPERATOR_KEYS.LESS_EQUAL, translationId: '<=' },
  [SMD_NUMBER_OPERATOR_KEYS.MORE_EQUAL]: { value: SMD_NUMBER_OPERATOR_KEYS.MORE_EQUAL, translationId: '>=' },
  [SMD_NUMBER_OPERATOR_KEYS.MORE]: { value: SMD_NUMBER_OPERATOR_KEYS.MORE, translationId: '>' },
  [SMD_NUMBER_OPERATOR_KEYS.ANY]: { value: SMD_NUMBER_OPERATOR_KEYS.ANY, translationId: 'anyNumber' },
  [SMD_NUMBER_OPERATOR_KEYS.EMPTY]: { value: SMD_NUMBER_OPERATOR_KEYS.EMPTY, translationId: 'isEmpty' },
});
