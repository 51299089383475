export enum CldFieldsNames {
  DisplayName = 'display_name',
  FileName = 'filename',
  Format = 'format',
  ResourceType = 'resource_type',
  CreatedAt = 'created_at',
  Pixels = 'pixels',
  Bytes = 'bytes',
  Tags = 'tags',
}

export const METADATA_PROPERTY_NAME = 'metadata';
export const METADATA_PREFIX = `${METADATA_PROPERTY_NAME}.`;

export const SMD_FIELD_TYPES = Object.freeze({
  STRING: 'string',
  INTEGER: 'integer',
  DATE: 'date',
  ENUM: 'enum',
  SET: 'set',
});
