import React, { forwardRef, useState, KeyboardEvent, useMemo, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { DateObj, Calendar as CalenderType } from 'dayzed';
import { math } from 'polished';
import { isString } from 'lodash';
import isEqual from 'date-fns/isEqual';
import { globalTheme, combineValues } from '@clds/component-theme';
import { datepickerTheme } from '../datepickerTheme';
import { useWeekdayName } from '../hooks';
import { GetDateProps } from '../types';
import { CalendarProps } from './types';
import Day from './Day';
import WeekDay from './WeekDay';
import Header from './Header';
import Item from './Item';
import useMoveBetweenCalendarDays from './useMoveBetweenCalendarDays';

const Wrapper = styled.div`
  outline: none;
  background-color: ${globalTheme.palette.surface};
  width: ${combineValues(datepickerTheme.day.size, datepickerTheme.day.margin, (size, margin) => {
    return math(`(${size} * 7) + (${margin} * 2 * 7)`);
  })};
`;

const Body = styled.div`
  &:focus {
    outline: none;
  }
`;

const WeekdayWrapper = styled.div``;

const renderCalendarDay = (
  calendar: CalenderType,
  dateObj: DateObj,
  weekIndex: number,
  dayIndex: number,
  focusedDate: Date | undefined,
  getDateProps: GetDateProps
) => {
  const key = `${calendar.month}${calendar.year}${weekIndex}${dayIndex}`;
  const dayProps = dateObj ? getDateProps({ dateObj }) : {};
  const day = dateObj.date ? dateObj.date.getDate() : false;
  const isFocused = focusedDate ? isEqual(dateObj.date, focusedDate) : false;

  return <Day key={key} {...{ day, ...dayProps, ...dateObj, focused: isFocused }} />;
};

const Calendar = forwardRef<HTMLDivElement, CalendarProps>(
  ({ setPopoverVisible, selected, minDate, maxDate, calendars = [], getDateProps, getBackProps, getForwardProps }, ref) => {
    const bodyRef = useRef<HTMLDivElement>(null);
    const calendar = calendars[0];
    const weekdays = useWeekdayName();
    const [focusedDate, setFocusedDate] = useState<Date | undefined>(selected);

    const { month: calendarMonth, year: calendarYear, weeks: calendarWeeks } = calendar || {};

    const moveBetweenDays = useMoveBetweenCalendarDays({
      calendars,
      calendarMonth,
      calendarYear,
      getBackProps,
      getForwardProps,
      focusedDate,
      bodyRef,
      minDate,
      maxDate,
      setFocusedDate,
      calendarWeeks,
    });

    const keyboardHandlers: Record<string, (e: KeyboardEvent<HTMLDivElement>) => void> = useMemo(
      () => ({
        ArrowRight: (e) => moveBetweenDays(e, 1),
        ArrowLeft: (e) => moveBetweenDays(e, -1),
        ArrowUp: (e) => moveBetweenDays(e, -7),
        ArrowDown: (e) => moveBetweenDays(e, 7),
        Enter: (e) => {
          if (focusedDate) {
            const { onClick } = getDateProps({
              dateObj: {
                date: focusedDate,
                nextMonth: false,
                prevMonth: false,
                selected: true,
                selectable: true,
                today: false,
              },
            });

            onClick?.(e);
          }
        },
        Escape: (e) => {
          e.stopPropagation();
          setPopoverVisible(false);
        },
      }),
      [moveBetweenDays, getDateProps, focusedDate, setPopoverVisible]
    );

    const handleOnKeyDown = useCallback(
      (e: KeyboardEvent<HTMLDivElement>) => {
        if (keyboardHandlers[e.key]) {
          keyboardHandlers[e.key](e);
        }
      },
      [keyboardHandlers]
    );

    return calendar ? (
      <Wrapper onKeyDown={handleOnKeyDown} ref={ref} tabIndex={0}>
        <Header calendars={calendars} calendar={calendar} getBackProps={getBackProps} getForwardProps={getForwardProps} />
        <Body tabIndex={-1} ref={bodyRef}>
          <WeekdayWrapper>
            {weekdays.map((weekday, idx) => (
              <WeekDay key={`${weekday}-${idx}`}>{weekday}</WeekDay>
            ))}
          </WeekdayWrapper>
          {calendarWeeks.map((week, weekIndex) =>
            week.map((dateObj, dayIndex) =>
              isString(dateObj) ? (
                // TODO: Introduce key
                // eslint-disable-next-line react/jsx-key
                <Item>&nbsp;</Item>
              ) : (
                renderCalendarDay(calendar, dateObj, weekIndex, dayIndex, focusedDate, getDateProps)
              )
            )
          )}
        </Body>
      </Wrapper>
    ) : null;
  }
);

export default Calendar;
