import { SelectComponentsConfig } from 'react-select';

import { useMemo } from 'react';
import { DropdownIndicator } from '../components/DropdownIndicator';
import { ClearIndicator } from '../components/ClearIndicator';
import { MultiValue } from '../components/MultiValue';
import { NoOptionsMessage } from '../components/NoOptionsMessage';
import { Option } from '../components/Option';
import { IndicatorSeparator } from '../components/IndicatorSeparator';
import { Group } from '../components/Group';
import { GroupHeading } from '../components/GroupHeading';
import { Input } from '../components/Input';

/**
 * A hook that returns a `component` prop to be passed to react-select in order to align it to Design System definition
 * @param components custom overrides for react-select components props to be added after default ones
 */
export const useSelectComponents = <T, IsMulti extends boolean>(components?: SelectComponentsConfig<T, IsMulti>) =>
  useMemo(
    () => ({
      Group,
      GroupHeading,
      IndicatorSeparator,
      DropdownIndicator,
      ClearIndicator,
      MultiValue,
      Option,
      NoOptionsMessage,
      Input,
      ...components,
    }),
    [components]
  );
