import { ASSETS_GROUPS } from '../../SearchFilters.constants';

export const DIMENSIONS_TYPES = Object.freeze({
  icon: { queryValue: '<=0.1m', label: 'icon', resourceType: ASSETS_GROUPS.IMAGE },
  small: { queryValue: ':[0.1m TO 0.5m]', label: 'small', resourceType: ASSETS_GROUPS.IMAGE },
  medium: { queryValue: ':[0.5m TO 2m]', label: 'medium', resourceType: ASSETS_GROUPS.IMAGE },
  large: { queryValue: '>=2m', label: 'large', resourceType: ASSETS_GROUPS.IMAGE },
  sd: { queryValue: '<=0.5m', label: 'sd', resourceType: ASSETS_GROUPS.VIDEO },
  hd: { queryValue: ':[0.5m TO 2m]', label: 'hd', resourceType: ASSETS_GROUPS.VIDEO },
  fullHD: { queryValue: ':[2m TO 8m]', label: 'fullHD', resourceType: ASSETS_GROUPS.VIDEO },
  '4k': { queryValue: '>=8m', label: '4k', resourceType: ASSETS_GROUPS.VIDEO },
});

export type DimensionType = keyof typeof DIMENSIONS_TYPES;
