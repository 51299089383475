import { Asset, isImageAsset, isVideoAsset } from '@cld/console-apps-types';
import React, { ReactNode, useState } from 'react';
import { StyledThumbnailContainer, StyledImage, StyledThumbnailSkeleton } from './Thumbnail.styles';
import { AssetWithDimensions } from '../../types';
import { ThumbnailPlaceholder } from '../AssetCard/ThumbnailPlaceholder';
import { ASSETS_GROUPS } from '../SearchFilters/SearchFilters.constants';

const IMAGE_THUMBNAIL_DEFAULT_WIDTH = 100;
const IMAGE_THUMBNAIL_DEFAULT_HEIGHT = IMAGE_THUMBNAIL_DEFAULT_WIDTH;

interface ImageThumbnailProps {
  asset: Asset;
  transformation?: string;
  itemHeight?: number;
  width?: number;
  height?: number;
  overlay?: ReactNode;
  fallback?: ReactNode;
  dataTest?: string;
  iconColorType?: string;
}

/**
 * @description
 * Takes care of displaying a reduced-sized version of an image.
 * This component functionalities are:
 * - Loading - renders a component (given or default) as loading feedback until image loads
 * - Fallback - renders a component (given or default) as a fallback if image fails to load
 * - Checkers - show checkers background behind the image (effective when it’s with alpha colors)
 */
export const Thumbnail = ({
  asset,
  itemHeight = NaN,
  transformation,
  width = IMAGE_THUMBNAIL_DEFAULT_WIDTH,
  height = IMAGE_THUMBNAIL_DEFAULT_HEIGHT,
  overlay,
  fallback,
  dataTest,
  iconColorType,
  ...imageProps
}: ImageThumbnailProps) => {
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const [isLoadingImageFailed, setIsLoadingImageFailed] = useState(false);
  // Temporary solution until we will figure out the types issues here
  const typedAsset = asset as AssetWithDimensions;
  const isOriginalThumbnailSmaller = isImageAsset(asset) || isVideoAsset(asset) ? typedAsset.height < height && typedAsset.width < width : false;
  const is3DAsset = typedAsset?.type === ASSETS_GROUPS.IMAGE && !(typedAsset?.width || typedAsset?.height);
  const thumbnailSrc = isOriginalThumbnailSmaller && !is3DAsset ? asset?.urls?.original : asset?.urls?.thumbnail;
  const imageSrc = transformation ? thumbnailSrc.replace('upload/', `upload/${transformation}/`) : thumbnailSrc;
  const assetName = asset?.displayName || asset?.filename;
  const FallbackComponent = fallback || <ThumbnailPlaceholder asset={asset} iconColorType={iconColorType} />;

  const handleLoad = () => {
    setIsLoadingImage(false);
  };

  const handleError = () => {
    setIsLoadingImage(false);
    setIsLoadingImageFailed(true);
  };

  if (isLoadingImageFailed || (!isImageAsset(asset) && !isVideoAsset(asset))) {
    return (
      <StyledThumbnailContainer asset={typedAsset} shouldShowCheckers={false} itemHeight={itemHeight}>
        {FallbackComponent}
      </StyledThumbnailContainer>
    );
  }

  return (
    <StyledThumbnailContainer asset={typedAsset} shouldShowCheckers={!isLoadingImage} itemHeight={itemHeight}>
      {isLoadingImage && <StyledThumbnailSkeleton />}

      <StyledImage
        className=""
        src={imageSrc}
        alt={assetName}
        onLoad={handleLoad}
        onError={handleError}
        shouldHide={isLoadingImage}
        data-test-specifier={dataTest}
        // Seems like fetchPriority is not supported yet as an attribute in img tag in React
        {...imageProps}
      />
      {!isLoadingImage && overlay}
    </StyledThumbnailContainer>
  );
};
