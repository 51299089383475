import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { TagSign } from '@clds/icon';
import { AsyncMultiSelectFilter, MultiSelectOptionType, MultiSelectGroupOptionType, useCldFilters } from '../../../../cld-filters';
import { FilterComponentBasePropsType, SearchFilterValuesType, SearchFiltersNames } from '../../types';
import { messages } from '../../../../i18n';
import { fetchTags } from '../../../../api/autocomplete/helpers';

const createTagsGroup = (options: MultiSelectOptionType[] | undefined) => [
  {
    label: 'Tags',
    options,
  },
];

export const Tags = ({ onFilterInteract, onClear }: FilterComponentBasePropsType) => {
  const { values, setFilterValue } = useCldFilters<SearchFilterValuesType>();
  const [tagsOptions, setTagsOptions] = useState<MultiSelectGroupOptionType[]>([]);
  const { formatMessage } = useIntl();
  const tags = values[SearchFiltersNames.Tags];

  const optionCreator = (value: MultiSelectOptionType) => ({
    id: value.id,
    value: value.value,
    label: value.label,
    icon: <TagSign />,
  });

  const stringToOptionType = (value: string) => {
    return {
      id: value,
      value: value,
      label: value,
      icon: <TagSign />,
    };
  };

  const handleChange = useCallback(
    (tags: MultiSelectOptionType[]) => {
      const tagsValues = tags.map((tag) => tag.value);
      setFilterValue(SearchFiltersNames.Tags, tagsValues);
      onFilterInteract(SearchFiltersNames.Tags, tagsValues);
    },
    [setFilterValue, onFilterInteract]
  );

  const loadOptions = async (newInput: string) => {
    const tags = await fetchTags(newInput);
    const options = tags?.map((tag) => stringToOptionType(tag));
    const optionsGroup = createTagsGroup(options) as MultiSelectGroupOptionType[];
    setTagsOptions(optionsGroup);
  };

  const handleClear = () => {
    onClear(SearchFiltersNames.Tags);
  };

  return (
    <AsyncMultiSelectFilter
      values={tags.map((t) => stringToOptionType(t))}
      onClear={handleClear}
      optionCreator={optionCreator}
      onChange={handleChange}
      options={tagsOptions}
      filterLabelPrefix={formatMessage(messages.tags)}
      dataTest="search-tags"
      withFilterInput
      loadOptions={loadOptions}
    />
  );
};
