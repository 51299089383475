import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Typography, typography } from '@clds/typography';
import CustomerLogo from '@cld/customer-logo';
import { CloudinaryLogo } from '@clds/icon';
import { CollectionLogo } from './CollectionLogo';
import { MenuItem } from '@clds/menu-old';
import { MenuItemProps } from '@clds/menu-old/src/MenuItem';

interface StyledMenuItemProps extends MenuItemProps {
  isDisabled?: boolean;
}

export const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${globalTheme.spacing.lg};
  gap: ${globalTheme.spacing.md};

  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
`;

export const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  overflow: hidden;
  display: inline-grid;
  margin-left: ${globalTheme.spacing.xs};

  ${typography.md.highContrast};
  line-height: ${globalTheme.font.size.xl};
`;

export const Count = styled.span`
  flex-shrink: 0;
  margin-left: ${globalTheme.spacing.xs};

  ${typography.xs.regular};
  line-height: ${globalTheme.font.size.xl};
`;

export const StyledHeaderLeftSlot = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const StyledHeaderRightSlot = styled.div`
  display: flex;
  align-items: center;
  gap: ${globalTheme.spacing.xxs};
`;

export const StyledCustomerLogo = styled(CustomerLogo)`
  max-height: 40px;
  max-width: 40px;
  min-height: 0;
`;

export const StyledCollectionLogo = styled(CollectionLogo)`
  width: 40px;
  height: 40px;
`;

export const StyledCloudinaryLogo = styled(CloudinaryLogo)`
  color: ${globalTheme.palette.contrastLow};
`;

export const StyleTypography = styled(Typography)`
  width: max-content;
`;

export const StyleMenuItem = styled(MenuItem)<StyledMenuItemProps>`
  opacity: ${({ isDisabled }) => isDisabled && '0.5'};
  padding: ${globalTheme.spacing.xxs} 0;
`;
