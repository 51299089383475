import React from 'react';
import Tooltip from '@clds/tooltip';
import { StyledTruncated } from '../FilterChipLabel/FilterChipLabel.styles';

interface TruncatedWithTooltipProps {
  tooltipText: string;
  children: React.ReactNode | string;
}

export const TruncatedWithTooltip: React.FC<TruncatedWithTooltipProps> = ({ tooltipText, children }) => (
  <Tooltip title={tooltipText} placement="topRight" maxWidth="md">
    <StyledTruncated data-testid="truncated-with-tooltip">{children}</StyledTruncated>
  </Tooltip>
);
