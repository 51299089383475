import { CldFiltersConfig, CldFiltersContextType, CldFiltersValues } from '../types';
import React, { createContext, useEffect, useReducer } from 'react';
import { cldFiltersReducer } from './CldFilters.reducer';
import { getCldFiltersInitialContext } from './CldFilters.helpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CldFiltersContext = createContext<CldFiltersContextType<any>>(undefined as any);

export const CldFiltersProvider = <Values extends CldFiltersValues = CldFiltersValues>(props: CldFiltersConfig<Values>) => {
  const initialContext = getCldFiltersInitialContext<Values>(props);
  const [state, dispatch] = useReducer(cldFiltersReducer<Values>, initialContext);

  useEffect(() => {
    props.onChange?.(state);
  }, [state, props.onChange]);

  return <CldFiltersContext.Provider value={{ state, dispatch }}>{props.children}</CldFiltersContext.Provider>;
};
