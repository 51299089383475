import { SmdOperatorType } from '../../cld-filters';
import { MinMaxType } from '../../cld-filters/components/RangeFilter/RangeFilter.types';
import { FilterDefinition } from '../../types';
import { SizeFilterUnits } from './Filters/SizeFilter/SizeFilter.types';
import { SmdMultipleType } from './Filters/SmdMultiple/SmdMultiple.types';

export enum SearchFiltersNames {
  FreeSearch = 'freeSearch',
  AssetTypes = 'resourceType',
  Formats = 'formats',
  Dimensions = 'dimensions',
  DateCreated = 'createdAt',
  Size = 'size',
  Tags = 'tags',
  SmdMultiple = 'smdMultiple',
  SmdText = 'smdText',
  SmdDate = 'smdDate',
  SmdNumber = 'smdNumber',
}

export interface SearchFilterValuesType {
  [SearchFiltersNames.FreeSearch]: string;
  [SearchFiltersNames.AssetTypes]: string[];
  [SearchFiltersNames.Formats]: string[];
  [SearchFiltersNames.Dimensions]: string[];
  [SearchFiltersNames.DateCreated]: string;
  [SearchFiltersNames.Size]: { range: MinMaxType; unit?: SizeFilterUnits };
  [SearchFiltersNames.Tags]: string[];
  [SearchFiltersNames.SmdMultiple]: SmdMultipleType;
  [SearchFiltersNames.SmdText]: SmdOperatorType | null;
  [SearchFiltersNames.SmdDate]: SmdOperatorType | null;
  [SearchFiltersNames.SmdNumber]: SmdOperatorType | null;
}

export interface FilterComponentBasePropsType {
  key?: string;
  onFilterInteract: (field: string, fieldValues: unknown) => void;
  onClear: (field: string) => void;
}

export interface SmdFilterComponentBasePropsType extends FilterComponentBasePropsType {
  filterDefinition: FilterDefinition;
}

export interface MetadataFieldOption {
  externalId: string;
  value: string;
}

export interface SearchSuggestionsType {
  tags?: string[] | null;
  metadataFieldOptions?: MetadataFieldOption[] | null;
}
