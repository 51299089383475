import { richTextSchema } from '@cld/console-apps-types';
import * as yup from '@cld/yup-extra';
import { COLLECTION_KINDS } from '../../types';
export const ACTIVE_USER_STATUS = 'active';

export const collectionInfoSchema = yup
  .object({
    id: yup.string().required(),
    collectionKind: yup.mixed().oneOf(Object.values(COLLECTION_KINDS)).required(),
    totalCount: yup.number().required(),
    totalCountTruncated: yup.boolean().required(),
    downloadAssetsLimit: yup.number().required(),
    name: yup.string().required(),
    description: richTextSchema,
    linkExpiredAt: yup.string().nullable(),
    downloadUrl: yup.string().optional().nullable(), // TODO: remove BC once BE merged DAM-10034
    lastModifiedAt: yup.string().required(),
    createdAt: yup.string().required(),
    customLogoPath: yup.string().required(),
    sharedPresets: yup.array(
      yup
        .object({
          name: yup.string().required(),
          presetTypes: yup.array().of(yup.string().required()).required(),
          transformation: yup.string().required(),
        })
        .optional()
        .camelCase()
    ),
    flags: yup
      .object({
        localizationEnabled: yup.boolean().optional(), // TODO: change once BE merged DAM-5002
        folderDecouplingEnabled: yup.boolean().required(),
      })
      .camelCase()
      .optional(),
    preferences: yup
      .object({
        locale: yup.string().nullable(),
        preventDownloadOriginal: yup.boolean(),
        theme: yup
          .object({
            primaryColor: yup.string().nullable(),
            primaryColorDark: yup.string().nullable(),
          })
          .camelCase()
          .optional(),
        logo: yup
          .object({
            deliveryUrl: yup.string().nullable(),
          })
          .camelCase()
          .optional(),
      })
      .camelCase()
      .optional(),
    owner: yup
      .object({
        firstName: yup.string().nullable(),
        lastName: yup.string().nullable(),
        status: yup.string().default(ACTIVE_USER_STATUS).nullable(),
      })
      .camelCase()
      .nullable(),
    allowedSearchFields: yup
      .array()
      .of(
        yup
          .object({
            property: yup.string(),
            externalId: yup.string(),
            type: yup.string().optional(),
            label: yup.string().optional(),
          })
          .camelCase()
          .defined()
      )
      .nullable(),
  })
  .from('collection_name', 'name')
  .from('collection_description', 'description')
  .from('collection_download_link', 'downloadUrl')
  .camelCase()
  .noUnknown()
  .required();

export type CollectionInfoDto = yup.InferType<typeof collectionInfoSchema>;
