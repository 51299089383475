import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { filter, orderBy, some } from 'lodash';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { QueryClientProvider } from '@tanstack/react-query';
import { LocaleContext, useUpdatedDocumentLang } from '@cld/intl';
import { RedirectedURLMessage } from '@cld/redirected-url-message';
import { CLOUDINARY_FAVICON } from '@cld/use-cloudinary-favicon';
import { messages } from '../i18n';
import { DownloadPresetsModal } from './DowloadPresetsModal/DownloadPresetsModal';
import { CloudinaryTrackerProvider } from './CloudinaryTrackerProvider/CloudinaryTrackerProvider';
import { CollectionGrid } from './AssetGrid/CollectionGrid';
import Footer from './Footer/Footer';
import { Preview } from './Preview/Preview';
import RefreshModal from './RefreshModal/RefreshModal';
import { MainLayout } from './MainLayout';
import { TranslationProvider } from './TranslationProvider';
import { getAssetTypes } from './DowloadPresetsModal/utils';
import { useCloudinaryTracker } from './CloudinaryTrackerProvider/hooks';
import { useSupportedLanguages } from './AssetGrid/LanguageSelector/useSupportedLanguages';
import { useAssets } from './AssetGrid/VirtualizedGrid/VirtualizedGrid.hooks';
import { queryClient } from '../api/queryClient';
import { CollectionInfoProvider } from '../context/CollectionInfoContext/CollectionInfoProvider';
import { useFavicon, useTitle } from 'react-use';
import { SearchProvider } from '../context/SearchContext/SearchProvider';
import { useCollectionInfoContext } from '../context/CollectionInfoContext/CollectionInfo.hooks';
import { Asset } from '@cld/console-apps-types';
import { CollectionDynamicTheme } from './Theme/CollectionTheme';
import { DownloadType } from './Enums/Enum';

const Root = styled.div`
  background-color: ${globalTheme.palette.surface};
`;

export const SESSION_DATA_EXPIRY = 60 * 60 * 1000;

export const CollectionContent = () => {
  const cloudinaryTracker = useCloudinaryTracker();
  const [previewedAssetIndex, setPreviewedAssetIndex] = useState<number | null>(null);
  const [refreshModalOpen, setRefreshModalOpen] = useState(false);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [downloadType, setDownloadType] = useState(DownloadType.SEARCHED_ASSETS);
  const [downloadableAssets, setDownloadableAssets] = useState<Asset[] | Asset | null>(null);
  const { allFetchedAssets } = useAssets();
  const collectionInfo = useCollectionInfoContext();

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setRefreshModalOpen(true);
      },
      SESSION_DATA_EXPIRY - 3 * 60 * 1000
    ); // display popup 3 minutes before expiry
    return () => clearTimeout(timeout);
  }, []);

  const preventDownload = !!collectionInfo.preferences?.preventDownloadOriginal;
  const { sharedPresets, id: publicLinkId } = collectionInfo;

  const assetsTypes = getAssetTypes(allFetchedAssets);
  const filteredPresets =
    sharedPresets &&
    filter(sharedPresets, (preset) => {
      const presetTypes = preset.presetTypes;
      return some(presetTypes, (presetType) => !!assetsTypes[presetType]);
    });
  const sortedPresets = filteredPresets && orderBy(filteredPresets, (preset) => preset?.name?.toLowerCase(), ['asc']);
  const isDownloadAllowed = !preventDownload || sortedPresets.length > 0;

  const onDownload = (assets: Asset[] | Asset | null, downloadType?: DownloadType) => {
    const selectedDownloadType = downloadType ?? DownloadType.All;
    setDownloadType(selectedDownloadType);
    setDownloadModalOpen(true);
    setDownloadableAssets(assets);
  };

  const wasPageViewEventReported = useRef(false);
  useEffect(() => {
    if (!wasPageViewEventReported.current) {
      wasPageViewEventReported.current = true;
      cloudinaryTracker({ eventName: 'collection_page_view' });
    }
  }, [cloudinaryTracker]);

  return (
    <Root>
      <DownloadPresetsModal
        downloadType={downloadType}
        assets={downloadableAssets}
        publicLinkId={publicLinkId}
        preventDownload={preventDownload}
        presets={sortedPresets}
        isOpen={downloadModalOpen}
        onClose={() => setDownloadModalOpen(false)}
        // @ts-expect-error Silencing existing error, please fix it
        trackingAssetPosition={previewedAssetIndex + 1}
      />
      <MainLayout
        content={
          <>
            <RedirectedURLMessage appName="collection" />
            <CollectionGrid
              onModalDownload={onDownload}
              presets={sortedPresets}
              preventDownload={preventDownload}
              customLogoPath={collectionInfo.customLogoPath}
              setPreviewedAssetIndex={setPreviewedAssetIndex}
            />
          </>
        }
        footer={<Footer />}
      />
      <Preview
        onModalDownload={onDownload}
        presets={sortedPresets}
        preventDownload={preventDownload}
        previewedAssetIndex={previewedAssetIndex}
        // @ts-expect-error Silencing existing error, please fix it
        setPreviewedAssetIndex={setPreviewedAssetIndex}
        isDownloadEnabled={isDownloadAllowed}
      />
      <RefreshModal isOpen={refreshModalOpen} onClose={() => setRefreshModalOpen(false)} />
    </Root>
  );
};

export const Collection = () => {
  const { formatMessage } = useIntl();
  const notFoundMessage = formatMessage(messages.collectionNotFound);
  const collectionInfo = useCollectionInfoContext();

  useTitle(collectionInfo.collection.name || notFoundMessage);
  useFavicon(collectionInfo?.preferences?.logo?.deliveryUrl ?? CLOUDINARY_FAVICON);
  useUpdatedDocumentLang();
  const locale = useContext(LocaleContext);
  const { languages } = useSupportedLanguages();
  const currentLanguage = languages.find((language) => language.languageKey === locale)?.languageLabel;

  return (
    // @ts-expect-error Silencing existing error, please fix it
    <CloudinaryTrackerProvider language={currentLanguage} collection_id={collectionInfo?.id} collection_name={collectionInfo.collection.name}>
      <SearchProvider>
        <CollectionContent />
      </SearchProvider>
    </CloudinaryTrackerProvider>
  );
};

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <TranslationProvider>
        <CollectionInfoProvider>
          <CollectionDynamicTheme>
            <Collection />
          </CollectionDynamicTheme>
        </CollectionInfoProvider>
      </TranslationProvider>
    </QueryClientProvider>
  );
};

export default App;
