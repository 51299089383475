import React, { FC, KeyboardEvent, MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { globalTheme, combineValues } from '@clds/component-theme';
import { typography } from '@clds/typography';
import Item from './Item';

export interface DayProps {
  day: number | false;
  selected: boolean;
  selectable: boolean;
  focused: boolean;
  today: boolean;
  nextMonth: boolean;
  prevMonth: boolean;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
  onClick?: (event: MouseEvent | KeyboardEvent) => void;
}

type StyledDayProps = Omit<DayProps, 'day'>;

const focusCss = css<StyledDayProps>`
  box-shadow: 0 0 0 3px ${combineValues(globalTheme.palette.primary, (color) => rgba(color, 0.5))};

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    border: 1px solid ${globalTheme.palette.primary};
  }
`;

const selectedBgColor = css<StyledDayProps>`
  background-color: ${combineValues(globalTheme.palette.primary, (color, { selectable, prevMonth, nextMonth }) =>
    !selectable || prevMonth || nextMonth ? rgba(color, 0.3) : color
  )};
`;

const selectedCss = css`
  ${typography.xs.contrastInvert};
  ${selectedBgColor};

  :hover {
    ${selectedBgColor}
  }
`;

const todayCss = css`
  background-color: ${combineValues(globalTheme.palette.primary, (color) => rgba(color, 0.06))};
  ${typography.xs.highContrast};
  :hover {
    background-color: ${combineValues(globalTheme.palette.contrastHigh, (color) => rgba(color, 0.06))};
  }
`;

const disabledCss = css`
  ${typography.xs.disabled};
  color: ${combineValues(globalTheme.palette.secondaryAlt, (color) => rgba(color, 0.5))};
  pointer-events: none;
`;

const StyledDay = styled(Item)<StyledDayProps>`
  transition:
    background-color 350ms,
    color 350ms,
    box-shadow 250ms,
    opacity 250ms;
  position: relative;
  outline: none;
  cursor: pointer;
  ${typography.xs.regular};

  :hover {
    background-color: ${combineValues(globalTheme.palette.primary, (color) => rgba(color, 0.06))};
  }

  ${({ focused, selectable }) => focused && selectable && focusCss};
  ${({ today, selected, selectable }) => today && !selected && selectable && todayCss};
  ${({ selectable, prevMonth, nextMonth }) => (!selectable || prevMonth || nextMonth) && disabledCss};
  ${({ selected, selectable }) => selected && selectable && selectedCss};
`;

const Day: FC<React.PropsWithChildren<DayProps>> = ({ day, ...props }) => {
  return <>{!day ? <Item>&nbsp;</Item> : <StyledDay {...props}>{day}</StyledDay>}</>;
};

export default Day;
