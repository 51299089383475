import { IndicatorComponentType } from 'react-select/src/components';
import React, { type ComponentProps } from 'react';
import { OptionTypeBase } from 'react-select';
import styled from 'styled-components';
import { useMixin } from '@clds/component-theme';
import { CrossInCircle } from '@clds/icon';
import { ComboboxSize } from '../types';
import { comboboxTheme } from '../theme';

const ClearIcon = styled(CrossInCircle)`
  margin-right: ${comboboxTheme.clearIcon.margin.right};
  cursor: pointer;
`;

export const ClearIndicator = <IsMulti extends boolean>({ selectProps, innerProps }: ComponentProps<IndicatorComponentType<OptionTypeBase, IsMulti>>) => {
  const color = useMixin(comboboxTheme.clearIcon.color);
  const size = useMixin(comboboxTheme.clearIcon.iconSize[selectProps.size as ComboboxSize]);
  return <ClearIcon size={size} color={color} {...innerProps} />;
};
