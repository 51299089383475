import * as yup from '@cld/yup-extra';

export const searchSuggestionsSchema = yup
  .object({
    tags: yup.array().of(yup.string().defined()),
    metadataFieldOptions: yup.array().of(
      yup
        .object({
          externalId: yup.string(),
          value: yup.string(),
        })
        .camelCase()
        .defined()
    ),
  })
  .camelCase()
  .required();

export type SearchSuggestionsDto = yup.InferType<typeof searchSuggestionsSchema>;
