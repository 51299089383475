import { cloneDeep, reduce, mapValues, groupBy } from 'lodash';
import { GAP, MOSAIC_CARD_MAX_HEIGHT, RIGHT_LEFT_PADDING } from './MosaicLayout.constants';
import { MosaicCardConfiguration, MosaicRowConfiguration } from './MosaicLayout.types';
import { Asset, isImageAsset, isVideoAsset } from '@cld/console-apps-types';
import { AssetWithDimensions } from '../../types';
import { PAGE_SIZE } from '../AssetGrid/VirtualizedGrid/VirtualizedGrid.helpers';

export const numberWithCommas = (number: number) => {
  return number === null || number === undefined ? '' : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getRowHeight = (assets: Asset[], width: number, gap: number) => {
  const realWidth = width - assets.length * gap + gap;
  const ratio = assets.reduce((sum: number, asset: Asset) => {
    const typedAsset = asset as AssetWithDimensions;
    const isImageOrVideo = isImageAsset(typedAsset) || isVideoAsset(typedAsset);
    const isWidthHeightDefined = typedAsset?.width && typedAsset?.height;
    const imageWidth: number = isImageOrVideo && isWidthHeightDefined ? typedAsset.width : MOSAIC_CARD_MAX_HEIGHT;
    const imageHeight: number = isImageOrVideo && isWidthHeightDefined ? typedAsset.height : MOSAIC_CARD_MAX_HEIGHT;
    sum += imageWidth / imageHeight;
    return sum;
  }, 0);

  return realWidth / ratio;
};

// *** This source code was taken from https://github.com/josephj/react-justified-grid/blob/master/src/utils.ts
/**
 * Curate image list according to setting
 */
export const curateImageList = (items: Asset[], width: number, setting: unknown): MosaicRowConfiguration[] => {
  // eslint-disable-next-line
  // @ts-ignore
  const { gap, rows, maxRowHeight, showIncompleteRow } = setting;
  const rowWidth = width;

  let imageList = cloneDeep(items);
  let processedImageList: MosaicCardConfiguration[] = [];
  let rowIndex = 0;

  while (imageList.length > 0 && (rows === undefined || rows > rowIndex)) {
    let height = 0;
    let isFulfilled = false;
    let offset = 0;
    let selectedImages: Asset[] = [];
    imageList.some(() => {
      selectedImages = imageList.slice(0, offset + 1);
      height = getRowHeight(selectedImages, rowWidth, gap);
      isFulfilled = height <= maxRowHeight;
      if (!isFulfilled) {
        offset += 1;
        return false;
      }
      processedImageList = updateProcessedImageList(processedImageList, selectedImages, height, rowIndex);
      return true;
    });

    if (!isFulfilled) {
      if (showIncompleteRow) {
        processedImageList = updateProcessedImageList(processedImageList, selectedImages, maxRowHeight, rowIndex);
      }

      return getFinalizedImagesListByRowOffset(processedImageList);
    }

    imageList = cloneDeep(items).slice(processedImageList.length);
    rowIndex += 1;
  }

  return getFinalizedImagesListByRowOffset(processedImageList);
};

export const updateProcessedImageList = (
  processImageList: MosaicCardConfiguration[],
  selectedImages: Asset[],
  rowHeight: number,
  rowIndex: number
): MosaicCardConfiguration[] => {
  processImageList = reduce(
    selectedImages,
    (result, imageItem, i) => {
      const typedImage = imageItem as AssetWithDimensions;

      const ratio = rowHeight / (typedImage.height || MOSAIC_CARD_MAX_HEIGHT);
      const width = (typedImage.width || MOSAIC_CARD_MAX_HEIGHT) * ratio;
      result.push({
        width,
        height: rowHeight,
        rowOffset: rowIndex,
        originalData: typedImage,
      });

      return result;
    },
    processImageList
  );

  return processImageList;
};

//*** end of source code

export const getFinalizedImagesListByRowOffset = (images: MosaicCardConfiguration[]): MosaicRowConfiguration[] => {
  const groupedByRowOffset = mapValues(groupBy(images, 'rowOffset'));
  return Object.keys(groupedByRowOffset).map((rowOffset) => groupedByRowOffset[rowOffset]);
};

//This helper arranges the items based on their row offset, resulting in an array of rows.
//Each row in this array contains items that can be displayed within that row, determined by the container's width.
export const getMosaicRows = (width: number, items: Asset[]): MosaicRowConfiguration[] => {
  const assetsOrderedByOffset: MosaicRowConfiguration[] = curateImageList(items, width - RIGHT_LEFT_PADDING, {
    gap: GAP,
    showIncompleteRow: true,
    maxRowHeight: MOSAIC_CARD_MAX_HEIGHT,
  });

  return assetsOrderedByOffset;
};

export const getMockedMosaicRows = (width: number): MosaicRowConfiguration[] => {
  const mockedItems = Array.from({ length: PAGE_SIZE }, () => ({ width: Math.random() * 2000 + 100, height: Math.random() * 2000 + 200 }));
  // eslint-disable-next-line
  // @ts-ignore
  return getMosaicRows(width, mockedItems);
};
