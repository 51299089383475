import { createTheme, createVariant } from '@clds/component-theme';

import { CustomerLogoProps } from './components/CustomerLogo';

export const theme = createTheme(
  {
    minHeight: '20px',
    sm: {
      maxHeight: '20px',
      maxWidth: '20px',
    },
    md: {
      maxHeight: '32px',
      maxWidth: '32px',
    },
    lg: {
      maxHeight: '60px',
      maxWidth: '60px',
    },
    xl: {
      maxHeight: '100px',
      maxWidth: '100px',
    },
    stretch: {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  'customer-logo'
);

type VariantProps = Required<Pick<CustomerLogoProps, 'size'>>;

export const sizeVariant = createVariant(({ size }: VariantProps) => theme[size || 'stretch']);
