import { CrossInCircle, Search } from '@clds/icon';
import { StyledIconWrapper, StyledSearchInputWrapper, StyledTextField } from './FreeSearchFilter.styles';
import React from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Tooltip } from '@clds/tooltip';
import { useIntl } from 'react-intl';
import { messages } from '../../../i18n/messages';

interface FreeSearchFilterProps {
  value: string;
  placeholder: string;
  dataTest?: string;
  onChange: (value: string) => void;
  onClear: () => void;
  onFocus?: () => void;
}

export const FreeSearchFilter = ({ value, placeholder, dataTest, onChange, onClear, onFocus }: FreeSearchFilterProps) => {
  const { formatMessage } = useIntl();

  return (
    <StyledSearchInputWrapper data-test-specifier={dataTest}>
      <StyledTextField
        type="text"
        onFocus={onFocus}
        leadingDecoration={<Search color="contrastLow" size="sm" />}
        trailingDecoration={
          value && (
            <Tooltip trigger="hover" title={formatMessage(messages.clearFilter)}>
              <StyledIconWrapper onClick={onClear} data-test-specifier="clear-button">
                <CrossInCircle color="contrastLow" size="sm" />
              </StyledIconWrapper>
            </Tooltip>
          )
        }
        value={value ?? ''}
        onChange={onChange}
        placeholder={placeholder}
      />
    </StyledSearchInputWrapper>
  );
};
