import React from 'react';
import { Description } from '@cld/console-apps-types';
import { CollectionDescription } from './CollectionDescription';
import { SearchFilters } from '../SearchFilters/SearchFilters';
import { CollectionGridSort } from './CollectionGridSort';
import { SearchSortContainer } from './CollectionGrid.styles';

export interface CollectionDescriptionProps {
  description?: Description;
}

export const CollectionGridTopSection = ({ description }: CollectionDescriptionProps) => {
  return (
    <>
      <SearchSortContainer>
        <SearchFilters />
      </SearchSortContainer>
    </>
  );
};
