import React, { FC } from 'react';
import { usePersistency } from '@cld/use-persistency';
import { LanguageProvider, LocaleContext, defaultLocale } from '@cld/intl';
import { loadMessages } from '../i18n';
import { useCollectionInfo } from '../api/collectionInfo/hooks';

// The local which will be displayed in the app is based on several sources.
// Once the infoData API return a locale (which was set in the DAM) it will be the displayed locale,
// unless the user decided to override it - in that case the user locale selection is saved in his localStorage.
// In case neither the user or the customer selected a lang, we display the default locale which is English.

export const TranslationProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const persistenceKey = 'userLocale';
  const { value: userLocale } = usePersistency(persistenceKey);

  const { data } = useCollectionInfo();
  const fallbackLocale: string = data?.collection?.preferences?.locale ?? defaultLocale;

  const locale = userLocale ?? fallbackLocale;

  return (
    <LocaleContext.Provider value={locale}>
      <LanguageProvider loadMessages={loadMessages} data-test="language-provider">
        {children}
      </LanguageProvider>
    </LocaleContext.Provider>
  );
};
