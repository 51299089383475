import Chip from '@clds/chip';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';

export const StyledFilterChip = styled(Chip)`
  height: 28px;
  border-radius: ${globalTheme.spacing.md};
  max-width: 250px;
`;

export const StyledCloseDiv = styled.div`
  display: flex;
`;
