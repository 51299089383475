import React, { ReactEventHandler, useState } from 'react';

const BASE_RETRY_PERIOD_MILLIS = 2000;
const MAX_RETRIES = 5;

export interface ImageProps {
  src: string;
  alt: string;
  className: string;
  onLoad: ReactEventHandler;
  onError: ReactEventHandler;
}

export const Image = ({ src, alt, onLoad, onError, className, ...imgProps }: ImageProps) => {
  const [retryCount, setRetryCount] = useState(0);
  return (
    <img
      {...imgProps}
      key={`${src}--${retryCount}`}
      className={className}
      src={src}
      onLoad={(e) => {
        onLoad?.(e);
      }}
      alt={alt}
      onError={(e) => {
        // Unfortunately we can't get the HTTP error code from the event
        if (retryCount < MAX_RETRIES) {
          setTimeout(
            () => {
              setRetryCount(retryCount + 1);
            },
            BASE_RETRY_PERIOD_MILLIS * (retryCount + 1)
          );
        } else {
          onError?.(e);
        }
      }}
    />
  );
};
