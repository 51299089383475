import { SizeFilterUnits } from './SizeFilter.types';

export const SEARCH_FILE_SIZE = Object.freeze({
  MIN: 0,
  STEP: 1,
  UNITS: [
    { value: SizeFilterUnits.gb, label: 'GB' },
    { value: SizeFilterUnits.mb, label: 'MB' },
    { value: SizeFilterUnits.kb, label: 'KB' },
  ],
});
