import { KeyboardEvent, RefObject, SyntheticEvent, useCallback } from 'react';
import { isUndefined } from 'lodash';
import { DateObj } from 'dayzed';
import getStartOfDay from 'date-fns/startOfDay';
import addDays from 'date-fns/addDays';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import { isDateInRange } from '../utils';
import { CalendarProps } from './types';

const checkIfDayInMonth = (d: DateObj | '') => d && !d.prevMonth && !d.nextMonth;

interface MoveBetweenCalendarDaysProps extends Omit<CalendarProps, 'setPopoverVisible' | 'selected' | 'getDateProps'> {
  focusedDate?: Date;
  calendarMonth: number;
  calendarYear: number;
  bodyRef: RefObject<HTMLDivElement>;
  setFocusedDate: (date: Date) => void;
  calendarWeeks: Array<Array<DateObj | ''>>;
}

const useMoveBetweenCalendarDays = ({
  calendars,
  calendarMonth,
  calendarYear,
  getBackProps,
  getForwardProps,
  focusedDate,
  bodyRef,
  minDate,
  maxDate,
  setFocusedDate,
  calendarWeeks,
}: MoveBetweenCalendarDaysProps) => {
  const moveBetweenMonths = useCallback(
    (e: SyntheticEvent, nextMonth: number, nextYear: number) => {
      const moveMonthProps = { calendars };

      if (calendarYear > nextYear || (calendarYear === nextYear && calendarMonth > nextMonth)) {
        getBackProps?.(moveMonthProps)?.onClick(e);
      } else if (calendarYear < nextYear || calendarMonth < nextMonth) {
        getForwardProps?.(moveMonthProps)?.onClick(e);
      }
    },
    [getBackProps, getForwardProps, calendarMonth, calendarYear, calendars]
  );

  const moveBetweenDays = useCallback(
    (e: KeyboardEvent, increaseBy: number, startDay?: Date) => {
      const day = focusedDate || startDay;

      if (day) {
        if (!isUndefined(calendarMonth)) {
          //ensure while moving days, focus is not left on the months back/forward buttons
          bodyRef.current?.focus();

          const nextDay = getStartOfDay(addDays(day, increaseBy));
          const nextMonth = getMonth(nextDay);
          const nextYear = getYear(nextDay);

          if (isDateInRange(nextDay, minDate, maxDate)) {
            moveBetweenMonths(e, nextMonth, nextYear);
            setFocusedDate(nextDay);
          }
        }
      } else {
        //no day is focused yet. Find the first day of the currently displayed month and set is as focused
        const firstWeekOfMonth: Array<DateObj | ''> | undefined = calendarWeeks.find((week: Array<DateObj | ''>) => !!week.find(checkIfDayInMonth));

        const firstDayOfMonth = firstWeekOfMonth?.find(checkIfDayInMonth);

        if (firstDayOfMonth) {
          setFocusedDate(firstDayOfMonth.date);
        }
      }
    },
    [moveBetweenMonths, setFocusedDate, calendarMonth, focusedDate, minDate, maxDate, calendarWeeks, bodyRef]
  );

  return moveBetweenDays;
};

export default useMoveBetweenCalendarDays;
