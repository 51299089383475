import React from 'react';
import { DIMENSIONS_TYPES } from './Dimensions.constants';
import { ASSETS_GROUPS } from '../../SearchFilters.constants';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../../../i18n';
import { MultiSelectGroupOptionType, MultiSelectOptionType } from '../../../../cld-filters';

const imageDimensions: (MultiSelectOptionType & { resourceType: string })[] = Object.keys(DIMENSIONS_TYPES)
  // @ts-expect-error Silence lacking inference error
  .filter((key) => DIMENSIONS_TYPES[key].resourceType === ASSETS_GROUPS.IMAGE)
  .map((key) => {
    // @ts-expect-error Silence lacking inference error
    const { label, resourceType } = DIMENSIONS_TYPES[key];
    return {
      value: key,
      resourceType,
      // @ts-expect-error Silence lacking inference error
      label: <FormattedMessage {...messages[label]} />,
    };
  });

const videoDimensions: MultiSelectOptionType[] = Object.keys(DIMENSIONS_TYPES)
  // @ts-expect-error Silence lacking inference error
  .filter((key) => DIMENSIONS_TYPES[key].resourceType === ASSETS_GROUPS.VIDEO)
  .map((key) => {
    // @ts-expect-error Silence lacking inference error
    const { label, resourceType } = DIMENSIONS_TYPES[key];
    return {
      value: key,
      resourceType,
      // @ts-expect-error Silence lacking inference error
      label: <FormattedMessage {...messages[label]} />,
    };
  });

export const dimensionsOptions: MultiSelectGroupOptionType[] = [
  {
    label: <FormattedMessage {...messages.image} />,
    options: imageDimensions,
  },
  {
    label: <FormattedMessage {...messages.video} />,
    options: videoDimensions,
  },
];
