import * as ACTION_TYPES from './CldFilters.actionTypes';
import { DispatchAction } from '../types';
import { Dispatch } from 'react';

export const setFilterValue = (field: string, value: unknown): DispatchAction => ({
  type: ACTION_TYPES.SET_FILTER_VALUE,
  payload: {
    field,
    value,
  },
});

export const setMultipleValues = (values: unknown): DispatchAction => ({
  type: ACTION_TYPES.SET_MULTIPLE_VALUES,
  payload: {
    values,
  },
});

export const setSmdFilterValue = (parentFieldId: string, field: string, value: unknown): DispatchAction => ({
  type: ACTION_TYPES.SET_SMD_FILTER_VALUE,
  payload: {
    parentFieldId,
    field,
    value,
  },
});

export const getCldFiltersActions = (dispatch: Dispatch<DispatchAction>) => ({
  setFilterValue: (field: string, value: unknown) => dispatch(setFilterValue(field, value)),
  setMultipleValues: (values: unknown) => dispatch(setMultipleValues(values)),
  setSmdFilterValue: (parentFieldId: string, field: string, value: unknown) => dispatch(setSmdFilterValue(parentFieldId, field, value)),
});
