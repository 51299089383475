import { useIntl } from 'react-intl';
import React, { FC, ReactNode } from 'react';
import { LanguageProvider } from '@cld/intl';
import { LinkButton } from '@clds/button-old';
import EmptyState, { CommonEmptyStateProps } from '../../EmptyState';
import { loadMessages, messages } from '../../i18n';
import noPreview from './noPreview.svg';

export interface NoPreviewProps extends CommonEmptyStateProps {
  /** Action button text */
  action?: ReactNode;
  /** Action button onClick handler */
  onActionClick?(): void;
  /** Temporary TS fix */
  actionText?: Record<string, string>;
}

const NoPreviewRaw: FC<NoPreviewProps> = ({ action, onActionClick, ...rest }) => {
  const { formatMessage } = useIntl();
  return (
    <EmptyState imageSrc={noPreview} imageHeight={112} imageWidth={96} title={formatMessage(messages.noPreviewTitle)} {...rest}>
      {action && (
        <LinkButton data-testid="no-preview-button" onClick={onActionClick}>
          {action}
        </LinkButton>
      )}
    </EmptyState>
  );
};

export const NoPreview: FC<React.PropsWithChildren<NoPreviewProps>> = (props) => (
  <LanguageProvider loadMessages={loadMessages}>
    <NoPreviewRaw {...props} data-test-specifier="no-preview" />
  </LanguageProvider>
);
