import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Typography } from '@clds/typography';
import { Combobox } from '@cld/combobox';
import { LightBulbOutline } from '@clds/icon';
import Progress from '@clds/progress';
import { Filter } from '../Filter/Filter';
import { MultiValueChipLabel } from '../FilterChipLabel/FilterChipLabel';
import { ProgressWrapper, StyledStartTyping, customStyles } from './MultiSelectFilter.styles';
import { MultiSelectGroupOptionType, MultiSelectOptionType } from './MultiSelectFilter.types';
import { getComponents } from './MultiSelectFilter.components';
import { messages } from '../../../i18n';
import { useIntl } from 'react-intl';

const ACTIONS = {
  INPUT_CHANGE: 'input-change',
  MENU_CLOSE: 'menu-close',
};

const DEBOUNCE_DELAY = 300;

const EMPTY_OPTIONS_LIST: MultiSelectGroupOptionType[] = [];

const createSelectedGroup = (options: MultiSelectOptionType[]) => [
  {
    label: 'Selected',
    options,
  },
];

const filterSelectedFromOptions = (options: MultiSelectOptionType[], selected: MultiSelectOptionType[]) => {
  return options.filter((option) => !selected.some((select) => select.value === option.value));
};

export interface AsyncMultiSelectFilterProps {
  values: MultiSelectOptionType[];
  onChange: (values: MultiSelectOptionType[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionCreator: (value: MultiSelectOptionType) => any;
  inputValue?: string;
  onClear?: () => void;
  options: MultiSelectGroupOptionType[];
  filterLabelPrefix: string;
  dataTest: string;
  hideLabelTooltip?: boolean;
  withFilterInput?: boolean;
  loadOptions: (inputValue: string) => void;
}

export const AsyncMultiSelectFilter = ({
  values,
  onChange,
  optionCreator,
  options,
  filterLabelPrefix,
  onClear,
  withFilterInput = false,
  dataTest,
  hideLabelTooltip = false,
  loadOptions,
}: AsyncMultiSelectFilterProps) => {
  const { formatMessage } = useIntl();
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [optionsCombobox, setOptionsCombobox] = useState(options);
  const shouldShowNoOptionsMessage = inputValue.length > 0 && !isLoading;

  const selectedValues = useMemo(() => {
    return values.map((value) => optionCreator(value));
  }, [values, optionCreator]);

  const onChangeHandler = useCallback(
    (selections: MultiSelectOptionType[]) => {
      onChange(selections);
    },
    [onChange]
  );

  const onClearHandler = () => {
    onClear?.();
    onChange([]);
  };

  useEffect(() => {
    const selectedGroup = createSelectedGroup(selectedValues);
    setOptionsCombobox(selectedGroup);
  }, [selectedValues]);

  useEffect(() => {
    if (!options || !options[0] || !selectedValues) {
      return;
    }
    const filteredSelectedOptions = filterSelectedFromOptions(options[0].options as MultiSelectOptionType[], selectedValues);
    const filteredGroupOptions = [{ label: options[0].label, options: filteredSelectedOptions }];
    const selectedGroup = createSelectedGroup(selectedValues);
    setOptionsCombobox([...selectedGroup, ...filteredGroupOptions] as MultiSelectGroupOptionType[]);
    setIsLoading(false);
  }, [options, selectedValues]);

  const debouncedLoadOptions = useDebouncedCallback((newInput: string) => {
    return loadOptions(newInput);
  }, DEBOUNCE_DELAY);

  const handleInputChange = useCallback(
    (value: string, { action }: { action: string }) => {
      if (action === ACTIONS.INPUT_CHANGE) {
        setIsLoading(true);
        //when typing in the search field
        setOptionsCombobox(EMPTY_OPTIONS_LIST);
        setInputValue(value);
        debouncedLoadOptions(value);
      } else if (action === ACTIONS.MENU_CLOSE) {
        //when closing the dropdown menu
        setInputValue('');
        debouncedLoadOptions('');
      }
    },
    [setInputValue, debouncedLoadOptions]
  );

  const onFilterOpen = useCallback(() => {
    if (options?.length < 1) {
      setIsLoading(true);
      loadOptions('');
    }
  }, [loadOptions, optionsCombobox]);

  const overlay = (
    <>
      <Combobox
        styles={customStyles}
        menuIsOpen
        isMulti
        options={optionsCombobox}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        value={selectedValues}
        // @ts-expect-error Silencing existing error, please fix it
        onChange={onChangeHandler}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        selectionVariant="subtle"
        components={getComponents(withFilterInput)}
        isClearable={false}
        placeholder={formatMessage(messages.filter)}
        onClickClearSelection={onClearHandler}
        backspaceRemovesValue={false}
        hideNoOptionErrorIconAndStyles={!shouldShowNoOptionsMessage}
        noOptionsMessage={() => (shouldShowNoOptionsMessage ? formatMessage(messages.noResults) : '')}
      />
      {isLoading && (
        <ProgressWrapper>
          <Progress.Circular variant="primary" size="sm" />
        </ProgressWrapper>
      )}
      {inputValue.length === 0 && !isLoading && (
        <StyledStartTyping>
          <LightBulbOutline color="contrastHigh" size="sm" />
          <Typography size="xs" type="lowContrast">
            {formatMessage(messages.startTyping)}
          </Typography>
        </StyledStartTyping>
      )}
    </>
  );

  const label = (
    <MultiValueChipLabel
      values={values.map((value) => value.value)}
      options={selectedValues}
      hasValue={!!values.length}
      filterLabelPrefix={filterLabelPrefix}
      hideLabelTooltip={hideLabelTooltip}
    />
  );

  return <Filter overlay={overlay} label={label} onClear={onClearHandler} hasValue={!!values.length} dataTest={dataTest} onOpen={onFilterOpen} />;
};
