import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { ModalCard, ModalContent, ModalFooter, ModalHeader } from '@clds/modal-old';
import { typography } from '@clds/typography';

export const Graphic = styled.img`
  margin-right: ${globalTheme.spacing.xs};
`;

export const StyledModalCard = styled(ModalCard)`
  background-color: ${globalTheme.palette.surfaceAlt};
`;

export const StyledModalHeader = styled(ModalHeader)`
  background-color: transparent;
  border-bottom: 1px solid ${globalTheme.palette.secondary};
`;

export const StyledModalFooter = styled(ModalFooter)`
  background-color: transparent;
  border-top: 1px solid ${globalTheme.palette.secondary};
`;

export const StyledModalContent = styled(ModalContent)`
  background-color: transparent;
  padding: ${globalTheme.spacing.lg};
  display: flex;
  align-items: center;
  gap: ${globalTheme.spacing.md};

  ${typography.sm.overlay};
  color: ${globalTheme.palette.contrastLow};
`;
