import React, { ComponentProps, FC } from 'react';
import { MultiValueProps } from 'react-select';
import { Option } from 'react-select/src/filters';
import styled from 'styled-components';
import { combineValues, useMixin } from '@clds/component-theme';
import Chip, { ChipProps, chipTheme } from '@clds/chip';
import Tooltip, { Placement, TooltipThemeProps } from '@clds/tooltip';
import { Cross } from '@clds/icon';
import { comboboxTheme } from '../theme';
import { ComboboxProps, ComboboxSize } from '../types';

const StyledChip = styled(Chip)<ComponentProps<typeof Chip> & { comboboxSize: ComboboxSize }>`
  margin: ${combineValues(comboboxTheme.multiValue.margin, (value, { comboboxSize }) => value[comboboxSize])};
  &:not(:nth-last-child(2)) {
    // -2 is last chip, -1 is input
    margin-right: 0;
    margin-bottom: 0;
  }
  max-width: calc(100% - ${chipTheme.rightSlotSpacing});
`;

const StyledIcon = styled(Cross)`
  cursor: pointer;
`;

export const MultiValue: FC<React.PropsWithChildren<MultiValueProps<Option>>> = ({ data, removeProps, ...rest }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const comboboxSize = (rest.selectProps as ComboboxProps<Option>).size!;
  const chipSize = useMixin(comboboxTheme.multiValue.chip.size[comboboxSize]) as ChipProps['size'];
  const placement = useMixin(comboboxTheme.tooltip.placement) as Placement;
  const maxWidth = useMixin(comboboxTheme.tooltip.maxWidth) as TooltipThemeProps['maxWidth'];
  const CustomValueLabel = (rest.selectProps as ComboboxProps<Option>).components?.MultiValueLabel;

  return (
    <StyledChip
      comboboxSize={comboboxSize}
      size={chipSize}
      data-testid="multi-value"
      data-test-specifier="root-chip"
      label={
        CustomValueLabel ? (
          <CustomValueLabel data={data} {...rest} />
        ) : (
          <Tooltip title={data.label} placement={placement} maxWidth={maxWidth}>
            <Tooltip.OverflowTrigger data-testid="chip-value">{data.label}</Tooltip.OverflowTrigger>
          </Tooltip>
        )
      }
      rightSlot={<StyledIcon data-test="remove-chip" {...removeProps} size="xs" />}
    />
  );
};
