import { createTheme, globalTheme } from '@clds/component-theme';
import { ComboboxSize } from './types';

const { palette, spacing } = globalTheme;

/**
 * Use this function to make sure you provide values for all theme size variants
 */
const bySize = <T extends { [key in ComboboxSize]: unknown }>(value: T) => value;

export const comboboxTheme = createTheme({
  background: {
    color: {
      base: palette.surfaceAlt,
    },
  },
  border: {
    color: {
      base: palette.secondaryAlt,
      error: palette.error,
      focus: palette.primary,
      hover: palette.primary,
      success: palette.success,
      warning: palette.warning,
      disabled: palette.secondary,
    },
    radius: globalTheme.radius.lg,
    width: '1px',
  },
  input: {
    color: {
      base: palette.contrastHigh,
      placeholder: palette.secondaryAlt,
    },
    padding: {
      all: bySize({ md: spacing.xs, sm: spacing.xxs }),
      left: bySize({ md: spacing.xs, sm: spacing.xxs }),
    },
  },
  outline: {
    color: {
      base: palette.primary,
    },
  },
  minHeight: bySize({ sm: '1.75rem', md: '2.5rem' }),
  typographySize: bySize({ sm: 'xs', md: 'md' }),
  multiValue: {
    chip: {
      size: bySize({ sm: 'sm', md: 'md' }),
    },
    margin: bySize({ sm: spacing.xxs, md: spacing.xs }),
  },
  singleValue: {
    marginLeft: bySize({ md: spacing.sm, sm: spacing.xs }),
  },
  clearIcon: {
    margin: {
      right: spacing.sm,
    },
    color: palette.contrastLow,
    iconSize: bySize({ md: 'md', sm: 'sm' }),
  },
  dropdownIndicator: {
    margin: {
      right: spacing.sm,
    },
    color: palette.contrastLow,
  },
  menu: {
    background: {
      color: palette.surfaceAlt,
    },
  },
  tooltip: {
    placement: 'topRight',
    maxWidth: 'lg',
  },
});
