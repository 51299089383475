import React, { SyntheticEvent } from 'react';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import { Assign } from 'utility-types';
import { CommonFieldProps } from '@clds/common-definitions';
import { supportFieldLineLayout } from '@clds/toolbar-layout';
import { useAdaptedProps } from './hooks/useAdaptedProps';
import { useSelectProps } from './hooks/useSelectProps';
import { AsyncComboboxProps } from './types';

const StyledSelect = styled(AsyncSelect)`
  min-width: 200px;

  ${supportFieldLineLayout('.cb__control')};
`;

/**
 * A component to pick one value from the list with no option to create new value.
 * To allow user create new values, use AsyncComboboxCreatable
 */
export const AsyncCombobox = <T,>(props: AsyncComboboxProps<T>) => {
  const finalProps = useSelectProps(props);

  return <StyledSelect {...finalProps} />;
};

export type AsyncComboboxFieldProps<T> = Assign<AsyncComboboxProps<T>, CommonFieldProps<T, SyntheticEvent>>;

/**
 * AsyncCombobox with props adjusted to CommonFieldProps
 */
export const AsyncComboboxField = <T,>(props: AsyncComboboxFieldProps<T>) => {
  const comboboxProps = useAdaptedProps(props);
  return <AsyncCombobox {...comboboxProps} />;
};
