import React, { ReactNode, createContext, memo, useCallback } from 'react';
import { ENVS, getConfEnvName } from '@cld/env-config';
import { getDefaultCloudinaryAnalyticsParams } from './utils';
import { sendCloudinaryAnalytics } from '../../api/analytics';

// eslint-disable-next-line @typescript-eslint/require-await
export const CloudinaryTrackerContext = createContext<typeof sendCloudinaryAnalytics | (() => Promise<boolean>)>(async () => {
  if (getConfEnvName() === ENVS.PRODUCTION) {
    // TODO: report error to an error reporting service
    return false;
  } else {
    throw new Error('No Provider for the CloudinaryTrackerContext');
  }
});

export const CloudinaryTrackerProvider = memo(<T = unknown,>({ children, ...defaultParams }: { children: ReactNode; defaultParams: T }) => {
  const tracker = useCallback(
    (additionalParams: { eventName: string }) =>
      sendCloudinaryAnalytics({ ...getDefaultCloudinaryAnalyticsParams(), ...defaultParams, ...additionalParams }),
    [defaultParams]
  );

  return <CloudinaryTrackerContext.Provider value={tracker}>{children}</CloudinaryTrackerContext.Provider>;
});

CloudinaryTrackerProvider.displayName = 'CloudinaryTrackerProvider';
