import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { TextField } from '@clds/text-field-old';
import { HorizontalSeparator } from '@clds/separator';

export const StyledLabel = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  svg {
    margin-right: ${globalTheme.spacing.sm};
  }
`;

export const StyledOption = styled.div`
  div {
    cursor: pointer;
  }
`;

export const StyledOptionLabel = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledSearchInput = styled(TextField)`
  flex: 1;
`;

export const StyledHorizontalSeparator = styled(HorizontalSeparator)`
  width: calc(100% + 2 * ${globalTheme.spacing.xs});
`;

export const StyledControl = styled.div`
  position: relative;
  > .cb__value-container {
    position: unset;
  }
`;

export const customStyles = {
  control: () => ({
    display: 'none',
  }),
  menu: () => ({
    boxShadow: 'none',
    width: '240px',
  }),
  placeholder: () => ({
    display: 'none',
  }),
};

export const StyledSearchInputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: ${globalTheme.spacing.xl};
  margin: ${globalTheme.spacing.xs};
  gap: ${globalTheme.spacing.xs};
`;

export const StyledStartTyping = styled.div`
  display: flex;
  align-items: center;
  gap: ${globalTheme.spacing.xs};
  padding: ${globalTheme.spacing.xs};
  border-top: 1px solid ${globalTheme.palette.secondary};
`;

export const ProgressWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${globalTheme.spacing.xs};
`;
