import React, { MouseEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import Popover from '@clds/popover';
import { Asset, isRawAsset } from '@cld/console-apps-types';
import { messages } from '../../../i18n';
import { useCloudinaryTracker } from '../../CloudinaryTrackerProvider/hooks';
import { SharedPreset } from '../../../types';
import { PopoverOverlay } from './PopoverOverlay';
import { ItemIcon } from './ItemIcon';
import { getAssetTrackingParams } from '../../CloudinaryTrackerProvider/utils';
import { StyledItemOverlayContainer, Title } from './ItemOverlay.styles';
import Tooltip from '@clds/tooltip';

const PopoverWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopoverIcon = styled.div.attrs({ 'data-test': 'popover-icon' })`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${globalTheme.spacing.sm};
`;

export interface ItemOverlayProps {
  presets: SharedPreset[];
  onClick: () => void;
  onDownload: (assets: Asset[] | Asset) => void;
  preventDownload?: boolean;
  asset: Asset;
  trackingAssetPosition: number;
}

export const ItemOverlay = ({ presets, preventDownload, onClick, onDownload, asset, trackingAssetPosition }: ItemOverlayProps) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const cloudinaryTracker = useCloudinaryTracker();
  const assetName = asset.displayName || asset.filename;

  const handleMouseLeave = () => setIsPopoverVisible(false);
  const handleTooltipVisibleChange = (visible: boolean) => setIsTooltipVisible(!isPopoverVisible && visible);

  const hasPresets = presets?.length > 0;
  const canDownload = isRawAsset(asset) || hasPresets || !preventDownload;
  const showPopoverMenu = isRawAsset(asset) || canDownload;
  const handleMenuIconClick = (e: MouseEvent) => {
    e.stopPropagation();
    setIsTooltipVisible(false);
  };

  const handleItemClick = (e?: MouseEvent) => {
    e?.stopPropagation();
    onDownload(asset);
    void cloudinaryTracker({
      eventName: 'collection_asset_menu_download_intent_click',
      ...getAssetTrackingParams({ asset: { ...asset, trackingAssetPosition } }),
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const getTooltipContainer = menuButtonRef.current ? () => menuButtonRef.current! : undefined;
  return (
    <StyledItemOverlayContainer onClick={onClick} data-test-specifier="item-overlay-container">
      <PopoverWrapper onMouseLeave={handleMouseLeave} data-test-specifier="popover-wrapper">
        {showPopoverMenu && (
          <Popover
            destroyTooltipOnHide
            getTooltipContainer={getTooltipContainer}
            onVisibleChange={(isVisible: boolean) => {
              if (isVisible) {
                void cloudinaryTracker({
                  eventName: 'collection_asset_menu_click',
                  ...getAssetTrackingParams({ asset: { ...asset, trackingAssetPosition } }),
                });
              }
              setIsPopoverVisible(isVisible);
            }}
            overlay={<PopoverOverlay handleItemClick={handleItemClick} />}
            paperProps={{ isPadded: false }}
            placement="bottom"
            trigger="click"
            visible={isPopoverVisible}
          >
            <PopoverIcon onClick={handleMenuIconClick} ref={menuButtonRef}>
              <ItemIcon handleTooltipVisibleChange={handleTooltipVisibleChange} isTooltipVisible={isTooltipVisible} message={messages.menu} />
            </PopoverIcon>
          </Popover>
        )}
        <Title>
          <Tooltip maxWidth="lg" placement="bottomRight" title={assetName}>
            <Tooltip.OverflowTrigger>{assetName}</Tooltip.OverflowTrigger>
          </Tooltip>
        </Title>
      </PopoverWrapper>
    </StyledItemOverlayContainer>
  );
};
