import { getProperties } from '@cld/console-apps-services';
import { validateResponseStatus } from '../helpers';
import { SearchSuggestionsType } from '../../components/SearchFilters/types';
import { publicBaseUrl } from '../api.constants';
import { SearchSuggestionsDto, searchSuggestionsSchema } from './schema';

export const fetchAutocomplete = async (value: string, property: string, metadataFieldId?: string) => {
  const { customerId, resourceId: publicLinkId } = getProperties();

  if (!(publicLinkId && customerId)) {
    throw new Error('corrupted properties');
  }
  const queryString = `term=${value}&property=${property}${metadataFieldId ? `&metadata_field_id=${metadataFieldId}` : ''}`;
  const collectionInfoUrl = `${publicBaseUrl}/${customerId}/${publicLinkId}/search_suggestions?${queryString}`;
  const response: Response = await fetch(collectionInfoUrl);

  validateResponseStatus(response);

  const data = (await response.json()) as Promise<unknown>;
  return parseSearchSuggestion(data);
};

export const fetchTags = async (value: string) => {
  const response = await fetchAutocomplete(value, 'tags');
  return response.tags;
};

export const fetchMetadataOptions = async (value: string, metadataFieldId: string) => {
  const response = await fetchAutocomplete(value, 'metadata_field_options', metadataFieldId);
  return response.metadataFieldOptions;
};

const parseSearchSuggestion = (rawData: unknown): SearchSuggestionsType => {
  const dto: SearchSuggestionsDto = searchSuggestionsSchema.validateSync(rawData);
  return {
    tags: dto.tags,
    metadataFieldOptions: dto.metadataFieldOptions,
  };
};
