import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { usePhone } from '@clds/style-utils';
import { Asset, isRawAsset } from '@cld/console-apps-types';
import { useAssets } from '../AssetGrid/VirtualizedGrid/VirtualizedGrid.hooks';
import { GRID_MAX_COLUMNS } from '../AssetGrid/CollectionGrid.consts';
import { Content } from './Content';
import { Header } from './Header';
import { Navigable } from './Navigable';
import { useSearchTotalsSelector } from '../AssetGrid/VirtualizedGrid/VirtualizedGrid.selectors';
import { StyledPreviewWrapper } from './Preview.styles';
import { PreviewPane } from '../PreviewPane/PreviewPane';

const Layout = styled.div.attrs({ 'data-test': 'preview' })`
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
`;

export interface PreviewProps {
  previewedAssetIndex: number | null;
  setPreviewedAssetIndex: (value: null | ((value: number) => number)) => void;
  onModalDownload: (asset: Asset) => void;
  isDownloadEnabled: boolean;
}

export const Preview: FC<PreviewProps> = ({ previewedAssetIndex, setPreviewedAssetIndex, onModalDownload, isDownloadEnabled }) => {
  const { allFetchedAssets, fetchNextPage, hasNextPage, isFetchingNextPage } = useAssets();
  const { totalCount = 0 } = useSearchTotalsSelector();
  const isMobile = usePhone({ maxOnly: true });

  const isFirstAsset = previewedAssetIndex === 0;
  const isLastAsset = previewedAssetIndex === totalCount - 1;

  const handleClose = useCallback(() => {
    setPreviewedAssetIndex(null);
  }, [setPreviewedAssetIndex]);

  const handleNext = useCallback(() => {
    if (Number(previewedAssetIndex) >= allFetchedAssets.length - GRID_MAX_COLUMNS && hasNextPage && !isFetchingNextPage) {
      void fetchNextPage();
    }
    if (!hasNextPage && isLastAsset) {
      return;
    }
    setPreviewedAssetIndex((previewedAssetIndex) => previewedAssetIndex + 1);
  }, [previewedAssetIndex, setPreviewedAssetIndex, allFetchedAssets, hasNextPage, isLastAsset, fetchNextPage, isFetchingNextPage]);

  const handlePrevious = useCallback(() => {
    if (Number(previewedAssetIndex) > 0) {
      setPreviewedAssetIndex((previewedAssetIndex) => previewedAssetIndex - 1);
    }
  }, [previewedAssetIndex, setPreviewedAssetIndex]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (previewedAssetIndex === null) {
        return;
      }
      if (event.key === 'ArrowLeft') {
        handlePrevious();
      } else if (event.key === 'ArrowRight') {
        handleNext();
      } else if (event.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClose, handleNext, handlePrevious, previewedAssetIndex]);

  if (previewedAssetIndex === null) {
    return null;
  }

  const asset = allFetchedAssets[previewedAssetIndex];

  return (
    <Layout>
      <Header
        asset={asset}
        onClose={handleClose}
        onModalDownload={onModalDownload}
        trackingAssetPosition={previewedAssetIndex + 1}
        isDownloadEnabled={isDownloadEnabled}
      />
      <StyledPreviewWrapper>
        <Navigable
          canNavigateToPrevious={!isFirstAsset}
          canNavigateToNext={!isLastAsset}
          onNext={handleNext}
          onPrevious={handlePrevious}
          color={isRawAsset(asset) ? 'background' : 'contrastHigh'}
        >
          <Content asset={asset} trackingAssetPosition={previewedAssetIndex + 1} />
        </Navigable>
        {!isMobile && <PreviewPane asset={asset} />}
      </StyledPreviewWrapper>
    </Layout>
  );
};
