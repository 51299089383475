import React, { FC, MouseEvent, useCallback } from 'react';
import { Calendar } from 'dayzed';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Typography } from '@clds/typography';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@clds/icon';
import { Button } from '@clds/button-old';
import { useMonthName } from '../hooks';
import { GetForwardProps } from '../types';

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${globalTheme.spacing.xs};

  ${Typography} {
    flex-grow: 1;
    white-space: nowrap;
    text-align: center;
  }
`;

interface HeaderProps {
  calendars: Calendar[];
  calendar: Calendar;
  getBackProps: GetForwardProps;
  getForwardProps: GetForwardProps;
}

const Header: FC<React.PropsWithChildren<HeaderProps>> = ({ calendars, calendar, getBackProps, getForwardProps }) => {
  const monthNamesShort = useMonthName();

  const { onClick: goBack, ...backProps } = getBackProps({ calendars });
  const { onClick: goForward, ...forwardProps } = getForwardProps({ calendars });

  const onBackClick = useCallback(
    (e: MouseEvent) => {
      goBack(e);
    },
    [goBack]
  );

  const onForwardClick = useCallback(
    (e: MouseEvent) => {
      goForward(e);
    },
    [goForward]
  );

  return (
    <StyledHeader>
      <Button {...backProps} onClick={onBackClick} shape="pill" size="md" variant="secondary" tone="plain" tabIndex={0}>
        <KeyboardArrowLeft />
      </Button>
      <Typography size="lg" type="regular">
        {monthNamesShort[calendar.month]} {calendar.year}
      </Typography>
      <Button {...forwardProps} onClick={onForwardClick} shape="pill" size="md" variant="secondary" tone="plain" tabIndex={0}>
        <KeyboardArrowRight />
      </Button>
    </StyledHeader>
  );
};

export default Header;
