import React from 'react';
import { ItemLayout } from '@clds/item';
import { MenuItem } from '@clds/menu-old';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../i18n';
import { StyledMenu } from './CollectionGrid.styles';
import { SortField } from './CollectionGridSort';

interface CollectionGridSortOverlayProps {
  sortItems: SortField[];
  onItemClick: (item: SortField) => void;
}

export const CollectionGridSortOverlay = ({ sortItems, onItemClick }: CollectionGridSortOverlayProps) => {
  return (
    <StyledMenu variant={{ selectionVariant: 'subtle', borderRadius: 'md' }}>
      {sortItems.map((item) => (
        <MenuItem key={item} onClick={() => onItemClick(item)} data-test="grid-sort-overlay" size="sm">
          <ItemLayout>
            <FormattedMessage {...messages[item]} />
          </ItemLayout>
        </MenuItem>
      ))}
    </StyledMenu>
  );
};
