/* istanbul ignore file */
import React, { FC } from 'react';
import { Button, ButtonProps } from '@clds/button-old';
import { Language } from '@clds/icon';

export const LanguageSelectorButton: FC<React.PropsWithChildren<ButtonProps>> = ({ children, ...rest }) => (
  <Button size="sm" variant="secondary" tone="surface" {...rest} leftSlot={<Language />}>
    {children}
  </Button>
);
