import styled from 'styled-components';
import Chip from '@clds/chip';
import { globalTheme } from '@clds/component-theme';
import { Typography } from '@clds/typography';
import CollapsiblePanel, { StyledToggleButton } from '@cld/collapsible-panel';

const PREVIEW_PANE_WIDTH = 480;

export const StyledTypographyHeader = styled(Typography)`
  padding: 0 ${globalTheme.spacing.md};
`;

export const StyledDetailsWrapper = styled.div`
  padding: 0 ${globalTheme.spacing.md};
`;

export const StyledDetailsRowWrapper = styled.div`
  padding: ${globalTheme.spacing.sm} 0;
  display: grid;
  grid-column-gap: ${globalTheme.spacing.sm};
  grid-template-columns: 1fr 2fr;
`;

export const StyledSideBarContainer = styled.div`
  height: 100%;
  width: ${PREVIEW_PANE_WIDTH}px;
`;

export const StyledCollapsiblePanel = styled(CollapsiblePanel)`
  background-color: ${globalTheme.palette.surface};

  ${StyledToggleButton} {
    z-index: 100;
    bottom: 15px;
    left: -25px;
    top: revert;
    border: none;
    border-radius: ${globalTheme.radius.lg};
    background-color: transparent;
    width: 25px;
    box-shadow: unset;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: ${globalTheme.palette.secondary};
    }
    &:focus:not(:hover) {
      background-color: transparent;
    }
  }
`;

export const StyledChip = styled(Chip)`
  margin-right: ${globalTheme.spacing.xs};
  margin-bottom: ${globalTheme.spacing.xs};
`;
