import { CldFiltersActions, CldFiltersContextState, CldFiltersProvider, ClearAllButton } from '../../cld-filters';
import { INITIAL_VALUES, STATIC_SEARCH_FILTERS_ORDER, SET_QUERY_DEBOUNCE_DELAY } from './SearchFilters.constants';
import { isNil } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { StyledFreeSearchWrapper, StyledSearchFiltersContainer } from './SearchFilters.styles';
import {
  QUERY_URL_PREFIX,
  SEARCH_FILTERS_CONFIG,
  buildLuceneQuery,
  getFilterComponent,
  getFilterNameFromFilterDefinition,
  getUrlQueryParams,
  setUrlQueryParams,
} from './SearchFilters.helpers';
import { SearchFilterValuesType, SearchFiltersNames } from './types';
import { useCloudinaryTracker } from '../CloudinaryTrackerProvider/hooks';
import { useDebouncedCallback } from 'use-debounce';
import { messages } from '../../i18n';
import { useIntl } from 'react-intl';
import { useSearchDispatch } from '../../context/SearchContext/SearchContext.hooks';
import { setFiltersLuceneQueryString } from '../../context/SearchContext/SearchContext.actions';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';
import { deleteQueryParam } from '../../helpers/url.helpers';
import { usePhone } from '@clds/style-utils';
import { SearchFiltersMobile } from './SearchFiltersMobile';
import { FreeSearch } from './Filters/FreeSearch';
import { CollectionGridSort } from '../AssetGrid/CollectionGridSort';
import { StyledSpacer } from '../AssetGrid/CollectionGrid.styles';
import { FilterDefinition } from '../../types';

export const SearchFilters = () => {
  const [initialValues, setInitialValues] = useState<SearchFilterValuesType>();
  const dispatch = useSearchDispatch();
  const collectionInfo = useCollectionInfoContext();
  const { formatMessage } = useIntl();
  const isPhone = usePhone();
  const cloudinaryTracker = useCloudinaryTracker();

  const handleFilterInteract = (field: string, fieldValue: unknown) => {
    const eventParams = { field, fieldValue };
    void cloudinaryTracker({
      eventName: 'interact_with_field',
      ...eventParams,
    });
  };

  const handleClear = (field: string) => {
    const eventParams = { field };
    void cloudinaryTracker({
      eventName: 'clear_field',
      ...eventParams,
    });
  };

  const debouncedHandleFiltersLuceneQueryChange = useDebouncedCallback(
    (luceneQuery) => dispatch(setFiltersLuceneQueryString(luceneQuery)),
    SET_QUERY_DEBOUNCE_DELAY
  );

  const handleChange = useCallback(
    ({ values, isPopulated, populatedValues }: CldFiltersContextState<SearchFilterValuesType>) => {
      const luceneQuery = buildLuceneQuery(values, collectionInfo);
      debouncedHandleFiltersLuceneQueryChange(luceneQuery);
      isPopulated ? setUrlQueryParams<SearchFilterValuesType>(populatedValues) : setUrlQueryParams();
    },
    [collectionInfo, debouncedHandleFiltersLuceneQueryChange]
  );

  const handleClearAll = ({ setMultipleValues }: CldFiltersActions) => {
    setMultipleValues(INITIAL_VALUES);
    deleteQueryParam(QUERY_URL_PREFIX);
  };

  useEffect(() => {
    const initialValuesFromQueryParams = getUrlQueryParams<SearchFilterValuesType>() || INITIAL_VALUES;
    setInitialValues(initialValuesFromQueryParams);
  }, []);

  const renderSearchComponents = () => {
    return (
      <>
        {STATIC_SEARCH_FILTERS_ORDER.map((filterName) => {
          // @ts-expect-error TODO: fix this
          const { FilterComponent } = SEARCH_FILTERS_CONFIG[filterName];
          return <FilterComponent key={filterName} onFilterInteract={handleFilterInteract} onClear={handleClear} />;
        }).filter((filter) => !isNil(filter))}
        {collectionInfo.allowedSearchFields
          ?.map((filter) => {
            const FilterComponent = getFilterComponent(filter);
            if (!FilterComponent) {
              return <></>;
            }
            return (
              <FilterComponent
                key={`${filter.property}-${filter.externalId}`}
                onFilterInteract={handleFilterInteract}
                onClear={handleClear}
                filterDefinition={filter}
              />
            );
          })
          .filter((filter) => !isNil(filter))}
      </>
    );
  };
  return (
    <>
      {initialValues && (
        <CldFiltersProvider<SearchFilterValuesType> initialValues={initialValues} unpopulatedValues={INITIAL_VALUES} onChange={handleChange}>
          {isPhone ? (
            <SearchFiltersMobile onFilterInteract={handleFilterInteract} onClear={handleClear} onClearAll={handleClearAll} />
          ) : (
            <>
              <StyledFreeSearchWrapper>
                <FreeSearch onFilterInteract={handleFilterInteract} onClear={handleClear} />
              </StyledFreeSearchWrapper>
              <StyledSearchFiltersContainer>
                {renderSearchComponents()}
                <ClearAllButton onClick={handleClearAll}>{formatMessage(messages.clearAll)}</ClearAllButton>
                <StyledSpacer />
                <CollectionGridSort />
              </StyledSearchFiltersContainer>
            </>
          )}
        </CldFiltersProvider>
      )}
    </>
  );
};
