import { useEffect, useRef } from 'react';
import { TRANSITION_TIME } from './shared/consts';

export const useFullyFloatingPanel = (
  isPanelFloatedOpen: boolean,
  onFloatChange?: (isOpen: boolean) => void,
) => {
  const hoverStart = useRef(0);
  const floatingPanelTimeoutId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!onFloatChange) {
      return;
    }

    if (isPanelFloatedOpen) {
      hoverStart.current = Date.now();
      floatingPanelTimeoutId.current = setTimeout(
        () => onFloatChange(true),
        TRANSITION_TIME,
      );
    } else if (hoverStart.current) {
      if (Date.now() - hoverStart.current >= TRANSITION_TIME) {
        onFloatChange(false);
      } else if (floatingPanelTimeoutId.current) {
        clearTimeout(floatingPanelTimeoutId.current);
      }
    }

    return () => {
      if (floatingPanelTimeoutId.current) {
        clearTimeout(floatingPanelTimeoutId.current);
      }
    };
  }, [isPanelFloatedOpen, onFloatChange]);
};
