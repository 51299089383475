import { getProperties } from '@cld/console-apps-services';
import { useQuery } from '@tanstack/react-query';
import * as yup from '@cld/yup-extra';
import { publicBaseUrl } from '../../api/api.constants';

const validateResponseStatus = (response: Response) => {
  if (response?.status >= 500) {
    throw new Error('delivery failure');
  }
  if (response?.status >= 400) {
    throw new Error('collection not found');
  }
};

interface Totals {
  totalImages: number;
  totalVideos: number;
}

export const totalsDataSchema = yup
  .object({
    totalImages: yup.number().required(),
    totalVideos: yup.number().required(),
  })
  .camelCase()
  .noUnknown()
  .required();

export const parseTotals = (rawData: unknown): Totals => {
  // Yup schema also converts to camel case
  const searchDataDTO: Totals = totalsDataSchema.validateSync(rawData);

  // Convert DTO to UI's Type
  const totalsData: Totals = {
    totalImages: searchDataDTO.totalImages,
    totalVideos: searchDataDTO.totalVideos,
  };

  return totalsData;
};

const fetchTotals = async () => {
  const { customerId, resourceId: publicLinkId } = getProperties();

  const requestUrl = `${publicBaseUrl}/${customerId}/${publicLinkId}/aggregations`;
  const response: Response = await fetch(requestUrl);
  validateResponseStatus(response);
  const data = (await response.json()) as Promise<Totals>;
  const parsedData = parseTotals(data);
  return parsedData;
};

export const useTotalsForDownload = () => {
  return useQuery({ queryKey: ['aggregations'], queryFn: fetchTotals });
};
