import React, { FC } from 'react';
import { fromAccountId } from '@cld/customer-logo';
import { useIsDarkTheme } from '../Theme/CollectionTheme.hooks';
import { StyledCloudinaryLogo, StyledCustomerLogo } from './CollectionHeaderItem.styles';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';
import { COLLECTION_KINDS } from '../../types';

export interface CollectionLogoProps {
  encryptedAccountId: string;
}

export const CollectionLogo: FC<React.PropsWithChildren<CollectionLogoProps>> = ({ encryptedAccountId }) => {
  const collectionInfo = useCollectionInfoContext();
  const isDarkTheme = useIsDarkTheme();
  const accountLogoUrl = fromAccountId(encryptedAccountId, isDarkTheme ? 'collection_cloudinary_logo_white.svg' : 'collection_cloudinary_logo_blue.svg');
  const isTempCollection = collectionInfo.collectionKind === COLLECTION_KINDS.TEMP;
  if (collectionInfo?.preferences?.logo?.deliveryUrl) {
    return <StyledCustomerLogo src={collectionInfo?.preferences?.logo?.deliveryUrl} size="lg" fallback={accountLogoUrl} />;
  }
  if (isTempCollection) {
    return <StyledCloudinaryLogo size="xl" />;
  }
  return <StyledCustomerLogo src={accountLogoUrl} size="lg" />;
};
