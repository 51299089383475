import isEqual from 'react-fast-compare';
import { CldFiltersConfig, CldFiltersContextState } from '../types';

export const getCldFiltersInitialContext = <Values>(props: CldFiltersConfig<Values>): CldFiltersContextState<Values> => {
  const { initialValues, unpopulatedValues } = props;

  return {
    initialValues,
    unpopulatedValues,
    values: initialValues ?? unpopulatedValues,
    isDirty: false,
    // At init, if we don't have initialValues then unpopulatedValues is the initialValues
    isPopulated: initialValues ? !isEqual(initialValues, unpopulatedValues) : true,
    populatedValues: getPopulatedValues<Values>(initialValues, unpopulatedValues),
  };
};

export const getPopulatedValues = <Values>(values: Values, unpopulatedValues: Values): Values => {
  return Object.fromEntries(
    Object.entries(values).filter(([key, value]) => {
      const unpopulatedValue = unpopulatedValues[key as keyof typeof unpopulatedValues];
      return !isEqual(value, unpopulatedValue);
    })
  ) as Values;
};
