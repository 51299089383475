import React from 'react';
import { isEmpty } from 'lodash';
import { Paper } from '@clds/paper';
import { RadioGroup } from '@clds/radio';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Filter } from '../Filter/Filter';
import { ValueChipLabel } from '../FilterChipLabel/FilterChipLabel';
import { GroupLayout, InlineFieldLayout, Label } from '@clds/form';
import { StyledWrapper } from './SingleSelectFilter.styles';

const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
`;

export const StyledRadio = styled(RadioGroup.Radio)`
  margin-right: ${globalTheme.spacing.sm};
`;

interface SingleSelectFilterProps {
  filterLabelPrefix: string;
  onChange: (value: string) => void;
  onClear: () => void;
  dataTest: string;
  label: string;
  value: string;
  options: { value: string; label: React.ReactNode }[];
}

const getHtmlId = (filterLabelPrefix: string, value: string) => `${filterLabelPrefix.toLowerCase().replace(/\s+/g, '-')}-${value}`;

export const SingleSelectFilter: React.FC<SingleSelectFilterProps> = (props: SingleSelectFilterProps) => {
  const Overlay = (
    <StyledPaper isPadded depth="none">
      <RadioGroup value={props.value} onChange={props.onChange}>
        <GroupLayout>
          {props.options.map(({ value, label }) => {
            const htmlId = getHtmlId(props.filterLabelPrefix, value);
            return (
              <InlineFieldLayout key={value} label={<Label htmlFor={htmlId}>{label}</Label>}>
                <StyledRadio id={htmlId} value={value} inputProps={{ 'data-test-specifier': htmlId }} />
              </InlineFieldLayout>
            );
          })}
        </GroupLayout>
      </RadioGroup>
    </StyledPaper>
  );
  const labelComp = <ValueChipLabel value={props.label} filterLabelPrefix={props.filterLabelPrefix} hasValue={!isEmpty(props.label)} />;
  return (
    <StyledWrapper>
      <Filter label={labelComp} hasValue={!isEmpty(props.label)} onClear={props.onClear} overlay={Overlay} dataTest={props.dataTest} />
    </StyledWrapper>
  );
};
