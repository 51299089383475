import React, { useMemo, useState } from 'react';
import { TextField } from '@clds/text-field-old';
import { useIntl } from 'react-intl';
import { messages } from '../../../../i18n';
import { OperatorFilter, OperatorOptionType, useCldFilters, SmdOperatorType, OperatorFilterValue } from '../../../../cld-filters';
import { SearchFilterValuesType, SearchFiltersNames, SmdFilterComponentBasePropsType } from '../../types';
import { SMD_TEXT_OPERATOR_KEYS, SMD_TEXT_OPERATORS } from './SmdText.constants';
import { getUpdatedSmdText } from './SmdText.helpers';

export const SmdText = ({ filterDefinition, onFilterInteract, onClear }: SmdFilterComponentBasePropsType) => {
  const { values, setFilterValue } = useCldFilters<SearchFilterValuesType>();
  const { externalId, label } = filterDefinition;
  const { formatMessage } = useIntl();

  const smdTextValue: SmdOperatorType | null = values[SearchFiltersNames.SmdText];
  const currentSmdFieldValue: OperatorFilterValue | undefined = smdTextValue?.[externalId as keyof typeof smdTextValue];
  const isAnyOrEmpty =
    currentSmdFieldValue && (currentSmdFieldValue.operator == SMD_TEXT_OPERATOR_KEYS.EMPTY || currentSmdFieldValue.operator == SMD_TEXT_OPERATOR_KEYS.ANY);
  const currentText = currentSmdFieldValue?.value || '';
  const hasValue = isAnyOrEmpty || !!currentText;
  // The reason to manage 2 states for operator (one in this state, and the second in clfFilters context)
  // is so user can change the operator, without affecting the URL or the query
  const [selectedOperator, setSelectedOperator] = useState<string>(currentSmdFieldValue?.operator || SMD_TEXT_OPERATOR_KEYS.CONTAINS);

  const smdTextOperatorsOptions = useMemo(() => {
    return Object.keys(SMD_TEXT_OPERATORS).map((key) => {
      const { value, translationId } = SMD_TEXT_OPERATORS[key as keyof typeof SMD_TEXT_OPERATORS];
      return {
        value,
        label: formatMessage(messages[translationId as keyof typeof messages]),
      };
    });
  }, [formatMessage]);

  const handleOperatorChange = (operator: OperatorOptionType) => {
    setSelectedOperator(operator.value);
    const updatedValue = getUpdatedSmdText({ smdTextValue, externalIdToUpdate: externalId, operator: operator.value, value: currentText });
    setFilterValue(SearchFiltersNames.SmdText, updatedValue);
    onFilterInteract(SearchFiltersNames.SmdText, updatedValue);
  };

  const handleTextInputChange = (value: string) => {
    const updatedValue = getUpdatedSmdText({ smdTextValue, externalIdToUpdate: externalId, operator: selectedOperator, value });
    setFilterValue(SearchFiltersNames.SmdText, updatedValue);
    onFilterInteract(SearchFiltersNames.SmdText, updatedValue);
  };

  const handleClear = () => {
    const updatedValue = getUpdatedSmdText({ smdTextValue, externalIdToUpdate: externalId, operator: SMD_TEXT_OPERATOR_KEYS.CONTAINS, value: '' });
    setFilterValue(SearchFiltersNames.SmdText, updatedValue);
    setSelectedOperator(SMD_TEXT_OPERATOR_KEYS.CONTAINS);
    onClear(SearchFiltersNames.SmdText);
  };

  const getValueLabel = () => {
    const selectedOperator: OperatorOptionType | undefined = smdTextOperatorsOptions.find((operator) => operator.value === currentSmdFieldValue?.operator);
    if (isAnyOrEmpty) {
      return selectedOperator?.label;
    } else {
      return currentText ? `${selectedOperator?.label?.toLowerCase()} ${currentText}` : '';
    }
  };

  return (
    <OperatorFilter
      operators={smdTextOperatorsOptions}
      value={selectedOperator}
      onChange={handleOperatorChange}
      filterLabelPrefix={label}
      valueLabel={getValueLabel()}
      hasValue={hasValue}
      onClear={handleClear}
      renderFieldComponent={
        isAnyOrEmpty ? null : (
          <TextField
            type="text"
            placeholder={formatMessage(messages.typeToFilter)}
            value={currentText}
            onChange={handleTextInputChange}
            isDisabled={isAnyOrEmpty}
          />
        )
      }
    />
  );
};
