import React from 'react';
import { Typography } from '@clds/typography';
import { formatBytes } from '@cld/format-bytes';
import AssetType from '@cld/asset-type';
import { Asset, isImageAsset, isVideoAsset } from '@cld/console-apps-types';
import { smdValue } from '../../types';
import { StyledChip } from './previewPane.styles';
import { SMD_FIELD_TYPES } from '../../cld-filters/constants';
import { formatStringDateToLabel } from '../SearchFilters/Filters/SmdDate/SmdDate.helpers';

export const detailsLogic = [
  {
    titleKey: 'displayName',
    value: (asset: Asset) => asset.displayName || asset.filename,
  },
  {
    titleKey: 'format',
    value: ({ format, type }: Asset) => <AssetType type={type} format={format} />,
  },
  {
    titleKey: 'fileSize',
    value: ({ bytes }: Asset) => {
      const { value, unit } = formatBytes(bytes);
      return `${value} ${unit}`;
    },
  },
  {
    titleKey: 'resolution',
    value: (asset: Asset) => {
      const isImage = isImageAsset(asset);
      const isVideo = isVideoAsset(asset);
      return isImage || isVideo ? `${asset.width} x ${asset.height}` : null;
    },
  },
];
export const propertyLogic = {
  tags: {
    titleKey: 'tags',
    value: ({ tags }: Asset) => {
      return tags && tags.length ? tags.map((tag: string | null | undefined) => <StyledChip key={tag} label={tag} />) : '-';
    },
  },
  metadata: {
    titleKey: 'metadata',
    value: (asset: Asset, type: string, externalId: string) => {
      switch (type) {
        case SMD_FIELD_TYPES.ENUM:
        case SMD_FIELD_TYPES.SET: {
          const { metadata } = asset as Asset & { metadata: Record<string, smdValue | smdValue[]> };
          const field = metadata && metadata[externalId];
          if (Array.isArray(field)) {
            return field.length ? field.map(({ value }) => <StyledChip key={value} label={value} />) : '-';
          }
          return field?.value || '-';
        }
        case SMD_FIELD_TYPES.DATE: {
          const { metadata } = asset as Asset & { metadata: Record<string, smdValue | string> };
          const field = (metadata && metadata[externalId]) as string;
          return (
            <Typography size="sm" type="highContrast">
              {formatStringDateToLabel(field) || '-'}
            </Typography>
          );
        }
        case SMD_FIELD_TYPES.INTEGER:
        case SMD_FIELD_TYPES.STRING: {
          const { metadata } = asset as Asset & { metadata: Record<string, smdValue | string> };
          const field = (metadata && metadata[externalId]) as string;
          return (
            <Typography size="sm" type="highContrast">
              {field || '-'}
            </Typography>
          );
        }
      }
    },
  },
};
