import { SingleSelectFilter, useCldFilters } from '../../../../cld-filters';
import React from 'react';
import { messages } from '../../../../i18n';
import { FormattedMessage, useIntl } from 'react-intl';
import { TIME_OPTIONS } from './DateCreated.constants';
import { FilterComponentBasePropsType, SearchFiltersNames } from '../../types';

const options = [TIME_OPTIONS.TODAY, TIME_OPTIONS.LAST7, TIME_OPTIONS.LAST30, TIME_OPTIONS.LAST90, TIME_OPTIONS.LAST180].map((option: string) => ({
  value: option,
  label: <FormattedMessage {...messages[option as keyof typeof messages]} />,
}));

const DateCreated = ({ onFilterInteract, onClear }: FilterComponentBasePropsType) => {
  const { values, setFilterValue } = useCldFilters();
  const { formatMessage } = useIntl();

  const handleChange = (value: string) => {
    setFilterValue(SearchFiltersNames.DateCreated, value);
    onFilterInteract(SearchFiltersNames.DateCreated, value);
  };

  const handleClear = () => {
    setFilterValue(SearchFiltersNames.DateCreated, '');
    onClear(SearchFiltersNames.DateCreated);
  };

  const getValueLabel = () => {
    return options.find((option) => option.value === values[SearchFiltersNames.DateCreated])?.label.props.defaultMessage;
  };

  return (
    <SingleSelectFilter
      filterLabelPrefix={formatMessage(messages.creationDate)}
      dataTest="date-created"
      onChange={handleChange}
      onClear={handleClear}
      options={options}
      label={getValueLabel()}
      value={values[SearchFiltersNames.DateCreated]}
    />
  );
};

export { DateCreated };
