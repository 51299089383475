import { Asset, Collection } from '@cld/console-apps-types';
import { PresetTypeEnum } from './components/DowloadPresetsModal/types';

export interface SharedPreset {
  name: string;
  presetTypes: PresetTypeEnum[];
  transformation: string;
}

export interface FeatureFlags {
  localizationEnabled: boolean;
  folderDecouplingEnabled: boolean;
}

export enum PropertyEnum {
  TAGS = 'tags',
  METADATA = 'metadata',
}

export interface smdValue {
  external_id: string;
  value: string;
}

export interface FilterDefinition {
  property: string;
  externalId: string;
  type?: string;
  label?: string;
}

export type CollectionWithoutAssets = Omit<Collection, 'assets'>;

export const COLLECTION_KINDS = Object.freeze({ CLASSIC: 'classic', DYNAMIC: 'dynamic', TEMP: 'temp' });
export type CollectionKind = typeof COLLECTION_KINDS.CLASSIC | typeof COLLECTION_KINDS.DYNAMIC | typeof COLLECTION_KINDS.TEMP;

export interface CollectionInfoType {
  id: string;
  collectionKind: CollectionKind;
  collection: CollectionWithoutAssets;
  customLogoPath: string;
  lastModifiedAt: string | null;
  createdAt: string;
  linkExpiredAt: string;
  downloadAssetsLimit: number;
  totalCountTruncated: boolean;
  totalCount: number;
  sharedPresets: Array<SharedPreset> | [];
  flags: FeatureFlags;
  preferences?: {
    locale?: string | null;
    preventDownloadOriginal?: boolean;
    logo: {
      deliveryUrl?: string | null;
    };
    theme: {
      primaryColor?: string | null;
      primaryColorDark?: string | null;
    };
  };
  owner: {
    firstName?: string;
    lastName?: string;
    status?: string;
  } | null;
  allowedSearchFields: Array<FilterDefinition> | [] | null | undefined;
}

export type AssetWithDimensions = Asset & { height: number; width: number };
