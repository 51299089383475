import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { Typography } from '@clds/typography';
import { CheckCircle } from '@clds/icon';
import { Item } from '@clds/item';
import { Paper } from '@clds/paper';
import { ModalCard, ModalContent, ModalHeader } from '@clds/modal-old';
import { TextSkeleton } from '@cld/skeleton';
import { InfoOutline } from '@clds/icon';
import { blenderBackground } from '@clds/blender';

export const MEDIA_QUERIES_MAX = Object.freeze({
  MD: 'screen and (min-width: 785px)',
  SM: 'screen and (max-width: 576px)',
});

export const StyledModalWrapper = styled(ModalCard)`
  @media ${MEDIA_QUERIES_MAX.MD} {
    height: 650px;
    width: 500px;
  }
  @media ${MEDIA_QUERIES_MAX.SM} {
    height: 100%;
    width: 380px;
  }
`;

export const StyledModalHeader = styled(ModalHeader)`
  background-color: ${globalTheme.palette.surfaceAlt};
  border-bottom: 1px solid ${globalTheme.palette.secondary};
`;

export const StyledModalHeaderItems = styled.span`
  display: flex;
  align-items: center;
  gap: ${globalTheme.spacing.sm};
`;

export const StyledModalContent = styled(ModalContent)`
  padding: ${globalTheme.spacing.lg};
  display: flex;
  flex-direction: column;
  min-height: 280px;

  ${Typography} {
    display: block;
    margin-bottom: ${globalTheme.spacing.lg};
    margin-top: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledCheckCircle = styled(CheckCircle)`
  margin-right: ${globalTheme.spacing.xs};
`;

export const StyledPaper = styled(Paper)`
  max-height: 400px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${globalTheme.palette.secondary};
  }
`;

export const StyledItem = styled(Item)`
  margin-bottom: ${globalTheme.spacing.sm};
`;

export const StyledTextSkeleton = styled(TextSkeleton)`
  height: 14px;
  > span > span {
    margin: 0;
  }
`;

export const StyledThumbnailContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  & > div:first-child {
    width: 60px;
    height: 40px;
    margin-right: ${globalTheme.spacing.sm};
  }
  & img {
    width: 60px;
  }
`;

export const StyledPresetHeaderTypography = styled(Typography)`
  margin-top: ${globalTheme.spacing.sm} !important;
  margin-bottom: 0 !important;
`;

export const StyledTypography = styled(Typography)`
  margin: 0 !important;
`;

export const StyledSingleDownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const StyleAlertContainer = styled.div`
  display: flex;
  align-items: center;
  padding: ${globalTheme.spacing.sm};
  border-radius: ${globalTheme.spacing.xxs};
  background-color: ${({ theme }) => blenderBackground({ theme, variant: 'primary', mode: 'plainContrast', intensity: 'sm' })};
`;

export const StyleInfoIcon = styled(InfoOutline)`
  margin-right: ${globalTheme.spacing.sm};
`;
