import React, { useState } from 'react';
import { Typography } from '@clds/typography';
import {
  StyledMobileDrawerContainer,
  StyledDrawerHeader,
  StyledDrawerFiltersContainer,
  StyledLeftSlot,
  StyledDrawer,
  StyledSearchFiltersContainer,
  StyledFreeSearchWrapper,
} from './SearchFilters.styles';
import { STATIC_SEARCH_FILTERS_ORDER } from './SearchFilters.constants';
import { SEARCH_FILTERS_CONFIG, getFilterNameFromFilterDefinition, getFilterComponent } from './SearchFilters.helpers';
import { FreeSearch } from './Filters/FreeSearch';
import { Badge, CldFiltersActions, ClearAllButton, useCldFilters } from '../../cld-filters';
import { useIntl } from 'react-intl';
import { messages } from '../../i18n';
import { Button } from '@clds/button-old';
import { Tune } from '@clds/icon';
import { SearchFiltersNames } from './types';
import { StyledSpacer } from '../AssetGrid/CollectionGrid.styles';
import { CollectionGridSort } from '../AssetGrid/CollectionGridSort';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';
import { isNil } from 'lodash';

interface SearchFiltersMobileProps {
  onFilterInteract: (field: string, fieldValues: unknown) => void;
  onClear: (field: string) => void;
  onClearAll: (actions: CldFiltersActions, event: React.MouseEvent<Element, MouseEvent>) => void;
}

export const SearchFiltersMobile = ({ onFilterInteract, onClear, onClearAll }: SearchFiltersMobileProps) => {
  const { populatedValues } = useCldFilters();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { formatMessage } = useIntl();
  const collectionInfo = useCollectionInfoContext();
  const { [SearchFiltersNames.FreeSearch]: freeSearchValue, ...populatedValuesWithoutFreeSearch } = populatedValues;
  const populatedFieldsCount = Object.keys(populatedValuesWithoutFreeSearch).length;

  const renderDrawer = () => (
    <StyledDrawer open onBackdropClick={() => setIsMobileMenuOpen(false)} position="bottom">
      <StyledMobileDrawerContainer>
        <StyledDrawerHeader>
          <Typography size="sm" type="lowContrast" as="p">
            {formatMessage(messages.filters)}
          </Typography>
          <ClearAllButton onClick={onClearAll}>{formatMessage(messages.clearAll)}</ClearAllButton>
        </StyledDrawerHeader>
        <StyledDrawerFiltersContainer>
          {STATIC_SEARCH_FILTERS_ORDER.map((filterName) => {
            // @ts-expect-error Index type interference error on SEARCH_FILTERS_CONFIG
            const { FilterComponent } = SEARCH_FILTERS_CONFIG[filterName];
            return <FilterComponent key={filterName} onFilterInteract={onFilterInteract} onClear={onClear} />;
          })}
          {collectionInfo.allowedSearchFields
            ?.map((filter) => {
              const filterName = getFilterNameFromFilterDefinition(filter);
              const FilterComponent = getFilterComponent(filter);
              if (!FilterComponent) {
                return <></>;
              }
              return (
                <FilterComponent
                  key={`${filter.property}-${filter.externalId}`}
                  onFilterInteract={onFilterInteract}
                  onClear={onClear}
                  filterDefinition={filter}
                />
              );
            })
            .filter((filter) => !isNil(filter))}
        </StyledDrawerFiltersContainer>
      </StyledMobileDrawerContainer>
    </StyledDrawer>
  );

  return (
    <>
      <StyledFreeSearchWrapper>
        <FreeSearch onFilterInteract={onFilterInteract} onClear={onClear} />
      </StyledFreeSearchWrapper>
      <StyledSearchFiltersContainer>
        <Button
          size="sm"
          variant="secondary"
          onClick={() => setIsMobileMenuOpen(true)}
          data-test-specifier="open-mobile-filters"
          leftSlot={
            <StyledLeftSlot>
              <Tune />
              {!!populatedFieldsCount && <Badge>{populatedFieldsCount}</Badge>}
            </StyledLeftSlot>
          }
        >
          {formatMessage(messages.filters)}
        </Button>
        <ClearAllButton onClick={onClearAll}>{formatMessage(messages.clearAll)}</ClearAllButton>
        {isMobileMenuOpen && renderDrawer()}
        <StyledSpacer />
        <CollectionGridSort />
      </StyledSearchFiltersContainer>
    </>
  );
};
