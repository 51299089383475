export const GRID_MAX_COLUMNS = 6;
export const BASE_IMAGE_WIDTH = 320;
export const BASE_IMAGE_HEIGHT = 223;
export const IMAGE_ASPECT_RATIO = BASE_IMAGE_HEIGHT / BASE_IMAGE_WIDTH;
export const CAPTION_HEIGHT = 30;
export const CARD_PADDING = 20;
export const CARD_WIDTH = 320;
export const CARD_HEIGHT = IMAGE_ASPECT_RATIO * CARD_WIDTH + CAPTION_HEIGHT;
export const SORT_FIELD_QUERY_PARAM = 'sortField';
export const SORT_DIRECTION_QUERY_PARAM = 'sortDirection';
