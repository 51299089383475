import { useIntl } from 'react-intl';
import React, { FC } from 'react';
import CloudinaryFooter from '@cld/footer';
import { messages } from '../../i18n';
import { Metadata } from './Metadata';
import { ACTIVE_USER_STATUS } from '../../api/collectionInfo/schema';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';
import { formatDate } from '../App.helpers';
import { COLLECTION_KINDS } from '../../types';

const Footer: FC = () => {
  const { formatMessage } = useIntl();
  const collectionInfo = useCollectionInfoContext();

  const createdBy = collectionInfo.owner;
  const lastUpdated = collectionInfo.lastModifiedAt || '';
  const linkExpiredAt = collectionInfo.linkExpiredAt || '';

  const isTempCollection = collectionInfo.collectionKind === COLLECTION_KINDS.TEMP;
  const shouldShowCreatedBy = createdBy && (createdBy.status === ACTIVE_USER_STATUS || !createdBy.status) && !isTempCollection;

  const { firstName = '', lastName = '' } = createdBy || {};
  // add space only if both first and last names are present
  const createdByValue = `${firstName}${firstName && lastName && ' '}${lastName}`;

  const createdByMetadata = {
    key: formatMessage(messages.metadataCreatedBy),
    value: createdByValue,
  };

  const lastUpdatedMetadata = {
    key: formatMessage(messages.metadataLastUpdated),
    value: formatDate(lastUpdated),
  };

  const linkExpiredAtMetadata = {
    key: formatMessage(messages.metadataLinkExpiredAt),
    value: formatDate(linkExpiredAt),
  };

  const metadata = [
    ...(shouldShowCreatedBy ? [createdByMetadata] : []),
    ...(!isTempCollection ? [lastUpdatedMetadata] : []),
    ...(linkExpiredAt ? [linkExpiredAtMetadata] : []),
  ];

  return (
    <CloudinaryFooter>
      <Metadata metadata={metadata} />
    </CloudinaryFooter>
  );
};

export default Footer;
