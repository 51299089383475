import { Asset, isAudioAsset, isImageAsset, isVideoAsset } from '@cld/console-apps-types';
import React from 'react';
import { ThumbnailPlaceholder } from './ThumbnailPlaceholder';
import { StyledAssetCardContainer, StyledIconOverlay } from './AssetCard.styles';
import { Audio, IconSize, Play } from '@clds/icon';
import { globalTheme, useMixin } from '@clds/component-theme';
import { Thumbnail } from '../Thumbnail/Thumbnail';

interface AssetCardProps {
  asset: Asset;
  index: number;
  cardHeight: number;
  cardWidth?: number;
  renderAssetCardOverlay: (asset: Asset, index: number) => React.ReactNode;
  iconSize?: IconSize;
}

/**
 * @description
 * Takes care of of displaying an asset in a card
 * - Placeholder - If asset does not have a thumbnail
 * - Icon Overlay - for video and audio asset types
 * - Details Hover Overlay - Display asset name and preview indicator
 */
export const AssetCard = ({ asset, index, cardHeight, cardWidth = cardHeight, renderAssetCardOverlay, iconSize = 'xl' }: AssetCardProps) => {
  const iconColor = useMixin(globalTheme.palette.overlayLight);
  const isImage = isImageAsset(asset);
  const isVideo = isVideoAsset(asset);
  const isAudio = isAudioAsset(asset);
  const shouldShowThumbnail = isImage || isVideo || isAudio;
  const shouldShowIconOverlay = isAudio || isVideo;

  const renderVideoAudioIconOverlay = () => (
    <StyledIconOverlay>{isVideo ? <Play size={iconSize} color={iconColor} /> : <Audio size={iconSize} color={iconColor} />}</StyledIconOverlay>
  );

  return (
    <StyledAssetCardContainer cardHeight={cardHeight} cardWidth={cardWidth} data-test-specifier="asset-card-wrapper">
      {shouldShowThumbnail ? (
        <Thumbnail asset={asset} itemHeight={cardHeight} overlay={shouldShowIconOverlay && renderVideoAudioIconOverlay()} />
      ) : (
        <ThumbnailPlaceholder asset={asset} iconSize={iconSize} />
      )}
      {renderAssetCardOverlay(asset, index)}
    </StyledAssetCardContainer>
  );
};
