import { FileXml, File, Photo, Audio, Video } from '@clds/icon';
// import { AudioPlaceholder, JsonPlaceholder, PhotoPlaceholder, RawPlaceholder, VideoPlaceholder, ThreeD, ThreeDPlaceholder } from '../../icons';
import { Asset } from '@cld/console-apps-types';

export const resolveAssetIcon = (asset: Asset) => {
  const icons = {
    image: Photo,
    video: Video,
    audio: Audio,
    raw: File,
    json: FileXml,
  };

  const types = {
    raw: icons.raw,
    video: icons.video,
    audio: icons.audio,
    image: asset?.format === 'json' ? icons.json : icons.image,
  };

  return types[asset?.type as keyof typeof types] || types.image;
};
