import styled from 'styled-components';
import themes from '@clds/themes';
import { NumberField } from '@clds/number-field-old';
import { Paper } from '@clds/paper';
import { Typography } from '@clds/typography';
import SingleValueCombobox from '../shared/SingleValueCombobox';

export const StyledPaper = styled(Paper)`
  display: flex;
  align-items: center;
`;

export const StyledTextDivider = styled(Typography)`
  margin: ${themes.spacing.xxs};
`;

export const StyledSingleValueCombobox = styled(SingleValueCombobox)`
  min-width: 100px;
  margin-left: ${themes.spacing.xs};
`;

export const StyledNumberField = styled(NumberField)`
  width: 80px;
`;
