const strToBoolean = (str: string) => {
  return str === 'true';
};

/** Our component can either be controlled or uncontrolled.
We *can't* use defaultValue if it's controlled, but we need some default if it's uncontrolled.
Also, if it's uncontrolled and has a local / session storage value, use this one instead. **/
export const getDefaultValue = (
  valueFromStorage: string | null,
  defaultIsExpanded: boolean,
  isExpanded: boolean | undefined,
) => {
  // Component is controlled, so no need for a default
  if (typeof isExpanded === 'boolean') {
    return undefined;
  }
  // Component is uncontrolled and has a saved value from local storage
  if (typeof valueFromStorage === 'string') {
    return strToBoolean(valueFromStorage);
  }
  // Component is uncontrolled, so we return a default either passed by the user, or set by us.
  return defaultIsExpanded;
};
