import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { media } from '@clds/style-utils';
import { Button } from '@clds/button-old';
import { HeaderHeight } from './Header';

const SideButton = styled.div<{ isVisible?: boolean }>`
  position: absolute;
  top: 50%;
  align-items: center;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transform: translateY(-50%);
  z-index: 2;

  &,
  * {
    pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
  }
`;

export const StyledNextButton = styled(SideButton)`
  right: 0;
`;

export const StyledPrevButton = styled(SideButton)`
  left: 0;
`;

type GlobalThemePalette = keyof ReturnType<typeof globalTheme>['palette'];

export const Columns = styled.div<{ color: GlobalThemePalette }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: ${({ color }) => globalTheme.palette[color]};
  position: relative;
  @media ${`${media.desktop({ minOnly: true })}`} {
    ${SideButton} {
      display: none;
    }

    &:hover {
      ${SideButton} {
        display: block;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  margin: 0 ${globalTheme.spacing.sm};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div<{ isPhone: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${({ isPhone }) => (isPhone ? HeaderHeight.phone : HeaderHeight.desktop)}px);
`;
