import { SearchFilterValuesType, SearchFiltersNames } from './types';

export const ASSETS_GROUPS = Object.freeze({
  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
  TXT: 'doc',
  THREE_D: 'three_d',
});

export const SET_QUERY_DEBOUNCE_DELAY = 300;
export const STATIC_SEARCH_FILTERS_ORDER: Omit<SearchFiltersNames, SearchFiltersNames.FreeSearch>[] = [
  SearchFiltersNames.AssetTypes,
  SearchFiltersNames.Formats,
  SearchFiltersNames.Size,
  SearchFiltersNames.Dimensions,
  SearchFiltersNames.DateCreated,
];

export const INITIAL_VALUES: SearchFilterValuesType = {
  [SearchFiltersNames.FreeSearch]: '',
  [SearchFiltersNames.Formats]: [],
  [SearchFiltersNames.AssetTypes]: [],
  [SearchFiltersNames.Size]: { range: { min: '', max: '' } },
  [SearchFiltersNames.Dimensions]: [],
  [SearchFiltersNames.DateCreated]: '',
  [SearchFiltersNames.Tags]: [],
  [SearchFiltersNames.SmdMultiple]: {},
  [SearchFiltersNames.SmdText]: null,
  [SearchFiltersNames.SmdDate]: null,
  [SearchFiltersNames.SmdNumber]: null,
};
