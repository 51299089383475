export const SMD_TEXT_OPERATOR_KEYS = Object.freeze({
  CONTAINS: ':',
  EQUALS: '=',
  ANY: 'any',
  EMPTY: 'empty',
});

export const SMD_TEXT_OPERATORS = Object.freeze({
  [SMD_TEXT_OPERATOR_KEYS.CONTAINS]: { value: SMD_TEXT_OPERATOR_KEYS.CONTAINS, translationId: 'startsWith' },
  [SMD_TEXT_OPERATOR_KEYS.EQUALS]: { value: SMD_TEXT_OPERATOR_KEYS.EQUALS, translationId: 'equalTo' },
  [SMD_TEXT_OPERATOR_KEYS.ANY]: { value: SMD_TEXT_OPERATOR_KEYS.ANY, translationId: 'anyText' },
  [SMD_TEXT_OPERATOR_KEYS.EMPTY]: { value: SMD_TEXT_OPERATOR_KEYS.EMPTY, translationId: 'isEmpty' },
});
