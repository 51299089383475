import React from 'react';
import { Asset } from '@cld/console-apps-types';
import styled from 'styled-components';

const StyledIframe = styled.iframe`
  border: 0;
  height: 100%;
`;

export const PdfViewer = ({ asset }: { asset: Asset }) => {
  return <StyledIframe src={asset.urls.original} />;
};
