const CUSTOMER_LOGOS_DIR = 'console/customer-logos';
const getCustomerLogoBase = (customFallbackImg = 'cld_default_cloud_logo.svg') =>
  `https://res-s.cloudinary.com/prod/image/upload/d_console:${customFallbackImg}/w_32,h_32,c_fill,dpr_2.0`;

/**
 * Builds customer logo URL with default fallback based on the account id.
 * @param accountId of the customer
 * @param customFallbackImg custom fallback img src
 * @returns customer logo URL with default fallback
 */
export const fromAccountId = (accountId?: string, customFallbackImg?: string) => {
  const customerLogoBase = getCustomerLogoBase(customFallbackImg);
  return accountId ? `${customerLogoBase}/${CUSTOMER_LOGOS_DIR}/${accountId}` : customerLogoBase;
};

/**
 * Builds customer logo URL with default fallback based on logo path from the Demo account.
 * @param logoPath in the demo account
 * @param customFallbackImg custom fallback img src
 * @returns customer logo URL with default fallback
 */
export const fromPath = (logoPath?: string, customFallbackImg?: string) => {
  const customerLogoBase = getCustomerLogoBase(customFallbackImg);
  return logoPath ? `${customerLogoBase}/${logoPath}` : customerLogoBase;
};
