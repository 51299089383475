import { RangeType, cldQueryBuilders, standardQueryBuilders } from '../../cld-filters';
import { INITIAL_VALUES } from './SearchFilters.constants';
import { SearchFilterValuesType, SearchFiltersNames } from './types';
import qs, { ParsedQs } from 'qs';
import { TIME_OPTIONS_API_MAP } from './Filters/DateCreated/DateCreated.constants';
import { isEmpty } from 'lodash';
import { AssetTypes } from './Filters/AssetTypes/AssetTypes';
import { DateCreated } from './Filters/DateCreated/DateCreated';
import { Dimensions } from './Filters/Dimensions/Dimensions';
import { Formats } from './Filters/Formats/Formats';
import { SizeFilter } from './Filters/SizeFilter/SizeFilter';
import { Tags } from './Filters/Tags/Tags';
import { DIMENSIONS_TYPES } from './Filters/Dimensions/Dimensions.constants';
import { CollectionInfoType, FilterDefinition } from '../../types';
import { SizeFilterUnits } from './Filters/SizeFilter/SizeFilter.types';
import { deleteQueryParam, getQueryParam, upsertQueryParam } from '../../helpers/url.helpers';
import { SmdMultipleType } from './Filters/SmdMultiple/SmdMultiple.types';
import { SmdMultiple } from './Filters/SmdMultiple/SmdMultiple';
import { METADATA_PROPERTY_NAME, SMD_FIELD_TYPES } from '../../cld-filters/constants';
import { SmdText } from './Filters/SmdText/SmdText';
import { SmdDate } from './Filters/SmdDate/SmdDate';
import { SmdNumber } from './Filters/SmdNumber/SmdNumber';
import { SmdOperatorType } from '../../cld-filters/';

export const QUERY_URL_PREFIX = 'q';

export const buildSpecificFilterQuery = (fieldKey: string, fieldValue: unknown, collectionInfo: CollectionInfoType): string => {
  switch (fieldKey) {
    case SearchFiltersNames.FreeSearch: {
      const { flags } = collectionInfo;
      return cldQueryBuilders.assetName(fieldValue as string, !!flags?.folderDecouplingEnabled);
    }
    case SearchFiltersNames.Formats: {
      return cldQueryBuilders.formats(fieldValue as string[]);
    }
    case SearchFiltersNames.Dimensions: {
      const dimensionsValues = fieldValue as string[];
      const dimensionsQueryData = dimensionsValues.map((value) => DIMENSIONS_TYPES[value as keyof typeof DIMENSIONS_TYPES]);
      return cldQueryBuilders.dimensions(dimensionsQueryData);
    }

    case SearchFiltersNames.AssetTypes: {
      return cldQueryBuilders.assetTypes(fieldValue as string[]);
    }
    case SearchFiltersNames.DateCreated: {
      if (fieldValue) {
        return cldQueryBuilders.dateCreated(TIME_OPTIONS_API_MAP[fieldValue as string]);
      }
      return '';
    }
    case SearchFiltersNames.Size: {
      return cldQueryBuilders.size(fieldValue as RangeType<SizeFilterUnits>);
    }
    case SearchFiltersNames.Tags: {
      return cldQueryBuilders.tags(fieldValue as string[]);
    }
    case SearchFiltersNames.SmdMultiple: {
      return cldQueryBuilders.smdMultiple(fieldValue as SmdMultipleType);
    }
    case SearchFiltersNames.SmdText:
    case SearchFiltersNames.SmdNumber:
    case SearchFiltersNames.SmdDate: {
      return fieldValue ? cldQueryBuilders.operator(fieldValue as SmdOperatorType) : '';
    }
    default:
      return '';
  }
};

export const buildLuceneQuery = (values: SearchFilterValuesType, collectionInfo: CollectionInfoType) => {
  const fieldsKeys = Object.values(SearchFiltersNames);
  const queries: string[] = fieldsKeys.map((key) => buildSpecificFilterQuery(key, values[key], collectionInfo));
  return standardQueryBuilders.groupQueriesByAND(queries);
};

export const getUrlQueryParams = <Values>(): Values | null => {
  const { [QUERY_URL_PREFIX]: queryParam }: ParsedQs = qs.parse(window.location.search, {
    ignoreQueryPrefix: true, // This option removes the leading '?'
  });

  if (!queryParam) {
    return null;
  }
  try {
    const queryParams = JSON.parse(queryParam as string);
    return { ...INITIAL_VALUES, ...queryParams };
  } catch {
    return null;
  }
};

export const setUrlQueryParams = <QueryParamsType>(queryParams?: QueryParamsType) => {
  const currentQuery = getQueryParam(QUERY_URL_PREFIX);

  if (!queryParams || isEmpty(queryParams)) {
    deleteQueryParam(QUERY_URL_PREFIX);
    return;
  }

  const queryParamsJsonString = JSON.stringify(queryParams);
  if (currentQuery !== queryParamsJsonString) {
    upsertQueryParam(QUERY_URL_PREFIX, queryParamsJsonString);
  }
};

export const getFilterNameFromFilterDefinition = (field: FilterDefinition) => {
  const { property, type } = field;
  if (property === SearchFiltersNames.FreeSearch) return null;
  const isSmd = isSmdField(field);
  if (isSmd) {
    switch (type) {
      case SMD_FIELD_TYPES.SET:
      case SMD_FIELD_TYPES.ENUM:
        return SearchFiltersNames.SmdMultiple;
      case SMD_FIELD_TYPES.STRING:
        return SearchFiltersNames.SmdText;
      case SMD_FIELD_TYPES.DATE:
        return SearchFiltersNames.SmdDate;
      case SMD_FIELD_TYPES.INTEGER:
        return SearchFiltersNames.SmdNumber;
      default:
        break;
    }
  } else {
    return Object.values(SearchFiltersNames).find((a) => a === property) || null;
  }
  return null;
};

export const getFilterComponent = (filter: FilterDefinition) => {
  const filterName = getFilterNameFromFilterDefinition(filter);
  if (!filterName || filterName === SearchFiltersNames.FreeSearch) return null;
  const { FilterComponent } = Object.keys(SEARCH_FILTERS_CONFIG).includes(filterName) ? SEARCH_FILTERS_CONFIG[filterName] : { FilterComponent: undefined };
  if (!FilterComponent) {
    return null;
  }
  return FilterComponent;
};

export const SEARCH_FILTERS_CONFIG = {
  [SearchFiltersNames.AssetTypes]: {
    FilterComponent: AssetTypes,
  },
  [SearchFiltersNames.Formats]: {
    FilterComponent: Formats,
  },
  [SearchFiltersNames.Dimensions]: {
    FilterComponent: Dimensions,
  },
  [SearchFiltersNames.DateCreated]: {
    FilterComponent: DateCreated,
  },
  [SearchFiltersNames.Size]: {
    FilterComponent: SizeFilter,
  },
  [SearchFiltersNames.Tags]: {
    FilterComponent: Tags,
  },
  [SearchFiltersNames.SmdMultiple]: {
    FilterComponent: SmdMultiple,
  },
  [SearchFiltersNames.SmdText]: {
    FilterComponent: SmdText,
  },
  [SearchFiltersNames.SmdDate]: {
    FilterComponent: SmdDate,
  },
  [SearchFiltersNames.SmdNumber]: {
    FilterComponent: SmdNumber,
  },
};
const isSmdField = (field: FilterDefinition) => {
  return field.property === METADATA_PROPERTY_NAME;
};
