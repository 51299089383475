import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { ItemLayout } from '@clds/item';
import { Menu, MenuItem } from '@clds/menu-old';
import { FileDownload } from '@clds/icon';
import { messages } from '../../../i18n';

export interface PopoverOverlayProps {
  handleItemClick: (e?: MouseEvent) => void;
}

const StyledMenu = styled(Menu)`
  min-width: 180px;
`;

export const PopoverOverlay = ({ handleItemClick }: PopoverOverlayProps) => {
  return (
    <StyledMenu variant={{ selectionVariant: 'subtle', borderRadius: 'md' }}>
      <MenuItem onClick={handleItemClick} data-test="download-button" size="sm">
        <ItemLayout leftSlot={<FileDownload size="md" />}>
          <FormattedMessage {...messages.assetDownload} />
        </ItemLayout>
      </MenuItem>
    </StyledMenu>
  );
};
