import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@clds/typography';
import Banner from '@cld/banner';
import { getCurrentWebAddress } from '@cld/console-apps-services';
import { LanguageProvider } from '@cld/intl';
import { messages, loadMessages } from '../i18n';

export const RedirectedURLMessage: FC<React.PropsWithChildren<{ appName: string }>> = ({ appName }) => {
  const currentURL = new URL(getCurrentWebAddress());
  const wasRedirectedFromOldURL = currentURL.searchParams.get('wasRedirectedFromOldURL') === 'true';

  useEffect(() => {
    if (wasRedirectedFromOldURL) {
      const urlWithoutRedirectionParam = new URL(getCurrentWebAddress());
      urlWithoutRedirectionParam.searchParams.delete('wasRedirectedFromOldURL');
      window.history.replaceState(null, '', urlWithoutRedirectionParam.href);
    }
  }, [wasRedirectedFromOldURL]);

  if (wasRedirectedFromOldURL) {
    return (
      <LanguageProvider loadMessages={loadMessages}>
        <Banner variant="secondary">
          <Typography size="md" type="regular">
            <FormattedMessage
              {...messages.urlWasRedirected}
              values={{
                appName,
              }}
            />
          </Typography>
        </Banner>
      </LanguageProvider>
    );
  }

  return null;
};
