import React from 'react';
import { useIntl, MessageDescriptor } from 'react-intl';
import { usePhone, useTablet } from '@clds/style-utils';
import { globalTheme, useMixin } from '@clds/component-theme';
import { Tooltip } from '@clds/tooltip';
import { Icon } from '@cld/icon';

export interface ItemIconProps {
  message: MessageDescriptor;
  isTooltipVisible: boolean;
  handleTooltipVisibleChange: (visible: boolean) => void;
}

export const ItemIcon = ({ message, isTooltipVisible, handleTooltipVisibleChange }: ItemIconProps) => {
  const { formatMessage } = useIntl();
  const isPhone = usePhone();
  const isTablet = useTablet();
  const iconsColor = useMixin(globalTheme.color.white); // should stay white

  const IconMoreVert = <Icon name="moreVert" size="sm" color={iconsColor} data-test-specifier="options-icon" />;

  if (isPhone || isTablet) {
    return IconMoreVert;
  }

  return (
    <Tooltip onVisibleChange={handleTooltipVisibleChange} visible={isTooltipVisible} title={formatMessage(message)}>
      {IconMoreVert}
    </Tooltip>
  );
};
