import * as ACTION_TYPES from './SearchContext.actionTypes';
import { DispatchAction } from './SearchContext.types';

export const setFiltersLuceneQueryString = (filtersLuceneQueryString: string): DispatchAction => ({
  type: ACTION_TYPES.SET_FILTERS_LUCENE_QUERY_STRING,
  filtersLuceneQueryString,
});
export const setSortField = (sortField: string): DispatchAction => ({
  type: ACTION_TYPES.SET_SORT_FIELD,
  sortField,
});
export const setSortDirection = (sortDirection: string): DispatchAction => ({
  type: ACTION_TYPES.SET_SORT_DIRECTION,
  sortDirection,
});
