import React, { FC } from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { typography } from '@clds/typography';
import { Description } from '@cld/console-apps-types';

export const StyledDescription = styled.div`
  ${typography.md.highContrast};
  line-height: ${globalTheme.font.size.md};
  text-align: start;
  font-weight: initial;
  padding: ${globalTheme.spacing.md};
  padding-top: 0;
  max-width: 800px;
  p {
    margin: 0;
  }
`;

export interface CollectionDescriptionProps {
  description?: Description;
}

export const CollectionDescription: FC<React.PropsWithChildren<CollectionDescriptionProps>> = ({ description, ...rest }) => {
  const descriptionComponent = Array.isArray(description)
    ? description.map((element, index) => <div key={`description-${index}`}>{element}</div>)
    : description;

  return descriptionComponent ? <StyledDescription {...rest}>{descriptionComponent}</StyledDescription> : <></>;
};
