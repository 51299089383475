import { isEmpty } from 'lodash';
import { SMD_TEXT_OPERATOR_KEYS } from './SmdText.constants';
import { SmdOperatorType } from '../../../../cld-filters';

export const getUpdatedSmdFieldValue = (operator: string, value: string | undefined) => {
  const isAnyOrEmpty = operator == SMD_TEXT_OPERATOR_KEYS.EMPTY || operator == SMD_TEXT_OPERATOR_KEYS.ANY;
  if (isAnyOrEmpty) {
    return { operator };
  } else if (value) {
    return { operator, value };
  } else {
    return null;
  }
};

export const getUpdatedSmdText = ({
  smdTextValue,
  externalIdToUpdate,
  operator,
  value,
}: {
  smdTextValue: SmdOperatorType | null;
  externalIdToUpdate: string;
  operator: string;
  value: string | undefined;
}) => {
  const smdFieldValue = getUpdatedSmdFieldValue(operator, value);

  if (smdFieldValue) {
    return { ...(smdTextValue || {}), [externalIdToUpdate]: smdFieldValue };
  } else {
    if (!smdTextValue) return null;
    const { [externalIdToUpdate]: removedSmdField, ...updatedSmdTextValue } = smdTextValue;
    return isEmpty(updatedSmdTextValue) ? null : updatedSmdTextValue;
  }
};
