/* istanbul ignore file */
import { getProperties } from '@cld/console-apps-services';
import { CollectionInfoType, CollectionWithoutAssets, FeatureFlags, SharedPreset } from '../../types';
import { CollectionInfoDto, collectionInfoSchema } from './schema';
import { Description } from '@cld/console-apps-types';
import { WysiwygEditorNode, htmlSerializer } from '@cld/wysiwyg-editor';
import parse from 'html-react-parser';
import { validateResponseStatus } from '../helpers';
import { publicBaseUrl } from '../api.constants';

const normalizeOwner = ({ firstName, lastName, status }: { firstName?: string | null; lastName?: string | null; status?: string | null }) => {
  return firstName || lastName
    ? {
        firstName: firstName ? firstName : undefined,
        lastName: lastName ? lastName : undefined,
        status: status ? status : undefined,
      }
    : null;
};

const normalizeDescription = (rawDescription: CollectionInfoDto['description']) => {
  if (!rawDescription) {
    return undefined;
  }

  const serializedDescription = htmlSerializer(rawDescription as WysiwygEditorNode[]);
  // Parse converts string elements, e.g: '<div>blabla</div>' into React.Element,
  // which helps us to prevent the usage of dangerouslySetInnerHTML;
  const descriptionElements: Description = serializedDescription.map((elm: string) => parse(elm)).flat();
  return descriptionElements;
};

const parseCollectionInfo = (rawData: unknown): CollectionInfoType => {
  const dto: CollectionInfoDto = collectionInfoSchema.validateSync(rawData);

  const collection: CollectionWithoutAssets = {
    name: dto.name,
    description: normalizeDescription(dto.description),
    downloadUrl: dto.downloadUrl,
  };

  return {
    collection,
    id: dto.id,
    collectionKind: dto.collectionKind,
    downloadAssetsLimit: dto.downloadAssetsLimit,
    totalCount: dto.totalCount,
    totalCountTruncated: dto.totalCountTruncated,
    createdAt: dto.createdAt,
    // @ts-expect-error Silencing existing error, please fix it
    preferences: dto.preferences,
    customLogoPath: dto.customLogoPath,
    lastModifiedAt: dto.lastModifiedAt,
    sharedPresets: dto.sharedPresets as SharedPreset[],
    flags: dto.flags as FeatureFlags,
    // @ts-expect-error Silencing existing error, please fix it
    linkExpiredAt: dto.linkExpiredAt,
    owner: dto.owner ? normalizeOwner(dto.owner) : null,
    allowedSearchFields: dto.allowedSearchFields,
  };
};

export const fetchCollectionInfo = async () => {
  const { customerId, resourceId: publicLinkId } = getProperties();

  if (!(publicLinkId && customerId)) {
    throw new Error('corrupted properties');
  }

  const collectionInfoUrl = `${publicBaseUrl}/${customerId}/${publicLinkId}/collection_info`;
  const response: Response = await fetch(collectionInfoUrl);

  validateResponseStatus(response);

  const data = (await response.json()) as Promise<unknown>;
  return parseCollectionInfo(data);
};
