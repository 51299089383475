import React, { SyntheticEvent } from 'react';
import Creatable from 'react-select/creatable';
import styled from 'styled-components';
import { Assign } from 'utility-types';
import { CommonFieldProps } from '@clds/common-definitions';
import { supportFieldLineLayout } from '@clds/toolbar-layout';
import { useAdaptedProps } from './hooks/useAdaptedProps';
import { useSelectProps } from './hooks/useSelectProps';
import { ComboboxCreatableProps } from './types';
import { withSimpleValue } from './withSimpleValue';

const StyledCreatable = styled(Creatable)`
  ${supportFieldLineLayout('.cb__control')};
`;

/**
 * A component to pick one value from the list with ability to create new value.
 */
export const ComboboxCreatable = <T, IsMulti extends boolean>(props: ComboboxCreatableProps<T, IsMulti>) => {
  const finalProps = useSelectProps(props);

  return <StyledCreatable createOptionPosition="first" {...finalProps} />;
};

export type ComboboxCreatableFieldProps<T, IsMulti extends boolean> = Assign<ComboboxCreatableProps<T, IsMulti>, CommonFieldProps<T, SyntheticEvent>>;

/**
 * ComboboxCreatable with props adjusted to CommonFieldProps
 */
export const ComboboxCreatableField = <T, IsMulti extends boolean>(props: ComboboxCreatableFieldProps<T, IsMulti>) => {
  const comboboxProps = useAdaptedProps(props);
  return <ComboboxCreatable {...comboboxProps} />;
};

export const SimpleValueComboboxCreatableField = withSimpleValue(ComboboxCreatableField);
