export const DATE = 'MMM dd, yyyy';
export const RAW = 'yyyy-MM-dd';

export const SMD_DATE_OPERATOR_KEYS = Object.freeze({
  IS: '=',
  BEFORE: '<',
  AFTER: '>=',
  ANY: 'any',
  EMPTY: 'empty',
});

export const SMD_DATE_OPERATORS = Object.freeze({
  [SMD_DATE_OPERATOR_KEYS.IS]: { value: SMD_DATE_OPERATOR_KEYS.IS, translationId: 'is' },
  [SMD_DATE_OPERATOR_KEYS.BEFORE]: { value: SMD_DATE_OPERATOR_KEYS.BEFORE, translationId: 'before' },
  [SMD_DATE_OPERATOR_KEYS.AFTER]: { value: SMD_DATE_OPERATOR_KEYS.AFTER, translationId: 'after' },
  [SMD_DATE_OPERATOR_KEYS.ANY]: { value: SMD_DATE_OPERATOR_KEYS.ANY, translationId: 'anyDate' },
  [SMD_DATE_OPERATOR_KEYS.EMPTY]: { value: SMD_DATE_OPERATOR_KEYS.EMPTY, translationId: 'isEmpty' },
});
