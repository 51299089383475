/* istanbul ignore file */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import React, { FC, useEffect, useState } from 'react';
import { filter, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@clds/typography';
import { FileDownload, Info } from '@clds/icon';
import { Tooltip } from '@clds/tooltip';
import { ModalCloseIcon, ModalNext } from '@clds/modal-old';
import { downloadFile } from '@cld/file-download';
import { Asset, isRawAsset } from '@cld/console-apps-types';
import { messages } from '../../i18n';
import { useCloudinaryTracker } from '../CloudinaryTrackerProvider/hooks';
import {
  StyleAlertContainer,
  StyledModalContent,
  StyledModalHeader,
  StyledModalHeaderItems,
  StyledModalWrapper,
  StyleInfoIcon,
} from './DownloadPresetsModalStyled';
import { OriginalDownloadSection } from './OriginalDownloadSection';
import { PresetsSection } from './PresetsSection';
import { getAssetDisplayName, getFilteredPresets, getPresetAssets, hasRawAssets } from './utils';
import { SharedPreset } from '../../types';
import { getBuildDownloadUrl } from '../../api/download';
import { getAssetTrackingParams } from '../CloudinaryTrackerProvider/utils';
import { useSearchState } from '../../context/SearchContext/SearchContext.hooks';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';
import { useSearchTotalsSelector } from '../AssetGrid/VirtualizedGrid/VirtualizedGrid.selectors';
import { DownloadType } from '../Enums/Enum';

export interface DownloadPresetsModalProps {
  assets: Asset[] | Asset | null;
  isOpen: boolean;
  onClose: () => void;
  preventDownload: boolean;
  presets: SharedPreset[];
  publicLinkId: string;
  collectionId?: string;
  className?: string;
  trackingAssetPosition: number;
  downloadType: DownloadType;
}

export const DownloadPresetsModal: FC<React.PropsWithChildren<DownloadPresetsModalProps>> = ({
  preventDownload,
  assets,
  publicLinkId,
  presets,
  isOpen,
  onClose,
  collectionId,
  className,
  trackingAssetPosition,
  downloadType,
}) => {
  const [activeDownloadItems, setActiveDownloadItems] = useState<string[] | never[]>([]);
  const cloudinaryTracker = useCloudinaryTracker();
  const searchParams = useSearchState();
  const { totalCount } = useSearchTotalsSelector();

  const collectionInfo = useCollectionInfoContext();

  const searchCollectionCount = downloadType === DownloadType.All ? collectionInfo.totalCount : totalCount;
  const searchLuceneQueryString = downloadType === DownloadType.All ? '' : searchParams.filtersLuceneQueryString;

  useEffect(() => {
    if (!isOpen) {
      setActiveDownloadItems([]);
    }
  }, [isOpen, setActiveDownloadItems]);

  const cloudinaryTrack = (externalId?: string, presetName?: string, presetTypes?: string[]) => {
    if (!externalId) {
      const presetParam = presetName
        ? { eventName: 'collection_download_preset_click', preset_name: presetName, preset_type: presetTypes }
        : { eventName: 'collection_download_original_click' };
      void cloudinaryTracker({
        ...presetParam,
      });
    } else {
      const presetParam = presetName
        ? { eventName: 'collection_asset_download_preset_click', preset_name: presetName, preset_type: presetTypes }
        : { eventName: 'collection_asset_download_original_click' };
      void cloudinaryTracker({
        ...presetParam,
        // @ts-expect-error Silencing existing error, please fix it
        ...getAssetTrackingParams({ asset: { ...assets, trackingAssetPosition } }),
      });
    }
  };

  const onDownload = async (externalId?: string, presetName?: string, presetTypes?: string[]) => {
    cloudinaryTrack(externalId, presetName, presetTypes);
    // @ts-expect-error TODO: Fix this
    setActiveDownloadItems((activeDownloadItems) => [...activeDownloadItems, presetName]);
    const presetDownloadUrl = await getBuildDownloadUrl(publicLinkId, presetName, externalId, collectionId, searchLuceneQueryString);

    downloadFile({ src: presetDownloadUrl?.redirect });
  };

  let filteredAssets = assets;
  let displayOriginalDownloadSection;
  let displayPresetsSection;
  let presetsList;

  const isCollection = Array.isArray(assets);

  if (isCollection) {
    // @ts-expect-error Silencing existing error, please fix it
    filteredAssets = preventDownload ? hasRawAssets(assets) : assets;
    displayOriginalDownloadSection = !preventDownload || !isEmpty(hasRawAssets(assets));
    presetsList = getFilteredPresets(assets, presets);
    displayPresetsSection = getPresetAssets(assets) && !isEmpty(presetsList);
  } else {
    const singleAsset = assets;
    // @ts-expect-error Silencing existing error, please fix it
    displayOriginalDownloadSection = !preventDownload || isRawAsset(singleAsset);
    // @ts-expect-error Silencing existing error, please fix it
    presetsList = filter(presets, (prest) => prest.presetTypes?.includes(singleAsset?.type));
    // @ts-expect-error Silencing existing error, please fix it
    displayPresetsSection = !isRawAsset(singleAsset) && !isEmpty(presetsList);
  }

  const isMultipleAssets =
    totalCount && totalCount > 1 ? messages.downloadCollectionPresetsModalHeader : messages.downloadCollectionSinglePresetsModalHeader;
  const modalHeaderTitle = isCollection ? isMultipleAssets : messages.downloadSinglePresetsModalHeader;

  return (
    <ModalNext isOpen={isOpen} onClose={onClose} className={className} closeOnEsc>
      <StyledModalWrapper>
        <StyledModalHeader>
          <StyledModalHeaderItems>
            <FileDownload size="lg" />
            <FormattedMessage {...modalHeaderTitle} values={{ count: '' }} />
          </StyledModalHeaderItems>
          <ModalCloseIcon />
        </StyledModalHeader>
        <StyledModalContent>
          {displayOriginalDownloadSection ? (
            <OriginalDownloadSection
              preventDownload={preventDownload}
              downloadType={downloadType}
              // @ts-expect-error Silencing existing error, please fix it
              assets={filteredAssets}
              onOriginalDownload={onDownload}
            />
          ) : (
            <StyleAlertContainer>
              <StyleInfoIcon size="lg" color="plain" />
              <FormattedMessage {...messages.DisabledDownloadOriginalAssetsText} />
            </StyleAlertContainer>
          )}

          {displayPresetsSection && (
            <PresetsSection
              activePresets={activeDownloadItems}
              // @ts-expect-error Silencing existing error, please fix it
              assets={assets}
              presets={presetsList}
              onPresetDownload={onDownload}
              downloadType={downloadType}
            />
          )}
        </StyledModalContent>
      </StyledModalWrapper>
    </ModalNext>
  );
};
