import React, { useCallback, useMemo } from 'react';
import { Combobox } from '@cld/combobox';
import { Filter } from '../Filter/Filter';
import { MultiValueChipLabel } from '../FilterChipLabel/FilterChipLabel';
import { customStyles } from './MultiSelectFilter.styles';
import { MultiSelectGroupOptionType, MultiSelectOptionType } from './MultiSelectFilter.types';
import { getOptionsMap } from './MultiSelectFilter.helpers';
import { getComponents } from './MultiSelectFilter.components';
import { messages } from '../../../i18n';
import { useIntl } from 'react-intl';

export interface MultiSelectFilterProps {
  values: string[];
  onChange: (values: string[]) => void;
  onClear?: () => void;
  options: MultiSelectOptionType[] | MultiSelectGroupOptionType[];
  filterLabelPrefix: string;
  dataTest: string;
  hideLabelTooltip?: boolean;
  withFilterInput?: boolean;
}

export const MultiSelectFilter = ({
  values,
  onChange,
  options,
  filterLabelPrefix,
  onClear,
  withFilterInput = false,
  dataTest,
  hideLabelTooltip = false,
}: MultiSelectFilterProps) => {
  const { formatMessage } = useIntl();
  const optionsMap = useMemo(() => getOptionsMap(options), [options]);

  const selectedValues = useMemo(() => {
    return values.map((value) => optionsMap.get(value));
  }, [values, optionsMap]);

  const onChangeHandler = useCallback(
    (selections: MultiSelectOptionType[]) => {
      onChange(selections?.map((selection) => selection.value));
    },
    [onChange]
  );

  const onClearHandler = () => {
    onClear?.();
    onChange([]);
  };

  const overlay = (
    <Combobox
      styles={customStyles}
      menuIsOpen
      isMulti
      options={options}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      value={selectedValues}
      // @ts-expect-error Silencing existing error, please fix it
      onChange={onChangeHandler}
      selectionVariant="subtle"
      components={getComponents(withFilterInput)}
      isClearable={false}
      placeholder={formatMessage(messages.filter)}
      onClickClearSelection={onClearHandler}
      backspaceRemovesValue={false}
    />
  );

  const label = (
    <MultiValueChipLabel
      values={values}
      options={selectedValues}
      filterLabelPrefix={filterLabelPrefix}
      hideLabelTooltip={hideLabelTooltip}
      hasValue={!!values.length}
    />
  );

  return <Filter overlay={overlay} label={label} onClear={onClearHandler} hasValue={!!values.length} dataTest={dataTest} />;
};
