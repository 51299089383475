import React from 'react';
import { Combobox as CLDCombobox, ComboboxCreatable } from '@cld/combobox';

interface SingleValueCombobox {
  isCreatable: boolean | undefined;
  value: string;
  options: { value: string; label: string }[];
}

/**
 Custom SingleValue Combobox that saves the trouble of passing a find function for each and every instance of combobox
 */
const SingleValueCombobox = ({ isCreatable, value, options, ...rest }: SingleValueCombobox) => {
  const selectedValue = value === null ? value : options.find((option) => option.value === value) || undefined;
  const ComboboxComponent = isCreatable ? ComboboxCreatable : CLDCombobox;
  return <ComboboxComponent value={selectedValue} options={options} {...rest} />;
};

export default SingleValueCombobox;
