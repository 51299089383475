import styled, { CSSObject } from 'styled-components';
import React, { FC } from 'react';
import { NoticeProps } from 'react-select/src/components/Menu';
import { OptionTypeBase } from 'react-select';
import { globalTheme, useMixin } from '@clds/component-theme';
import { typography } from '@clds/typography';
import { Error } from '@clds/icon';

const Root = styled.div`
  ${typography.xs.regular};
  display: flex;
  align-items: center;
  padding: ${globalTheme.spacing.xs};
  // Temp fix caused by react versions mismatch
  ${Error as unknown as CSSObject} {
    margin-right: ${globalTheme.spacing.xxs};
  }
`;

export const StyledErrorIcon = styled(Error)`
  margin-right: ${globalTheme.spacing.xs};
`;

export const NoOptionsMessage = <IsMulti extends boolean>(props: React.PropsWithChildren<NoticeProps<OptionTypeBase, IsMulti>>) => {
  const color = useMixin(globalTheme.palette.errorAlt);
  return props.selectProps.hideNoOptionErrorIconAndStyles ? (
    props.selectProps.noOptionsMessage?.({ inputValue: props.selectProps.inputValue || '' })
  ) : (
    <Root data-testid="combobox-no-options-message">
      <StyledErrorIcon size="md" color={color} />
      {props.selectProps.noOptionsMessage?.({ inputValue: props.selectProps.inputValue || '' })}
    </Root>
  );
};
