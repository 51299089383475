import React, { FC, memo } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { globalTheme } from '@clds/component-theme';
import { typography } from '@clds/typography';
import { Description } from '@cld/console-apps-types';
import { messages } from '../../i18n';
import assetTypesIcon from './assets/asset-types-icon.svg';
import { CollectionDescription } from './CollectionDescription';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  width: 117px;
`;

const Title = styled.div`
  ${typography.lg.regular};
  margin-top: 23px;
  margin-bottom: ${globalTheme.spacing.xs};
`;

export const StyledDescription = styled(CollectionDescription)`
  padding: ${globalTheme.spacing.lg};
`;

const Subtitle = styled.div`
  ${typography.xs.lowContrast};
`;

export interface EmptyCollectionProps {
  collectionName: string;
  collectionDescription?: Description;
}

export const EmptyCollection: FC<React.PropsWithChildren<EmptyCollectionProps>> = memo(({ collectionName, collectionDescription }) => (
  <Wrapper data-test="empty-collection">
    <Icon src={assetTypesIcon} alt="Empty collection placeholder" />
    <Title data-test="empty-collection-title">{collectionName}</Title>
    <StyledDescription description={collectionDescription} />
    <Subtitle data-test="empty-collection-subtitle">
      <FormattedMessage {...messages.collectionEmpty} />
    </Subtitle>
  </Wrapper>
));
