import React from 'react';
import { Asset } from '@cld/console-apps-types';
import { StyledPlaceholderIconWrapper } from './AssetCard.styles';
import { resolveAssetIcon } from './AssetCard.helpers';
import { IconSize } from '@clds/icon';
import { globalTheme, useMixin } from '@clds/component-theme';

interface ThumbnailPlaceholderProps {
  asset: Asset;
  withAnimation?: boolean;
  iconSize?: IconSize;
  iconColorType?: string;
}

export const ThumbnailPlaceholder = ({ asset, withAnimation = false, iconSize = 'xl', iconColorType }: ThumbnailPlaceholderProps) => {
  const AssetPlaceholderIcon = resolveAssetIcon(asset);
  const iconColor = useMixin(globalTheme.palette.primary);

  return (
    <StyledPlaceholderIconWrapper withAnimation={withAnimation}>
      <AssetPlaceholderIcon size={iconSize} color={iconColorType ? iconColorType : iconColor} />
    </StyledPlaceholderIconWrapper>
  );
};
