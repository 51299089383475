import React from 'react';
import { Filter } from '../Filter/Filter';
import { ValueChipLabel } from '../FilterChipLabel/FilterChipLabel';
import { StyledPaper } from './OperatorFilter.styles';
import SingleValueCombobox from '../shared/SingleValueCombobox';
import { OperatorOptionType } from './OperatorFilter.types';

export interface OperatorFilterProps {
  value: string;
  operators: OperatorOptionType[];
  filterLabelPrefix: string | undefined;
  valueLabel?: string | undefined;
  delimiter?: string;
  hasValue: boolean;
  onChange: (operator: OperatorOptionType) => void;
  onClear?: () => void;
  renderFieldComponent: React.ReactNode;
  dataTest?: string;
}

export const OperatorFilter = ({
  value,
  operators,
  filterLabelPrefix = '',
  valueLabel = '',
  delimiter,
  hasValue,
  onChange,
  onClear,
  renderFieldComponent,
  dataTest = '',
}: OperatorFilterProps) => {
  const handleClear = () => {
    onClear?.();
  };

  const overlay = (
    <StyledPaper isPadded depth="none">
      <SingleValueCombobox
        options={operators}
        value={value}
        // @ts-expect-error TODO: Fix this and the casting below
        onChange={onChange}
        isClearable={false}
        isCreatable={false}
      />
      {renderFieldComponent}
    </StyledPaper>
  );

  const labelComp = <ValueChipLabel value={valueLabel} filterLabelPrefix={filterLabelPrefix} hasValue={hasValue} delimiter={delimiter} />;

  return <Filter label={labelComp} overlay={overlay} hasValue={hasValue} onClear={handleClear} dataTest={dataTest} />;
};
