import React, { ReactNode } from 'react';
import { ItemLayout } from '@clds/item';
import { StyleMenuItem } from '../AssetGrid/CollectionHeaderItem.styles';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../i18n';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';
import { Tooltip } from '@clds/tooltip';
import { DownloadType } from '../Enums/Enum';

interface DownloadCollectionMenuItemProps {
  onClickDownload: (downloadType: DownloadType) => void;
  disabled?: boolean;
  downloadType: DownloadType;
  children: ReactNode;
  isStriped?: boolean;
  secondaryContent: ReactNode;
  handleTooltipVisibleChange?: (visible: boolean) => void;
  isDownloadButtonDisabled?: boolean;
}

export const DownloadCollectionMenuItem = ({
  onClickDownload,
  disabled,
  downloadType,
  children,
  isStriped,
  secondaryContent,
  handleTooltipVisibleChange,
  isDownloadButtonDisabled,
}: DownloadCollectionMenuItemProps) => {
  const collectionInfo = useCollectionInfoContext();

  return (
    <Tooltip
      maxWidth="md"
      placement="left"
      onVisibleChange={handleTooltipVisibleChange}
      title={isDownloadButtonDisabled && <FormattedMessage {...messages.disabledDownloadTooltip} values={{ limit: collectionInfo.downloadAssetsLimit }} />}
    >
      <StyleMenuItem
        isDisabled={disabled}
        data-test-specifier="download-collection-button"
        onClick={disabled ? undefined : () => onClickDownload(downloadType)}
        isStriped={isStriped}
      >
        <ItemLayout secondaryContent={secondaryContent}>{children}</ItemLayout>
      </StyleMenuItem>
    </Tooltip>
  );
};
