import React from 'react';
import styled from 'styled-components';
import { globalTheme } from '@clds/component-theme';

const StyledBadgeRoot = styled.div`
  min-height: ${globalTheme.spacing.md};
  min-width: ${globalTheme.spacing.md};
  border-radius: ${globalTheme.spacing.md};
  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.background};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${globalTheme.spacing.xxs};
`;

export interface BadgeProps {
  children: React.ReactNode | string;
  className?: string;
}

export const Badge = ({ children, className }: BadgeProps) => {
  return (
    <StyledBadgeRoot className={className}>
      <span>{children}</span>
    </StyledBadgeRoot>
  );
};

export const StyledBadge = styled(Badge)`
  margin-left: ${globalTheme.spacing.xxs};
`;
