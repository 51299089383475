import React, { FC } from 'react';
import styled from 'styled-components';
import Progress from '@clds/progress';
import { Asset } from '@cld/console-apps-types';
import { getProperties } from '@cld/console-apps-services';
import { VideoPlayer } from './VideoPlayer';
import { ImageContent } from './ImageContent';
import { PdfViewer } from './PdfViewer';

const ProgressWrapper = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export interface ContentProps {
  asset: Asset;
  trackingAssetPosition: number;
}

export const Content: FC<React.PropsWithChildren<ContentProps>> = ({ asset, trackingAssetPosition }) => {
  if (!asset) {
    return (
      <ProgressWrapper data-test-specifier="app-loader">
        <Progress.Circular variant="primary" size="xl" />
      </ProgressWrapper>
    );
  }

  if (['video', 'audio'].includes(asset.type)) {
    const { customerId } = getProperties();
    return <VideoPlayer asset={asset} cloudName={customerId} trackingAssetPosition={trackingAssetPosition} />;
  }

  if (asset.format === 'pdf') {
    return <PdfViewer asset={asset} />;
  }

  return <ImageContent asset={asset} />;
};
