import React from 'react';
import styled from 'styled-components';
import { asStyledComponent } from '@clds/component-enhancer';

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Spacer = styled.div`
  flex: 1 1;
`;

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  & > * {
    max-height: 100%;
    min-height: 0;
    overflow: auto;
  }
`;

export interface MainLayoutBase {
  content: React.ReactNode;
  footer: React.ReactNode;
  className?: string;
}

export const MainLayoutBase = ({ content, footer, ...rest }: MainLayoutBase) => {
  return (
    <Root {...rest} data-test-specifier="main-layout">
      <Content>
        <>
          {content}
          <Spacer />
          {footer}
        </>
      </Content>
    </Root>
  );
};

export const MainLayout = asStyledComponent(MainLayoutBase);
