import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../i18n';

const useMonthName = () => {
  const { formatMessage } = useIntl();
  const month = useMemo(
    () => [
      formatMessage(messages.jan),
      formatMessage(messages.feb),
      formatMessage(messages.mar),
      formatMessage(messages.apr),
      formatMessage(messages.may),
      formatMessage(messages.jun),
      formatMessage(messages.jul),
      formatMessage(messages.aug),
      formatMessage(messages.sep),
      formatMessage(messages.oct),
      formatMessage(messages.nov),
      formatMessage(messages.dec),
    ],
    [formatMessage]
  );
  return month;
};

export default useMonthName;
