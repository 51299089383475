import styled from 'styled-components';
import { typography } from '@clds/typography';
import { datepickerTheme } from '../datepickerTheme';

const Item = styled.div`
  border-radius: 100px;
  background-color: transparent;
  padding: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: ${datepickerTheme.day.margin};
  width: ${datepickerTheme.day.size};
  height: ${datepickerTheme.day.size};
  overflow: hidden;
  ${typography.xs.regular};
  line-height: ${datepickerTheme.day.size};
`;

export default Item;
