import React, { FC, Suspense, lazy } from 'react';
import { Asset } from '@cld/console-apps-types';
import { useCloudinaryTracker } from '../CloudinaryTrackerProvider/hooks';
import { getAssetTrackingParams } from '../CloudinaryTrackerProvider/utils';
import { useIsDarkTheme } from '../Theme/CollectionTheme.hooks';
import { useCollectionInfoContext } from '../../context/CollectionInfoContext/CollectionInfo.hooks';

const CldVideoPlayer = lazy(() => import('@cld/video-player'));

export interface VideoPlayerProps {
  asset: Asset;
  cloudName: string;
  trackingAssetPosition: number;
}

export const VideoPlayer: FC<React.PropsWithChildren<VideoPlayerProps>> = ({ asset, cloudName, trackingAssetPosition }) => {
  const cloudinaryTracker = useCloudinaryTracker();
  const isDarkTheme = useIsDarkTheme();
  const collectionInfo = useCollectionInfoContext();
  const primaryColor = collectionInfo.preferences?.theme?.primaryColor;
  const primaryColorDark = collectionInfo.preferences?.theme?.primaryColorDark ?? primaryColor;
  const accentColor = isDarkTheme ? primaryColorDark : primaryColor;
  const customerLogo = collectionInfo?.preferences?.logo?.deliveryUrl;

  return (
    <Suspense fallback={<div className="loading-video"></div>}>
      <CldVideoPlayer
        asset={asset}
        cloudName={cloudName}
        playerOptions={{
          onPlay: () =>
            cloudinaryTracker({
              eventName: 'collection_asset_preview_play_click',
              ...getAssetTrackingParams({ asset: { ...asset, trackingAssetPosition } }),
            }),
          onPause: () =>
            cloudinaryTracker({
              eventName: 'collection_asset_preview_pause_click',
              ...getAssetTrackingParams({ asset: { ...asset, trackingAssetPosition } }),
            }),
        }}
        videojsOptions={{
          showJumpControls: true,
          pictureInPictureToggle: true,
          playbackRates: [0.5, 1, 1.5, 2],
          ...(accentColor
            ? {
                colors: {
                  accent: accentColor,
                },
              }
            : {}),
          ...(isDarkTheme ? {} : { skin: 'light' }),
          ...(customerLogo ? { logoImageUrl: customerLogo } : {}),
        }}
      />
    </Suspense>
  );
};
