import React, { FunctionComponent } from 'react';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@clds/icon';
import { Direction } from './shared/consts';

export interface IndicatorProps {
  /** Direction to which the panel is closing  **/
  direction?: Direction;
  /** Is the panel open **/
  isExpanded?: boolean;
}

export const Indicator: FunctionComponent<
  React.PropsWithChildren<IndicatorProps>
> = ({ direction, isExpanded }) => {
  if (direction === Direction.RIGHT && isExpanded) {
    return <KeyboardArrowRight />;
  }
  if (direction === Direction.LEFT && !isExpanded) {
    return <KeyboardArrowRight />;
  }

  return <KeyboardArrowLeft />;
};
