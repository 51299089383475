import { globalTheme } from '@clds/component-theme';
import { media } from '@clds/style-utils';
import { typography } from '@clds/typography';
import styled from 'styled-components';

export const StyledItemOverlayContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  border-radius: ${globalTheme.radius.lg};
  opacity: 0;
  transition: opacity 0.15s ease-in;
  @media ${media.phone()} {
    background-color: transparent;
    opacity: 1;
  }
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    opacity: 0.3;
    background-color: ${globalTheme.palette.overlayDark};
  }
`;

export const Title = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: inline-grid;
  margin-left: ${globalTheme.spacing.xs};

  ${typography.md.highContrast};
  color: ${globalTheme.palette.overlayLight};
  line-height: ${globalTheme.font.size.xl};
`;
