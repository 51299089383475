import React, { useCallback, useEffect, useRef } from 'react';
import { HorizontalSeparator } from '@clds/separator';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useThrottledCallback } from 'use-debounce';

import { StyledListContainer, StyledParent, StyledVirtualRow, StyledItemContainer } from './VirtualizedList.styles';

interface VirtualizedListProps<Item> {
  items: Item[];
  getItemHeight: (index: number) => number;
  overscan: number;
  options?: Record<string, unknown>;
  renderItem: (item: Item, index: number) => React.ReactNode;
  renderSkeleton: () => React.ReactNode;
  isLoading: boolean;
  hasSeparator?: boolean;
  gridPadding: number;
  itemGap: number;
  listBottom?: React.ReactNode;
  listTop?: React.ReactNode;
}

const waitTime = 1000 / 60; // 1000ms/60times = 60FPS;

export const VirtualizedList = <Item,>({
  items = [],
  getItemHeight,
  overscan,
  options,
  renderItem,
  renderSkeleton,
  isLoading,
  hasSeparator = false,
  gridPadding = 0,
  itemGap = gridPadding,
  listBottom,
  listTop,
}: VirtualizedListProps<Item>) => {
  const parentRef = useRef<Element>(null);

  const virtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => {
      const height = getItemHeight(index);
      return height + itemGap;
    },
    overscan,
    ...(options ? options : {}),
  });

  const onScreenResize = useCallback(() => {
    virtualizer.measure();
  }, [virtualizer]);

  const onScreenResizeThrottled = useThrottledCallback(onScreenResize, waitTime);

  useEffect(() => {
    const ref = parentRef.current;

    if (!ref) {
      return;
    }

    const observer = new ResizeObserver(onScreenResizeThrottled);
    observer.observe(ref);

    return () => {
      observer.disconnect();
    };
  }, []);

  //In the case of Mosaic If the items are changing, the height of the rows are changing again therefore we need to remeasure the virtual list
  useEffect(() => {
    onScreenResizeThrottled();
  }, [items]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <StyledParent ref={parentRef as any}>
      {listTop}
      <div style={{ padding: gridPadding }}>
        {isLoading ? (
          renderSkeleton()
        ) : (
          <StyledListContainer height={virtualizer.getTotalSize()}>
            {virtualizer.getVirtualItems().map((virtualRow) => (
              <StyledVirtualRow key={virtualRow.key} start={virtualRow.start} height={virtualRow.size}>
                <StyledItemContainer>{renderItem(items[virtualRow.index], virtualRow.index)}</StyledItemContainer>
                {hasSeparator && virtualRow.index !== items.length && <HorizontalSeparator />}
              </StyledVirtualRow>
            ))}
          </StyledListContainer>
        )}
      </div>
      {listBottom}
    </StyledParent>
  );
};
