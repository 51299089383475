import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  sunday: {
    id: 'sunday',
    defaultMessage: 'Sun',
  },
  monday: {
    id: 'monday',
    defaultMessage: 'M',
  },
  tuesday: {
    id: 'tuesday',
    defaultMessage: 'T',
  },
  wednesday: {
    id: 'wednesday',
    defaultMessage: 'W',
  },
  thursday: {
    id: 'thursday',
    defaultMessage: 'T',
  },
  friday: {
    id: 'friday',
    defaultMessage: 'F',
  },
  saturday: {
    id: 'saturday',
    defaultMessage: 'S',
  },
  jan: {
    id: 'Jan',
    defaultMessage: 'Jan',
  },
  feb: {
    id: 'Feb',
    defaultMessage: 'Feb',
  },
  mar: {
    id: 'Mar',
    defaultMessage: 'Mar',
  },
  apr: {
    id: 'Apr',
    defaultMessage: 'Apr',
  },
  may: {
    id: 'May',
    defaultMessage: 'May',
  },
  jun: {
    id: 'Jun',
    defaultMessage: 'Jun',
  },
  jul: {
    id: 'Jul',
    defaultMessage: 'Jul',
  },
  aug: {
    id: 'Aug',
    defaultMessage: 'Aug',
  },
  sep: {
    id: 'Sep',
    defaultMessage: 'Sep',
  },
  oct: {
    id: 'Oct',
    defaultMessage: 'Oct',
  },
  nov: {
    id: 'Nov',
    defaultMessage: 'Nov',
  },
  dec: {
    id: 'Dec',
    defaultMessage: 'Dec',
  },
});
