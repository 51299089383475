import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  collectionDownload: {
    id: 'collection.download',
    defaultMessage: 'Download Collection',
  },
  collectionNotFound: {
    id: 'collection.notFound',
    defaultMessage: 'Collection not found',
  },
  collectionEmpty: {
    id: 'collection.empty',
    defaultMessage: 'This collection is currently empty',
  },
  collectionAssetsCount: {
    id: 'collection.assetsCount',
    defaultMessage: '{count, plural, one {# asset} other {# assets}}',
  },
  isOverLimit: {
    id: 'collection.isOverLimit',
    defaultMessage: 'Over {count, plural, one {# asset} other {# assets}}',
  },
  tempCollectionTitle: {
    id: 'collection.tempCollectionTitle',
    defaultMessage: 'Shared with You:',
  },
  assetDownload: {
    id: 'asset.download',
    defaultMessage: 'Download',
  },
  DisabledDownloadOriginalAssetsText: {
    id: 'asset.DisabledDownloadOriginalAssetsText',
    defaultMessage: 'Downloading original assets is currently disabled.',
  },
  assetDownloadError: {
    id: 'asset.download.error',
    defaultMessage: 'Asset download failed.',
  },
  original: {
    id: 'asset.original',
    defaultMessage: 'Original',
  },
  originalAssets: {
    id: 'asset.originalAssets',
    defaultMessage: 'Original Assets',
  },
  presetsDownload: {
    id: 'asset.downloadPresets',
    defaultMessage: '{isBulkDownload, select, false {Download a modified version of the file:} other {Download modified versions of the files:}}',
  },
  presetsDownloadError: {
    id: 'asset.downloadPresets.error',
    defaultMessage: 'Download Presets Failed',
  },
  downloadSinglePresetsModalHeader: {
    id: 'asset.downloadSinglePresetsModalHeader',
    defaultMessage: 'Download Media File',
  },
  downloadCollectionPresetsModalHeader: {
    id: 'asset.downloadCollectionPresetsModalHeader',
    defaultMessage: 'Download {count} Assets',
  },
  downloadCollectionSinglePresetsModalHeader: {
    id: 'asset.downloadCollectionSinglePresetsModalHeader',
    defaultMessage: 'Download {count} Asset',
  },
  menu: {
    id: 'menu',
    defaultMessage: 'More options',
  },
  metadataCreatedBy: {
    id: 'metadata.createdBy',
    defaultMessage: 'Created by:',
  },
  metadataLastUpdated: {
    id: 'metadata.lastUpdated',
    defaultMessage: 'Last updated:',
  },
  metadataLinkExpiredAt: {
    id: 'metadata.linkExpiredAt',
    defaultMessage: 'Sharing expires on:',
  },
  refreshModalHeader: {
    id: 'refreshModal.header',
    defaultMessage: 'Session Expired',
  },
  refreshModalButton: {
    id: 'refreshModal.button',
    defaultMessage: 'Refresh',
  },
  refreshModalMessage: {
    id: 'refreshModal.message',
    defaultMessage: "You've been here for a while. We suggest you refresh the page to make sure you're looking at the latest content.",
  },
  lightTheme: {
    id: 'collection.theme.light',
    defaultMessage: 'Light',
  },
  darkTheme: {
    id: 'collection.theme.dark',
    defaultMessage: 'Dark',
  },
  systemTheme: {
    id: 'collection.theme.system',
    defaultMessage: 'System',
  },
  disabledDownloadTooltip: {
    id: 'collection.disabledDownloadTooltip',
    defaultMessage: 'For bulk download, filter the collection to meet the { limit } asset limit.',
  },
  searchFiltersFreeSearchPlaceholder: {
    id: 'collection.searchFilters',
    defaultMessage: 'Type to filter',
  },
  today: {
    id: 'today',
    defaultMessage: 'Today',
  },
  last7: {
    id: 'last7',
    defaultMessage: 'Last 7 days',
  },
  last30: {
    id: 'last30',
    defaultMessage: 'Last 30 days',
  },
  last90: {
    id: 'last90',
    defaultMessage: 'Last 90 days',
  },
  last180: {
    id: 'last180',
    defaultMessage: 'Last 180 days',
  },
  creationDate: {
    id: 'creation-date-id',
    defaultMessage: 'Creation date',
  },
  imageCount: {
    id: 'collection.imageCount',
    defaultMessage: '{count, plural, one {# image} other {# images}}',
  },
  videoCount: {
    id: 'collection.videoCount',
    defaultMessage: '{count, plural, one {# video} other {# videos}}',
  },
  clearFilter: {
    id: 'collection.clearFilter',
    defaultMessage: 'Clear filter',
  },
  display_name: {
    id: 'collection.display_name',
    defaultMessage: 'Display name',
  },
  displayName: {
    id: 'collection.displayName',
    defaultMessage: 'Display name',
  },
  format: {
    id: 'collection.format',
    defaultMessage: 'Format',
  },
  resolution: {
    id: 'collection.resolution',
    defaultMessage: 'Resolution',
  },
  filename: {
    id: 'collection.filename',
    defaultMessage: 'File name',
  },
  created_at: {
    id: 'collection.created_at',
    defaultMessage: 'Date',
  },
  bytes: {
    id: 'collection.bytes',
    defaultMessage: 'Size',
  },
  fileSize: {
    id: 'collection.fileSize',
    defaultMessage: 'File size',
  },
  assetTypes: {
    id: 'search.assetTypes',
    defaultMessage: 'Asset types',
  },
  image: {
    id: 'search.image',
    defaultMessage: 'Image',
  },
  video: {
    id: 'search.video',
    defaultMessage: 'Video & audio',
  },
  others: {
    id: 'search.others',
    defaultMessage: 'Others (raw)',
  },
  sizeFilterLabel: {
    id: 'search.sizeFilterLabel',
    defaultMessage: 'Sizes',
  },
  rangeFilterMinPlaceholder: {
    id: 'search.rangeFilterMinPlaceholder',
    defaultMessage: 'Min',
  },
  rangeFilterMaxPlaceholder: {
    id: 'search.rangeFilterMaxPlaceholder',
    defaultMessage: 'Max',
  },
  rangeFilterMinimumPrefix: {
    id: 'search.rangeFilterMinimumPrefix',
    defaultMessage: 'At least',
  },
  rangeFilterMaximumPrefix: {
    id: 'search.rangeFilterMaximumPrefix',
    defaultMessage: 'At most',
  },
  sort: {
    id: 'search.sort',
    defaultMessage: 'Sort',
  },
  formats: {
    id: 'search.formats.label',
    defaultMessage: 'Formats',
  },
  popular: {
    id: 'search.formats.popular',
    defaultMessage: 'Popular',
  },
  moreFormats: {
    id: 'search.formats.moreFormats',
    defaultMessage: 'More formats',
  },
  dimensions: {
    id: 'search.dimensions',
    defaultMessage: 'Dimensions',
  },
  clear: {
    id: 'cldFilters.clear',
    defaultMessage: 'Clear',
  },
  filter: {
    id: 'cldFilters.filter',
    defaultMessage: 'Filter',
  },
  clearAll: {
    id: 'search.clearAll',
    defaultMessage: 'Clear all',
  },
  sd: { id: 'cldFilters.dimensions.sd', defaultMessage: 'SD' },
  hd: { id: 'cldFilters.dimensions.hd', defaultMessage: 'HD' },
  fullHD: { id: 'cldFilters.dimensions.fullHD', defaultMessage: 'Full HD' },
  '4k': { id: 'cldFilters.dimensions.4k', defaultMessage: '4K' },
  icon: { id: 'cldFilters.dimensions.icon', defaultMessage: 'Icon' },
  small: { id: 'cldFilters.dimensions.small', defaultMessage: 'Small' },
  medium: { id: 'cldFilters.dimensions.medium', defaultMessage: 'Medium' },
  large: { id: 'cldFilters.dimensions.large', defaultMessage: 'Large' },
  noResultsTitle: {
    id: 'search.noResults.title',
    defaultMessage: 'No Assets Met Your Search Criteria',
  },
  noResultsSubtitle: {
    id: 'search.noResults.subTitle',
    defaultMessage: 'Try adjusting your search filters, generalizing your search term, or double-checking your spelling.',
  },
  filters: {
    id: 'search.filters',
    defaultMessage: 'Filters',
  },
  tags: {
    id: 'search.tags.label',
    defaultMessage: 'Tags',
  },
  loading: {
    id: 'search.loading',
    defaultMessage: 'Loading...',
  },
  noResults: {
    id: 'search.noResults',
    defaultMessage: 'No results found',
  },
  startTyping: {
    id: 'search.startTyping',
    defaultMessage: 'Start typing to filter results.',
  },
  summary: {
    id: 'previewPave.metadata.summary',
    defaultMessage: 'Summary',
  },
  countAssets: {
    id: 'asset.countDownloadAssets',
    defaultMessage: '{count} Assets',
  },
  countAsset: {
    id: 'asset.countDownloadAsset',
    defaultMessage: '{count} Asset',
  },
  downloadSearchAssets: {
    id: 'asset.downloadSearchAssets',
    defaultMessage: 'Download Search Results',
  },
  typeToFilter: {
    id: 'search.smdText.textFieldPlaceholder',
    defaultMessage: 'Type to filter',
  },
  startsWith: {
    id: 'search.operators.contains',
    defaultMessage: 'Starts with',
  },
  equalTo: {
    id: 'search.operators.equals',
    defaultMessage: 'Equal to',
  },
  anyText: {
    id: 'search.operators.anyText',
    defaultMessage: 'Any text',
  },
  anyDate: {
    id: 'search.operators.anyDate',
    defaultMessage: 'Any date',
  },
  isEmpty: {
    id: 'search.operators.empty',
    defaultMessage: 'Is empty',
  },
  is: {
    id: 'search.operators.is',
    defaultMessage: 'Is',
  },
  before: {
    id: 'search.operators.before',
    defaultMessage: 'Before',
  },
  after: {
    id: 'search.operators.after',
    defaultMessage: 'After',
  },
  pickADate: {
    id: 'search.smdDate.pickADate',
    defaultMessage: 'Pick a date',
  },
  anyNumber: {
    id: 'search.operators.anyNumber',
    defaultMessage: 'Any number',
  },
});
