import styled from 'styled-components';
import themes from '@clds/themes';

export const StyledWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > button {
    margin: ${themes.spacing.xxs};
  }
  & > button:first-child {
    margin-left: 0;
  }
`;
