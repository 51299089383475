import isValid from 'date-fns/isValid';
import parseDate from 'date-fns/parse';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';

const validFormats = ['MM/dd/yy', 'MM/dd/yyyy', 'dd/MM/yy', 'dd/MM/yyyy', 'yy/MM/dd', 'MMMM dd yyyy', 'm/d/yy', 'PP'];

export const guessDate = (dateString: string, formats: string[] = []): Date | undefined => {
  let guessed: Date | undefined = undefined;

  if (dateString) {
    const guessFormats = formats.concat(validFormats);

    guessFormats.find((format) => {
      const date = parseDate(dateString, format, new Date());

      if (isValid(date)) {
        guessed = date;
      }

      return !!guessed;
    });

    if (!guessed) {
      const date = new Date(dateString);
      if (isValid(date)) {
        guessed = date;
      }
    }
  }

  return guessed;
};

export const isDateInRange = (date: Date, minDate?: Date, maxDate?: Date): boolean => {
  let isValid = minDate ? isEqual(date, minDate) || isAfter(date, minDate) : true;

  isValid = isValid && (maxDate ? isEqual(date, maxDate) || isBefore(date, maxDate) : true);

  return isValid;
};
