import React from 'react';
import { OptionProps } from 'react-select';
import styled, { css } from 'styled-components';
import { globalTheme } from '@clds/component-theme';
import { BlendVariant } from '@clds/blender';
import { Item } from '@clds/item';
import { CommonComboboxProps } from './types';

export const StyledItem = styled(Item)`
  ${(props: { isNew: boolean }) =>
    props.isNew &&
    css`
      border-bottom: 1px solid ${globalTheme.palette.secondaryAlt};
    `};
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ComboboxItemProps<IsMulti extends boolean> = React.PropsWithChildren<OptionProps<any, IsMulti> & { variant?: BlendVariant }>;

export const ComboboxItem = <IsMulti extends boolean>({
  isSelected,
  isFocused,
  innerRef,
  innerProps,
  data,
  selectProps,
  children,
  variant = 'primary',
}: ComboboxItemProps<IsMulti>) => {
  const { onClick, ...restInnerProps } = innerProps;

  const { selectionVariant = 'solid' } = selectProps as CommonComboboxProps;

  // FIXME: onClick ListItemLayout is called with no arguments, we should change it to act as normal onClick in the future
  return (
    <StyledItem
      variant={variant}
      selectionVariant={selectionVariant}
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access */
      isNew={data.__isNew__}
      isHoverable={false}
      isSelected={isSelected}
      isFocused={isFocused}
      borderRadius="md"
      ref={innerRef}
      onClick={onClick as () => void}
      data-test="combobox-item"
      {...restInnerProps}
    >
      {children}
    </StyledItem>
  );
};
