import { MultiSelectFilter, useCldFilters } from '../../../../cld-filters';
import React, { useCallback, useMemo } from 'react';
import { FilterComponentBasePropsType, SearchFilterValuesType, SearchFiltersNames } from '../../types';
import { File, Photo, Video } from '@clds/icon';
import { useIntl } from 'react-intl';
import { RESOURCE_TYPES } from './AssetTypes.constants';
import { messages } from '../../../../i18n';
import { ResourceTypesKeyType } from './AssetTypes.types';

const icons = {
  image: <Photo />,
  video: <Video />,
  others: <File />,
};

export const AssetTypes = ({ onFilterInteract, onClear }: FilterComponentBasePropsType) => {
  const { values, setFilterValue } = useCldFilters<SearchFilterValuesType>();
  const { formatMessage } = useIntl();
  const assetTypes = values[SearchFiltersNames.AssetTypes];

  const handleChange = useCallback(
    (assetTypes: string[]) => {
      setFilterValue(SearchFiltersNames.AssetTypes, assetTypes);
      onFilterInteract(SearchFiltersNames.AssetTypes, assetTypes);
    },
    [setFilterValue, onFilterInteract]
  );

  const options = useMemo(() => {
    return Object.keys(RESOURCE_TYPES).map((type) => {
      const { value, label } = RESOURCE_TYPES[type as ResourceTypesKeyType];
      return {
        value,
        label: formatMessage(messages[label as keyof typeof messages]),
        icon: icons[label as keyof typeof icons],
      };
    });
  }, [formatMessage]);

  const handleClear = () => {
    onClear(SearchFiltersNames.AssetTypes);
  };

  return (
    <MultiSelectFilter
      values={assetTypes}
      onChange={handleChange}
      onClear={handleClear}
      options={options}
      filterLabelPrefix={formatMessage(messages.assetTypes)}
      dataTest="search-asset-types"
    />
  );
};
