import * as ACTION_TYPES from './CldFilters.actionTypes';
import { CldFiltersContextState, DispatchAction } from '../types';
import isEqual from 'react-fast-compare';
import { getPopulatedValues } from './CldFilters.helpers';

export const cldFiltersReducer = <Values>(state: CldFiltersContextState<Values>, action: DispatchAction) => {
  const actions = {
    [ACTION_TYPES.SET_FILTER_VALUE]: () => {
      const { field, value } = action.payload;
      const { initialValues, unpopulatedValues } = state;
      const updatedValues = { ...state.values, [field]: value };
      // We update isDirty & isPopulated here to not update the Context twice
      const isDirty = !isEqual(initialValues, updatedValues);
      const isPopulated = !isEqual(unpopulatedValues, updatedValues);
      const populatedValues = getPopulatedValues(updatedValues, unpopulatedValues);

      return { ...state, values: updatedValues, isDirty, isPopulated, populatedValues };
    },

    [ACTION_TYPES.SET_MULTIPLE_VALUES]: () => {
      const { values: newValues } = action.payload;
      const { initialValues, unpopulatedValues, values } = state;
      // We update isDirty & isPopulated here to not update the Context twice
      const isDirty = !isEqual(initialValues, newValues);
      const isPopulated = !isEqual(unpopulatedValues, newValues);
      const populatedValues = getPopulatedValues(newValues, unpopulatedValues);

      return { ...state, values: { ...values, ...newValues }, isDirty, isPopulated, populatedValues };
    },

    [ACTION_TYPES.SET_SMD_FILTER_VALUE]: () => {
      const { parentFieldId, field, value } = action.payload;
      const { initialValues, unpopulatedValues } = state;
      let updatedValues = state.values;
      const shouldDelete = !value.values || value.values.length === 0;
      const parentValue = { ...state.values[parentFieldId as keyof typeof state.values] };
      if (shouldDelete) {
        delete parentValue[field as keyof typeof parentValue];
      } else {
        parentValue[field as keyof typeof parentValue] = value;
      }

      updatedValues = { ...state.values, [parentFieldId]: parentValue };

      // We update isDirty & isPopulated here to not update the Context twice
      const isDirty = !isEqual(initialValues, updatedValues);
      const isPopulated = !isEqual(unpopulatedValues, updatedValues);
      const populatedValues = getPopulatedValues(updatedValues, unpopulatedValues);

      return { ...state, values: updatedValues, isDirty, isPopulated, populatedValues };
    },
  };

  return actions[action.type] ? actions[action.type]() : state;
};
